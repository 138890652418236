import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { SuttonDisclaimerNote } from 'components/general/DisclaimerNote/SuttonDisclaimerNote';
import packageJson from '../../../../../package.json';
import { SAuthLayoutFooter } from './AuthLayoutFooter.styles';

export const AuthLayoutFooter = () => {
  const { t } = useTranslation('preRegOnboarding');
  const { fromDesktopSmall } = useDeviceDimension();

  return (
    <SAuthLayoutFooter className="auth-footer">
      {fromDesktopSmall ? (
        <SuttonDisclaimerNote footerTextType="Sutton" />
      ) : (
        <>
          <p>{packageJson.version}</p>
          <p>{t('preRegOnboarding.CopyrightPercapita', { year: new Date().getFullYear() })}</p>
        </>
      )}
    </SAuthLayoutFooter>
  );
};
