import styled, { css } from 'styled-components';
import { getColor, mediaFrom, mediaUpTo, mediaUpToHeight } from 'utils/helpers/styleHelpers';
import { TThemeColor } from 'styles/theme';

export interface SBarInputProps {
  bgColor: TThemeColor;
}

export const SBar = styled.div<SBarInputProps>`
  height: 4px;
  background: ${({ bgColor }) => getColor(bgColor)};
  width: 100%;
`;

export const SContainer = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 15px;

  ${mediaFrom(
    577,
    css`
      display: none;
    `
  )}

  ${mediaUpTo(
    400,
    css`
      .custom-text-inner {
        font-size: 11px;
      }
    `
  )}

    ${mediaUpTo(
    365,
    css`
      .custom-text-inner {
        font-size: 10px;
      }
    `
  )}

    ${mediaUpTo(
    340,
    css`
      .custom-text-inner {
        font-size: 9px;
      }
    `
  )}
`;

export const SBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  ${mediaUpTo(
    380,
    css`
      ${mediaUpToHeight(
        700,
        css`
          .custom-text-inner {
            font-size: 11px;
          }
        `
      )}
    `
  )}
`;

export const SWebContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto;
  width: fit-content;
  max-width: 100%;

  .active {
    border-bottom: 2px solid ${getColor('blue')};
  }

  .done {
    border-bottom: 2px solid ${getColor('green')};
  }

  .scroll-arrow {
    position: absolute;
    z-index: 30;

    &_left {
      left: 0;
    }

    &_right {
      right: 0;
    }
  }

  .stepper-web__scroll-container {
    display: flex;
    padding: 4px 0;
    max-width: 100%;

    &.scrollable {
      overflow-x: auto;
      mask-repeat: no-repeat;
      mask: linear-gradient(to right, transparent 0, ${getColor('cream70')} 80px calc(100% - 80px), transparent 100%);

      &.scrolled-to-left {
        mask: linear-gradient(to right, ${getColor('cream70')} 0 calc(100% - 80px), transparent 100%);
      }

      &.scrolled-to-right {
        mask: linear-gradient(to right, transparent 0, ${getColor('cream70')} 80px 100%);
      }
    }
  }

  ${mediaUpTo(
    'tablet',
    css`
      display: none;
    `
  )}
`;

export const SWebStepsSlug = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  flex: 1 0 auto;
`;

export const SWebStep = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${getColor('transparent')};
  padding-bottom: 6px;
  border-bottom: 2px solid ${getColor('creamS30')};
  width: 92px;
  flex-shrink: 0;

  .body-text {
    overflow: hidden;
  }

  .custom-text-inner {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
