import styled, { css } from 'styled-components';
import { getColor, mediaFrom } from 'utils/helpers/styleHelpers';
import { BodyText } from 'components/general/Typography';
import {
  map,
  taxEstimator,
  taxOptimizer,
  taxFilers,
  reteg,
  addGoalsV2,
  addNeedsV2,
  addNewCashAccount,
  cashCushion,
  jointAccountholderV2,
  onboardingMan,
  pennyJarMainLogoV2,
  startDepositV2,
  get25,
  inst,
  ideas,
  fb,
} from 'assets/images';
import { ICON_SIZE_MAP } from 'components/general/Icon/Icon.constants';

export const SBodyText = styled(BodyText)`
  background: ${getColor('purple30')};
  padding: 7px;
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 12px 4px 12px 4px;
`;

export const SOptionItem = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  background: white no-repeat center left;
  padding: 16px 20px 16px 72px;
  border-radius: 16px;
  cursor: pointer;
  width: 100%;
  max-width: 778px;
  min-height: 82px;
  flex-shrink: 0;

  .do-more-option__content {
    flex: 1;
  }

  .do-more-option__title-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    .title {
      flex: 1;

      .custom-title-text {
        font-size: 12px;
        font-weight: 600;
      }
    }
  }

  .body-text {
    .custom-text-inner {
      font-size: 11px;
    }
  }

  &.cash-account {
    background-image: url(${onboardingMan});
    background-position: bottom -10px left -24px;
    background-size: auto 88px;
  }

  &.direct-deposit {
    background-image: url(${startDepositV2});
    background-position: bottom left;
    background-size: auto 82px;
  }

  &.joint-accountholder {
    background-image: url(${jointAccountholderV2});
    background-position: bottom left;
    background-size: auto 82px;
  }

  &.goals-account {
    background-image: url(${addGoalsV2});
    background-position: center left -4px;
    background-size: auto 63px;
  }

  &.needs-account {
    background-image: url(${addNeedsV2});
    background-position: center left -2px;
    background-size: auto 64px;
  }

  &.cash-cushion {
    background-image: url(${cashCushion});
    background-position: 5px;
    background-size: auto 78px;
  }

  &.penny-jar {
    background-image: url(${pennyJarMainLogoV2});
    background-position: bottom left;
    background-size: auto 90px;
  }

  &.second-cash-account {
    background-image: url(${addNewCashAccount});
    background-position: bottom left;
    background-size: auto 65px;
  }

  &.refer {
    background-image: url(${get25});
    background-position: bottom left;
    background-size: auto 119px;
  }

  &.inst {
    background-image: url(${inst});
    background-position: center left;
    background-size: auto 96px;
  }

  &.fb {
    background-image: url(${fb});
    background-position: center left;
    background-size: auto 92px;
  }

  &.ideas {
    background-image: url(${ideas});
    background-position: center left;
    background-size: auto 90px;
  }

  &.rent-reporting {
    background-image: url(${reteg});
    background-position: bottom left 20px;
    background-size: auto 55px;
  }

  &.international-transfer {
    background-image: url(${map});
    background-position: bottom -20px left -15px;
    background-size: 105px 116px;
  }

  &.tax-estimator {
    background-image: url(${taxEstimator});
    background-position: bottom left 20px;
    background-size: auto 65px;
  }

  &.tax-optimizer {
    background-image: url(${taxOptimizer});
    background-position: bottom left 20px;
    background-size: auto 75px;
  }

  &.tax-filer {
    background-image: url(${taxFilers});
    background-position: bottom left 20px;
    background-size: auto 55px;
  }

  &.disabled::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 20px;
    cursor: not-allowed;
    background: ${getColor('creamS5')};
    opacity: 0.55;
  }

  .icon-sign {
    height: 36px;
    width: 36px;
    position: absolute;
    left: 18px;
  }

  // 321-576px
  ${mediaFrom(
    321,
    css`
      border-radius: 20px;
      padding: 24px 24px 24px 96px;

      .do-more-option__content {
        .do-more-option__title-wrapper {
          margin-bottom: 8px;

          .title {
            .custom-title-text {
              font-size: 18px;
              font-family: DM Sans, sans-serif;
              font-weight: 700;
            }
          }
        }

        & > .body-text {
          .custom-text-inner {
            font-size: 12px;
          }
        }
      }

      & > .icon-chevronRight {
        height: ${ICON_SIZE_MAP.smallest}px;
        width: ${ICON_SIZE_MAP.smallest}px;
      }

      &.cash-account {
        background-position: bottom -10px left -28px;
        background-size: auto 109px;
      }

      &.refer {
        background-image: url(${get25});
        background-position: bottom left;
        background-size: auto 73px;
      }

      &.inst {
        background-image: url(${inst});
        background-position: center left;
        background-size: auto 73px;
      }

      &.fb {
        background-image: url(${fb});
        background-position: center left;
        background-size: auto 73px;
      }

      &.ideas {
        background-image: url(${ideas});
        background-position: center left;
        background-size: auto 73px;
      }

      &.goals-account {
        background-position: center left 0;
        background-size: auto 74px;
      }

      &.needs-account {
        background-position: center left -2px;
        background-size: auto 78px;
      }

      &.penny-jar {
        background-position: bottom left;
        background-size: auto 118px;
      }

      &.second-cash-account {
        background-size: auto 75px;
      }

      .icon-sign {
        height: 40px;
        width: 40px;
        left: 28px;
      }
    `
  )};

  // 577 - 1199px
  ${mediaFrom(
    577,
    css`
      min-height: 96px;
      padding: 24px 24px 24px 96px;

      .do-more-option__content {
        .do-more-option__title-wrapper {
          margin-bottom: 8px;

          .title {
            margin-right: 10px;

            .custom-title-text {
              font-size: 18px;
              font-family: DM Sans, sans-serif;
              font-weight: 700;
            }
          }
        }

        .body-text {
          .custom-text-inner {
            font-size: 12px;
          }
        }
      }

      & > .icon-chevronRight {
        margin-left: 16px;
        height: ${ICON_SIZE_MAP.smallest}px;
        width: ${ICON_SIZE_MAP.smallest}px;
      }

      &.cash-account {
        background-position: bottom -10px left -28px;
        background-size: auto 109px;
      }

      &.direct-deposit {
        background-position: top 30px left 5px;
        background-size: auto 92px;
      }

      &.joint-accountholder {
        background-position: top 20px left;
        background-size: auto 90px;
      }

      &.goals-account {
        background-position: center left 1px;
        background-size: auto 74px;
      }

      &.needs-account {
        background-position: center left;
        background-size: auto 78px;
      }

      &.cash-cushion {
        background-position: top 25px left -10px;
        background-size: auto 98px;
      }

      &.penny-jar {
        background-position: bottom left 0;
        background-size: auto 102px;
      }

      &.second-cash-account {
        background-position: top 21px left 2px;
        background-size: auto 90px;
      }

      &.international-transfer {
        background-position: bottom -36px left -15px;
      }
    `
  )};

  // 1200 - 1440px
  ${mediaFrom(
    'desktop',
    css`
      align-items: flex-start;
      min-height: 240px;
      max-width: 310px;
      width: 31.6%;
      padding: 24px 12px 32px 96px;

      .do-more-option__content {
        .do-more-option__title-wrapper {
          min-height: 46px;
          margin-bottom: 16px;

          .title {
            .custom-title-text {
              line-height: 1.3;
            }
          }

          .icon-chevronRight {
            height: ${ICON_SIZE_MAP.smallest}px;
            width: ${ICON_SIZE_MAP.smallest}px;
            margin-right: 8px;
          }
        }

        & > .body-text {
          .custom-text-inner {
            font-size: 14px;
            line-height: 1.4;
          }
        }
      }

      &.disabled {
        .icon-sign {
          top: 28px;
          left: auto;
          right: 12px;
        }
      }

      &.cash-account {
        background-position: top 21px left -20px;
        background-size: auto 96px;
      }

      &.goals-account {
        background-position: top 20px left;
        background-size: auto 71px;
      }

      &.needs-account {
        background-position: top 30px left -3px;
        background-size: auto 78px;
      }

      &.penny-jar {
        background-position: top left;
      }

      &.international-transfer {
        background-position: top 22px left -14px;
        background-size: 94px 104px;
      }
    `
  )};

  // 1440px
  ${mediaFrom(
    1441,
    css`
      max-width: 314px;
      width: 32%;
      min-height: 240px;

      &.disabled {
        .icon-sign {
          right: 12px;
        }
      }
    `
  )};
`;
