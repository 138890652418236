import { Button } from 'components/theme/Button/Button';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import styled, { css } from 'styled-components';
import { mediaUpToHeight, getColor, mediaUpTo } from 'utils/helpers/styleHelpers';

export const SCustomSheet = styled(CustomSheet)`
  // Markup comes from server with styles so we have to hardly override it
  h1 {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    margin-bottom: 20px !important;
  }

  p {
    font-size: 14px !important;
    line-height: 20px !important;
    font-family: 'DM Sans', sans-serif !important;
  }

  table {
    width: auto;
  }

  ${mediaUpTo(
    'desktop',
    css`
      h1 {
        font-size: 14px !important;
      }

      p {
        font-size: 12px !important;
      }
    `
  )}
`;

export const SConsentWrapper = styled.div`
  position: relative;
  border-radius: 25px;
  overflow: hidden;
  margin-top: 25px;

  ${mediaUpToHeight(
    640,
    css`
      margin-top: 12px;
    `
  )}

  .consent-bar {
    padding: 24px;
  }

  ${mediaUpTo(
    'desktop',
    css`
      .consent-bar {
        .custom-text-inner {
          font-size: 14px;
        }

        .body-text .svg-icon {
          width: 20px;
          height: 20px;
        }
      }
    `
  )}
`;

export const SSuccessTopIcon = styled.img`
  align-self: center;
  width: 120px;
  margin: 0 auto 32px;
`;

export const SSentSuccessfully = styled.div`
  display: flex;
  flex-direction: column;

  .email {
    color: ${getColor('charcoal')};
    font-weight: 700;
  }
`;

export const SSendViaEmailLayout = styled.div`
  display: flex;
  flex-direction: column;

  & .email-label-container {
    flex-direction: row;
    justify-content: flex-start;
  }
`;

export const SButton = styled(Button)`
  background: transparent !important;
  border: 2px solid ${getColor('blue')};
  color: ${getColor('blue')};
  margin-bottom: 40px;
  margin-top: 24px;

  :disabled {
    border: 2px solid ${getColor('charcoal40')};
    color: ${getColor('charcoal40')};
  }

  &:hover,
  &:active,
  &:focus {
    color: ${getColor('blue')} !important;
  }

  ${mediaUpToHeight(
    640,
    css`
      margin-bottom: 4px;
      margin-top: 12px;
    `
  )}
`;
