import React, { useEffect, useMemo, useState } from 'react';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { BodyText, Title } from 'components/general/Typography';
import { Loader } from 'components/general/Loader/Loader';
import { useLanguage } from 'utils/hooks/useLanguage';
import { Trans, useTranslation } from 'react-i18next';
import { formatLocaleDate } from 'utils/helpers/date';
import { useCurrencyFormat } from 'utils/hooks/useCurrencyFormat';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { ITransactionDetailsSheet } from 'views/Account/BalancesTransactionsPage/BalancesTransactionsPage.types';
import { PaymentIcon } from 'views/Account/BalancesTransactionsPage/PaymentIcon/PaymentIcon';
import { PaymentDescription } from 'views/Account/BalancesTransactionsPage/PaymentDescription/PaymentDescription';
import { usersApi } from 'store/user/users.api';
import { Icon } from 'components/general/Icon/Icon';
import { normalizeScreamingSnakeCase, snakeCaseToCamelCase } from 'utils/helpers/string';
import { TRANSACTION_CATEGORIES } from 'views/Account/BalancesTransactionsPage/BalancesTransactionsPage';
import { CategorySelectSheet } from 'views/Account/BalancesTransactionsPage/CategorySelectSheet/CategorySelectSheet';
import { useUpdateAccountTransactionByIdMutation } from 'store/user/accounts/accounts.api';
import { EditTransactionNoteSheet } from 'views/Account/BalancesTransactionsPage/EditTransactionNoteSheet/EditTransactionNoteSheet';
import { ensureHttps } from 'utils/helpers/ensureHttps';
import { SMapAddress, SMapWrapper, STitleContainer, STransactionContainer, STrnBlock } from './TransactionDetailsSheet.styles';

export const TransactionDetailsSheet = ({ isOpen, transaction, accountId, onClose, isLoading }: ITransactionDetailsSheet) => {
  const { t } = useTranslation('accountInformation');
  const { isMobileSmallSize } = useDeviceDimension();
  const { locale } = useLanguage();
  const { formatAutoSign } = useCurrencyFormat();
  const [categoryEditOpen, setCategoryEditOpen] = useState(false);
  const [editNoteOpen, setEditNoteOpen] = useState(false);
  const [updateTransaction, updateTransactionResult] = useUpdateAccountTransactionByIdMutation();
  const {
    primaryCategory,
    transactionEventId,
    trnCode,
    userNote,
    trnDt,
    locationLatitude: lat,
    locationLongitude: lon,
    merchantName,
    merchantLogo,
    desc,
    drCrType,
    trnAmt,
  } = transaction?.acctTrnInfo ?? {};

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { website, phone_number, location: addressData } = transaction?.acctTrnInfo?.enrichedData?.enrichments ?? {};
  const { data: imageBlob, isLoading: isMapLoading } = usersApi.useGetUserMapQuery(`?zoom=14&api-version=2024-04-01&language=en-us&center=${lon},${lat}&height=144&Width=388`, { skip: !lat || !lon });

  const [category, setCategory] = useState('');
  const [note, setNote] = useState('');

  const preparedCategories = useMemo(() => {
    return TRANSACTION_CATEGORIES.map((cat) => ({
      label: normalizeScreamingSnakeCase(cat),
      value: cat,
      icon: snakeCaseToCamelCase(cat),
    }));
  }, [TRANSACTION_CATEGORIES]);

  const handleUpdateTransaction = async (updatedCategory: string, updatedNote: string) => {
    if (!transaction || !transactionEventId) return;
    await updateTransaction({
      accountId,
      transactionId: transactionEventId,
      data: {
        primaryCategory: updatedCategory,
        note: updatedNote,
      },
    });
  };

  const handleSaveCategory = async (newCategory: string) => {
    await handleUpdateTransaction(newCategory, note);
  };

  const handleUpdateNote = async (newNote: string) => {
    await handleUpdateTransaction(category, newNote);
  };

  useEffect(() => {
    if (updateTransactionResult.isSuccess || updateTransactionResult.isError) {
      setCategoryEditOpen(false);
      setEditNoteOpen(false);
    }
  }, [updateTransactionResult]);

  useEffect(() => {
    if (transaction) {
      setNote(userNote);
      setCategory(primaryCategory);
    }
  }, [transaction]);

  const mapBlock = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { address, city, region, postal_code } = addressData || {};
    const handleOpenMap = () => {
      window.open(`https://www.google.com/maps/place/${address},+${city},+${region}+${postal_code}`, '_blank');
    };

    return imageBlob ? (
      <STrnBlock onClick={handleOpenMap} className="trn-block trn-block_location">
        {isMapLoading && <Loader size="small" />}
        <SMapWrapper>
          <Icon className="map-pin" name="pin" color="blue" />
          <img className="map-img" src={URL.createObjectURL(imageBlob)} alt={merchantName || ''} />
        </SMapWrapper>
        <SMapAddress>
          <BodyText textType="bodyText" color="charcoal" size={isMobileSmallSize ? 'T' : 'N'} fontWeight="R">
            {address}
            <div className="address-second-line">
              {city} {region} {postal_code}
            </div>
          </BodyText>
          <Icon name="chevronRight" size="smallest" color="blue" />
        </SMapAddress>
      </STrnBlock>
    ) : null;
  }, [addressData, imageBlob, isMapLoading, merchantName]);

  const contactBlock = useMemo(
    () =>
      website || phone_number ? (
        <STrnBlock className="trn-block">
          {website && (
            <CustomRow justifyContent="space-between" marginBottom={16}>
              <BodyText textType="bodyText" color="charcoal" size="N" fontWeight="R">
                {t('accountInformation.website')}
              </BodyText>
              <BodyText size="N" textType="bodyText" fontWeight="B">
                <a className="link" href={ensureHttps(website)} rel="noreferrer" target="_blank">
                  {website}
                </a>
              </BodyText>
            </CustomRow>
          )}
          {phone_number && (
            <CustomRow justifyContent="space-between">
              <BodyText textType="bodyText" color="charcoal" size="N" fontWeight="R">
                {t('accountInformation.phoneNumber')}
              </BodyText>
              <BodyText size="N" textType="bodyText" fontWeight="B">
                <a className="link" href={`tel:${phone_number}`} rel="noreferrer">
                  {phone_number}
                </a>
              </BodyText>
            </CustomRow>
          )}
        </STrnBlock>
      ) : null,
    [website, phone_number]
  );

  const categoryBlock = useMemo(() => {
    const categoryName = primaryCategory || 'OTHER';

    return (
      <STrnBlock className="trn-block">
        <CustomRow marginBottom={16} justifyContent="space-between">
          <BodyText textType="bodyText" color="charcoal" size="N" fontWeight="R" className="category-label">
            {t('accountInformation.Category')}
          </BodyText>
          <div className="category-group">
            {categoryName && (
              <>
                {transactionEventId && <Icon name="pencilThin" color="blue" size="small" onClick={() => setCategoryEditOpen(true)} marginRight={10} cursorPointer />}
                <BodyText textType="bodyText" color="charcoal" size="N" fontWeight="B" textAlign="end" className="category-name">
                  <Trans i18nKey={`balancesTransactions.categories.${categoryName}`} ns="balancesTransactions" />
                </BodyText>
              </>
            )}
            <Icon name={snakeCaseToCamelCase(categoryName)} fallbackName="other" size="small" color="grey5" className="category-icon" cursorPointer />
          </div>
        </CustomRow>
        <CustomRow justifyContent="space-between">
          <BodyText textType="bodyText" color="charcoal" size={isMobileSmallSize ? 'T' : 'N'} fontWeight="R">
            {t('accountInformation.Type')}
          </BodyText>
          <PaymentDescription trnCode={trnCode || ''} isSheet />
        </CustomRow>
      </STrnBlock>
    );
  }, [primaryCategory, transactionEventId, trnCode]);

  return (
    <>
      <CustomSheet isOpen={isOpen} paddingTop="25px" header={false} wrapperPadding={false} onClose={onClose} modalName="transactionDetails">
        {isLoading && <Loader size="small" />}
        <STransactionContainer>
          <STitleContainer>
            <Title font="Poppins" fontWeight="SB" size={isMobileSmallSize ? 'T' : 'L'}>
              {drCrType === 'Debit' ? '- ' : '+ '}
              {formatAutoSign(trnAmt?.amt)}
            </Title>
            <PaymentIcon iconUrl={merchantLogo} iconName={primaryCategory} trnCode={trnCode || ''} type={drCrType === 'Debit' ? 'expense' : 'income'} />
          </STitleContainer>
          <CustomRow marginBottom={35} flexDirection="column" alignItems="flex-start">
            <Title font="Poppins" color="charcoal" fontWeight="SB" size="sS">
              {merchantName || desc[0]}
            </Title>
            <BodyText textType="bodyText" color="charcoal70" size={isMobileSmallSize ? 'T' : 'N'} fontWeight="R" marginTop={4}>
              {trnDt && formatLocaleDate(new Date(trnDt), 'MMMM dd, EEEE, HH:mm', locale)}
            </BodyText>
          </CustomRow>

          {mapBlock}
          {contactBlock}
          {categoryBlock}

          {transactionEventId && (
            <STrnBlock className="trn-block">
              <CustomRow marginBottom={4} justifyContent="space-between">
                <BodyText textType="bodyText" color="charcoal" size="N" fontWeight="R">
                  {t('accountInformation.note')}
                </BodyText>
                <Icon name="pencilThin" color="blue" size="small" onClick={() => setEditNoteOpen(true)} />
              </CustomRow>
              <CustomRow>
                <BodyText textType="bodyText" color="grey5" size="T" fontWeight="R">
                  {userNote || t('accountInformation.You have not yet taken note on the transaction')}
                </BodyText>
              </CustomRow>
            </STrnBlock>
          )}
        </STransactionContainer>
      </CustomSheet>
      <CategorySelectSheet
        loading={updateTransactionResult.isLoading}
        currentCategory={category}
        categories={preparedCategories}
        onSelect={handleSaveCategory}
        onClose={() => setCategoryEditOpen(false)}
        open={categoryEditOpen}
      />
      <EditTransactionNoteSheet loading={updateTransactionResult.isLoading} open={editNoteOpen} onClose={() => setEditNoteOpen(false)} note={userNote || ''} onSave={handleUpdateNote} />
    </>
  );
};
