import styled, { css } from 'styled-components';
import { getColor, mediaUpTo } from 'utils/helpers/styleHelpers';

export const SLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 100%;
  padding-bottom: 48px;

  header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-bottom: 12px;

    ${mediaUpTo(
      576,
      css`
        .svg-icon {
          display: none;
        }
      `
    )}
  }

  .card-container {
    background: ${getColor('white')};
    border: 2px solid #ece8dc;
    border-radius: 16px;
    padding: 24px 24px 8px 24px;
    margin-bottom: 8px;

    > div:first-child {
      display: flex;
      gap: 12px;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 26px;
    }

    div > image {
      width: 44px;
      height: 28px;
    }

    .info-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }

    .action-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      width: 100%;
      cursor: pointer;
    }
  }
`;
