import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { BodyText, Title } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';
import Card from 'assets/images/card.svg';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import { useTranslation } from 'react-i18next';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { useDevLogger } from 'utils/hooks/useDevLogger';
import { Loader } from 'components/general/Loader/Loader';
import { useAddCardMutation } from 'store/user/users.api';
import { IAccountItem } from 'store/user/accounts/accounts.types';
import { theme } from 'styles/theme';
import { SCardContainer } from './ValidateCardSheet.styles';

interface IValidateCardSheet {
  isOpen: boolean;
  selectedAccount?: IAccountItem | undefined;
  internalAccounts?: IAccountItem[];
  fromSendMoney?: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

export const ValidateCardSheet: React.FC<IValidateCardSheet> = ({ isOpen, selectedAccount, internalAccounts = [], fromSendMoney = false, onClose, onSuccess }) => {
  const [addCardAPI, addCardAPIResult] = useAddCardMutation();
  const { t } = useTranslation('moveMoney');
  const { isDesktopSize } = useDeviceDimension();
  const { log, error } = useDevLogger();
  const [selectedAcc, setSelectedAcc] = useState<IAccountItem | undefined>();
  const [isDisabled, setIsDisabled] = useState(true);

  const collectRef = useRef<any>(null);

  const css = {
    fontSize: '16px',
    fontFamily: 'DM Sans',
    fontWeight: '400',
    '&:placeholder': {
      fontSize: '16px',
      fontFamily: 'DM Sans',
      fontWeight: '400',
      color: theme.charcoal40,
    },
    color: theme.charcoal,
    borderRadius: '16px',
    border: `2px solid ${theme.blue}`,
    width: '90px',
    height: '56px',
    padding: '0 20px',
    background: theme.white,
  };

  useEffect(() => {
    if (window.VGSCollect && document.getElementById('cc-cvc')) {
      collectRef.current = window.VGSCollect.create('tnt7t75zytm', 'sandbox', (state: any) => {
        log('CVC form state:', state);
        setIsDisabled(!state.card_cvc.isValid);
      }).setRouteId('e3a2eb7b-a8a0-4e54-83f9-8140a2bae70a');

      collectRef.current.field('#cc-cvc', {
        type: 'card-security-code',
        name: 'card_cvc',
        placeholder: 'CVV',
        validations: ['required', 'validCardSecurityCode'],
        css,
      });
    }
  }, [selectedAcc]);

  const submitVGSCollectForm = () => {
    if (!collectRef.current) {
      error('VGS Collect form is not initialized');
      return;
    }

    collectRef.current.submit(
      '/post',
      {},
      (status: any, data: any) => {
        if (status >= 200 && status <= 300) {
          log('Successful response', data);

          const cvvToken = data.card_cvc;
          addCardAPI({ accountId: selectedAccount?.accountId || selectedAcc?.accountId, cvvToken }).then(() => {
            onSuccess?.();
            onClose();
          });
        } else if (!status) {
          error('error', status);
          log('Network error occured');
        } else {
          error('Server error');
        }
      },
      (validationError: any) => {
        log(validationError);
      }
    );
  };

  if (addCardAPIResult.isLoading) return <Loader />;

  return (
    <CustomSheet isModal={isDesktopSize} destroyOnClose isOpen={isOpen} header={false} modalName="validateCard" height="fit-content" wrapperPadding={isDesktopSize} onClose={onClose}>
      <Title marginBottom={4}>
        {fromSendMoney && internalAccounts.length > 1 && t('internationalTransferPage.selectCardSheet.Title')}
        {fromSendMoney && internalAccounts.length === 1 && t('internationalTransferPage.confirmCardSheet.Title')}
        {!fromSendMoney && t('internationalTransferPage.validateCardSheet.Title')}
      </Title>
      <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R">
        {fromSendMoney && internalAccounts.length > 1 && t('internationalTransferPage.selectCardSheet.Description')}
        {fromSendMoney && internalAccounts.length === 1 && t('internationalTransferPage.confirmCardSheet.Description')}
        {!fromSendMoney && t('internationalTransferPage.validateCardSheet.Description')}
      </BodyText>

      {fromSendMoney &&
        internalAccounts.length > 1 &&
        internalAccounts.map((account: IAccountItem) => (
          <SCardContainer className={clsx({ selected: selectedAcc?.accountId === account.accountId })} onClick={() => setSelectedAcc(account)}>
            <div className="card-holder">
              <img src={Card} alt="Credit card" />

              <div>
                <BodyText textType="bodyText" size="N" fontWeight="B" color="charcoal">
                  **** **** **** {account?.lastFourDigits}
                </BodyText>
                <BodyText textType="bodyText" size="T" fontWeight="R" color="charcoal70">
                  {account?.name}
                </BodyText>
              </div>
            </div>

            {selectedAcc?.accountId === account.accountId && (
              <form id="vgs-collect-form">
                <div id="cc-cvc" />
              </form>
            )}
          </SCardContainer>
        ))}

      {(!fromSendMoney || internalAccounts.length === 1) && (
        <SCardContainer className="selected">
          <div className="card-holder">
            <img src={Card} alt="Credit card" />

            <div>
              <BodyText textType="bodyText" size="N" fontWeight="B" color="charcoal">
                **** **** **** {selectedAccount?.lastFourDigits || internalAccounts[0]?.lastFourDigits}
              </BodyText>
              <BodyText textType="bodyText" size="T" fontWeight="R" color="charcoal70">
                {selectedAccount?.name || internalAccounts[0]?.name}
              </BodyText>
            </div>
          </div>

          <form id="vgs-collect-form">
            <div id="cc-cvc" />
          </form>
        </SCardContainer>
      )}

      <Button onClick={submitVGSCollectForm} preset="blue-filled" disabled={isDisabled} marginBottom={24}>
        {fromSendMoney && internalAccounts.length > 1 && t('internationalTransferPage.selectCardSheet.Button')}
        {fromSendMoney && internalAccounts.length === 1 && t('internationalTransferPage.confirmCardSheet.Button')}
        {!fromSendMoney && t('internationalTransferPage.validateCardSheet.Button')}
      </Button>

      <BodyText textType="bodyText" color="charcoal70" size="T" fontWeight="R" marginBottom={12}>
        {t('internationalTransferPage.validateCardSheet.Disclosure1')}
      </BodyText>
      <BodyText textType="bodyText" color="charcoal70" size="T" fontWeight="R">
        {t('internationalTransferPage.validateCardSheet.Disclosure2')}
      </BodyText>
    </CustomSheet>
  );
};
