import React from 'react';
import { ROUTES } from 'vars/const/ROUTES';
import { motion } from 'framer-motion';
import clsx from 'clsx';
import { Outlet, useNavigate, matchRoutes, useLocation } from 'react-router-dom';
import { useLogin } from 'utils/hooks/useLogin';
import { Icon } from 'components/general/Icon/Icon';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { SuttonDisclaimerNote } from 'components/general/DisclaimerNote/SuttonDisclaimerNote';
import { useRouteConfig } from 'utils/hooks/useRouteConfig';
import { SAuthLayout, SLayoutInner, SLayoutContent, SExtendedLayout, SDesktopWrapper, SDesktopBlock, SSuttonDisclaimerNoteWrapper, SHeaderLeftBtn } from './AuthLayout.styles';
import { AuthLayoutFooter } from './AuthLayoutFooter/AuthLayoutFooter';
import { AuthTopLogo } from './AuthLayoutHeader/AuthTopLogo';

type TAuthLayoutProps = {
  type?: string;
  tabletWidth?: string;
  showHeader?: boolean;
  showMobileHeader?: boolean;
  showDesktopHeader?: boolean;
  showFooter?: boolean;
  showMobileFooter?: boolean;
  showDesktopFooter?: boolean;
  showDesktopSuttonDisclaimer?: boolean;
};

const BACK_NAVIGATABLE_ROUTES = [ROUTES.registrationCode];

export const AuthLayout = ({
  type,
  tabletWidth,
  showHeader = true,
  showMobileHeader = false,
  showDesktopHeader = false,
  showFooter = true,
  showMobileFooter = false,
  showDesktopFooter = false,
  showDesktopSuttonDisclaimer = false,
}: TAuthLayoutProps) => {
  const location = useLocation();
  const loginClick = useLogin();
  const navigate = useNavigate();
  const { fromTablet } = useDeviceDimension();
  const { headerType } = useRouteConfig();
  const isHeader = (showDesktopHeader && fromTablet) || (showMobileHeader && !fromTablet) || (!showDesktopHeader && !showMobileHeader && showHeader);
  const isFooter = (showDesktopFooter && fromTablet) || (showMobileFooter && !fromTablet) || (!showDesktopFooter && !showMobileFooter && showFooter);
  const isSuttonDisclaimer = showDesktopSuttonDisclaimer && fromTablet;
  const isBackNavigatable = matchRoutes(BACK_NAVIGATABLE_ROUTES, location.pathname);
  const isWhiteLogo = !(type === 'v2' || type === 'onboarding' || type === 'onboarding-first-steps');
  const headerClassname = clsx('auth-layout__header', { 'auth-layout__header_mobile-simple': headerType === 'logo-simple' });

  const handleLogoClick = () => {
    navigate(ROUTES.registration.path);
  };

  const handleBackBtnClick = () => navigate(-1);

  return (
    <SAuthLayout altLayout={type} className="auth-layout">
      {isHeader && (
        <div className={headerClassname}>
          {isBackNavigatable && (
            <SHeaderLeftBtn onClick={handleBackBtnClick} data-testid="back-btn">
              <Icon size="small" name="arrowLeft" color="white" cursorPointer />
            </SHeaderLeftBtn>
          )}
          <AuthTopLogo isWhite={isWhiteLogo} onClick={handleLogoClick} />
        </div>
      )}

      <SLayoutInner as={motion.div} animate={loginClick.motionVariant()} transition={{ y: { duration: 0.5 } }} altLayout={type} className="auth-layout__inner">
        <SLayoutContent className="auth-layout__content">
          {fromTablet ? (
            <SDesktopWrapper>
              <SDesktopBlock tabletWidth={tabletWidth}>
                <Outlet />
              </SDesktopBlock>
            </SDesktopWrapper>
          ) : (
            <Outlet />
          )}
        </SLayoutContent>

        {isSuttonDisclaimer && (
          <SSuttonDisclaimerNoteWrapper>
            <SuttonDisclaimerNote />
          </SSuttonDisclaimerNoteWrapper>
        )}

        {isFooter && <AuthLayoutFooter />}
      </SLayoutInner>
      {loginClick.isClicked && <SExtendedLayout />}
    </SAuthLayout>
  );
};
