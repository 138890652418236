export interface ICurAmt {
  amt: number;
}

export interface IAcctBalItem {
  balType: string;
  curAmt: ICurAmt;
}

export enum EAccountType {
  CASH = 'Cash',
  STUFF = 'Stuff',
  SAVE = 'Save',
}

export interface IAddAccountRequest {
  type?: EAccountType;
  percapitaAccountType?: EAccountType;
  nickname?: string;
  jointInviteType?: string;
  inviteId?: string;
  cashAccountId?: string;
  userReferralCode?: string;
  transactionId?: string;
}

export interface IAddAccountResponse {}

export type IGetAccountTransactionsRequest = {
  accountId: string;
  accountType: string;
  dateTo: string;
  dateFrom: string;
};

export type IGetAccountTransactionsResponse = {
  acctTrnRec: IAcctTrnRecItem[];
};

export interface IAcctSummInfo {
  acctBal: IAcctBalItem[];
  acctDtlStatus: string;
  acctMajorType: string;
  desc: string;
  employeeInd: boolean;
  openDt: string;
  rate: number;
  restrictedInd: boolean;
}

export interface IAcctRef {
  acctSummInfo: IAcctSummInfo;
}

export interface IPartyAcctRelDataItem {
  partyAcctRelDesc: string;
  partyAcctRealType: string;
}

export interface IPartyAcctRelInfo {
  acctRef: IAcctRef;
  acctRelClass: string;
  nickname: string;
  ownerInd: boolean;
  partyAcctRelData: IPartyAcctRelDataItem[];
  primaryOwnerInd: boolean;
  taxReportingOwnerInd: boolean;
}

export interface IAcctKeys {
  acctId: string;
  acctType: string;
  owner: boolean;
  ownerId: string;
  ownerName?: string;
  jointHolderName?: string;
  hasJointHolder: boolean;
  percapitaAccountId: string;
  percapitaAccountType: string;
  debitCardNumber: string | null;
  isDefault?: boolean;
  parentAccountId?: string;
  isAddedToReadyRemit: boolean;
}

export interface IPartyKeys {
  partyId: string;
}

export interface IPartyAcctRelKeys {
  acctKeys: IAcctKeys;
  partyKeys: IPartyKeys;
}

export interface IPartyAcctRelStatus {
  effDt: string;
  partyAcctRelStatusCode: string;
}

export interface IAccountItem {
  id?: string;
  accountId: string;
  balance: number;
  fiservAccountId: string;
  fiservAccountType: string;
  owner: boolean;
  ownerId: string;
  ownerName?: string;
  ownerInd?: boolean;
  primaryOwnerInd?: boolean;
  type: string;
  percapitaType: string;
  cardNumber?: string | null;
  debitCardNumber?: string | null;
  externalDisplayAccountName?: string;
  accountNumber?: string;
  isDefault: boolean;
  isPrimary?: boolean;
  isJoint?: boolean;
  nickname: string;
  maskedAccountId?: string;
  shortName: string;
  name: string;
  icon: string;
  displayType: string;
  thirdPartyAccountSubType?: string | null;
  thirdPartyAccountId?: string | null;
  thirdPartyProvider?: string | null;
  externalDisplayAccountNumber?: string | null;
  jointHolderName?: string;
  parentAccountId: string;
  hasJointHolder: boolean;
  isCashType: boolean;
  isNeedsType: boolean;
  isGoalsType: boolean;
  isAddedToReadyRemit?: boolean;
  lastFourDigits?: string;
}

export interface IPartyAcctRelRecItem {
  partyAcctRelInfo: IPartyAcctRelInfo;
  partyAcctRelKeys: IPartyAcctRelKeys;
  partyAcctRelStatus: IPartyAcctRelStatus;
}

export interface IRecCtrlOut {
  sentRecCount: number;
}

export interface IStatus {
  severity: string;
  statusCode: string;
  statusDesc: string;
  svcProviderName: string;
}

export interface ISubAccountsItem {
  fiservAccountId: string;
  id: string;
  isActive: boolean;
  parentAccountId: string | null;
  userId: string;
}

export interface IFiservAccounts {
  partyAcctRelRec: IPartyAcctRelRecItem[];
  recCtrlOut: IRecCtrlOut;
  status: IStatus;
}

export interface IGetAccountsResponse {
  account: null;
  fiservAccounts: IFiservAccounts;
  subAccounts: ISubAccountsItem[];
}

export type TAddAccountType = 'needs' | 'goals';
export interface ICurrentTransfer {
  fromId?: string | 'WAITING_TO_ADD' | 'JUST_ADDED';
  toId?: string | 'WAITING_TO_ADD' | 'JUST_ADDED';
  amount?: string;
  note?: string;
  isExternalTransfer?: boolean;
}

export interface IInitialAccountsState {
  fiservAccountsData: IFiservAccounts;
  recentTransactions: IAcctTrnRecItem[];
  currentTransfer: ICurrentTransfer;
  immediatePayFrameUrl: string;
  closingRequested: string;
  lastTransactionFromAccount: string;
  lastTransactionToAccount: string;
  lastTransactionAmount: string;
  lastTransactionDate: string;
  lastTransactionNote: string;
  walletAccounts: IWalletAccount[];
  thirdPartyData: IThirdParty[];
  cashAccountId: string;
  addAccountType: TAddAccountType;
  pennyJarDestinationAccount: IAccountItem;
  pennyJarDestinationAccounts: IAccountItem[];
  summaryTopAccount: IAccountItem | null;
  pennyJarBackRoute: string;
}

export interface ICurCode {
  curCodeType: string;
  curCodeValue: string;
}

export interface IStmtRunningBalItem {
  amt: number;
  curCode: ICurCode;
  stmtRunningBalType: string;
}

export interface ITrnAmt {
  amt: number;
  curCode: ICurCode;
}

export interface IAcctTrnInfo {
  transactionEventId?: string;
  enrichedData: any;
  cspRefIdent: string;
  desc: string[];
  drCrType: string;
  effDt: string;
  memoPostInd: boolean;
  postedDt: string;
  restrictedInd: boolean;
  stmtRunningBal: IStmtRunningBalItem[];
  tellerIdent: string;
  trnAmt: ITrnAmt;
  trnCode: string;
  trnDt: string;
  locationLatitude: string;
  locationLongitude: string;
  merchantLogo: string;
  merchantName: string;
  primaryCategory: string;
  userNote: string;
}

export interface IAcctKeysTrn {
  acctId: string;
  acctType: string;
}

export interface IAcctTrnKeys {
  acctKeys: IAcctKeysTrn;
  acctTrnIdent: string;
}

export interface IAcctTrnStatus {
  acctTrnStatusCode: string;
  effDt: string;
}

export interface IAcctTrnRecItem {
  acctTrnInfo: IAcctTrnInfo;
  acctTrnKeys: IAcctTrnKeys;
  acctTrnStatus: IAcctTrnStatus;
}

export interface ISetLastTransactionData {
  lastTransactionFromAccount: string;
  lastTransactionToAccount: string;
  lastTransactionAmount: string;
  lastTransactionDate: string;
  lastTransactionNote: string;
}

export interface WalletAccountAdditionalData {}

export interface WalletAccountDetails {
  additionalData: WalletAccountAdditionalData;
  availability: string;
  bankName: string;
  currency?: any;
  expirationDate: string;
  iban?: any;
  number: string;
  route: string;
  styleId: number;
  type: string;
}

export interface IWalletAccount {
  accountDetails: WalletAccountDetails;
  accountType: string;
  accountTypeDetails: string;
  alias: string;
  balance?: any;
  details: string;
  failedAttemptsCount: number;
  id: number;
  modifiedDateTime: Date;
  name: string;
  primaryAccount: boolean;
  proxyNumber?: any;
  status: string;
}

export interface IThirdParty {
  accountId: string;
  dateCreated: string;
  externalDisplayAccountName: string;
  externalDisplayAccountNumber?: string;
  externalExpirationDate?: string;
  id: string;
  ownerAddress1?: string;
  ownerCity?: string;
  ownerState?: string;
  ownerZip?: string;
  thirdPartyAccountId: string;
  thirdPartyAccountSubType?: string;
  thirdPartyAccountType: string;
  thirdPartyDetails?: string;
  thirdPartyProvider?: string;
  thirdPartySessionID?: string;
  userId: string;
  issuingNetwork: string;
  transactionsCount?: number;
}

export const isThirdPartyAccount = (account: IThirdParty | IAccountItem): account is IThirdParty => {
  return (account as IThirdParty)?.thirdPartyAccountId !== undefined;
};

export interface ITransferAccountResultData {
  name: string;
  number: string;
}

export interface IRoundUp {
  accountId: string;
  amount: number;
}

export interface IOverdraft {
  isEligible: boolean;
  reason: string;
  amount: number;
}

export interface IFeatureItem {
  accountId?: string;
  type: 'RoundUp' | 'Overdraft' | 'overdraft';
  isEnabled: boolean;
  roundUp: IRoundUp | null;
  overdraft: IOverdraft | null;
}

export interface IValidateRequest {
  bankName: string;
  bankRoutingNumber: string;
  bankAccountNumber: string;
  first: string;
  last: string;
  middle?: string;
}

export interface IInviteRequest {
  inviteId?: string;
}

export interface IInviteHolderRequest {
  accountId?: string;
  id?: string;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  inviterFirstName?: string;
  isPrimaryOwner?: boolean;
}

export interface IOwnerJointInvite {
  id: string;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  timestamp: string;
  inviterUserId: string;
  inviterFirstName: string;
  inviterLastName: string;
  expirationDate: string;
  accountId: string;
}
