import React, { useEffect, useState } from 'react';
import { BodyText, Title } from 'components/general/Typography';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'vars/const/ROUTES';
import { useToggle } from 'utils/hooks/useToggle';
import { Loader } from 'components/general/Loader/Loader';
import { Button } from 'components/theme/Button/Button';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { useEditUserProfileDataMutation, useGetUserProfileDataMutation, usersApi } from 'store/user/users.api';
import { selectCurrentUser } from 'store/user/authentication.slice';
import { useCashAccountOpening } from 'utils/hooks/useCashAccountOpening';
import { CustomRequiredLabel } from 'components/theme/CustomRequiredLabel/CustomRequiredLabel';
import { Header } from 'views/OpenCashAccount/MyInfo/Header/Header';
import { Footer } from 'views/OpenCashAccount/MyInfo/Footer/Footer';
import { ConsentType } from 'components/general/Consent/Consents.types';
import { useConsents } from 'utils/hooks/useConsents';
import { ConsentSheet } from 'components/general/Consent/ConsentSheet';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { employerNameCharacterRule } from 'utils/helpers/validationRules';
import { useFormHelpers } from 'utils/hooks/useFormHelpers';
import { AutocompleteInput } from 'components/general/AutocompleteInput/AutocompleteInput';
import { SMyDetailsWrapper, SLayout } from './MyDetailsPage.styles';
import { PrimarySource } from './PrimarySource/PrimarySource';
import { EstimatedSource } from './EstimatedSource/EstimatedSource';
import { EmploymentStatus } from './EmploymentStatus/EmploymentStatus';
import { MilitaryStatus } from './MilitaryStatus/MilitaryStatus';
import { MakeJointCashAccountModal } from './MakeJointCashAccountModal/MakeJointCashAccountModal';
import { TRecord } from './MyDetailsPage.types';
import { MakeJointCashAccountWebModal } from './MakeJointCashAccountModal/MakeJointCashAccountWebModal';

interface TUserDetailsState {
  incomeSource: string;
  annualIncome: string;
  employmentStatus: string;
  militaryStatus: string;
  employerName: string;
}

const EMPLOYER_NAME_RELATION = {
  Student: 'N/A - Student',
  Retired: 'N/A - Retired',
  'Unemployed (currently looking for work)': 'N/A - Unemployed',
  'Unemployed (not currently looking for work)': 'N/A - Unemployed',
};

const initUserDetails = {
  incomeSource: '',
  annualIncome: '',
  employmentStatus: '',
  militaryStatus: '',
  employerName: '',
};

export const MyDetailsPage = () => {
  const { saveOnboardingData, openingAccountData, isLoading: openingAccountDataLoading } = useCashAccountOpening();
  const [detailsForm] = useForm();
  const [userDetails, setUserDetails] = useState<TUserDetailsState>(initUserDetails);
  const { primarySourceOfIncome, annualHouseholdIncome, employmentStatus, militaryStatus, employerName, userId } = useSelector(selectCurrentUser) || {};
  const { consentsData } = useConsents(ConsentType.SUTTON);
  const [editUserProfileData, editUserProfileDataResult] = useEditUserProfileDataMutation();
  const [getUserProfileData] = useGetUserProfileDataMutation();
  const getEmployersQuery = usersApi.useGetEmployersQuery();
  const { hasErrors, checkErrors } = useFormHelpers(detailsForm);
  const { t } = useTranslation(['cashAccountOpening', 'prepPage']);
  const navigate = useNavigate();
  const { isDesktopSize } = useDeviceDimension();

  const makeJointCashAccountModal = useToggle();
  const makeCashAccountWebModal = useToggle();
  const disclosureSheet = useToggle();

  const isDisable = !userDetails.annualIncome || !userDetails.incomeSource || !userDetails.employmentStatus || !userDetails.militaryStatus || !userDetails.employerName;

  const handleOnContinue = async () => {
    const userData = {
      primarySourceOfIncome: userDetails.incomeSource,
      annualHouseholdIncome: userDetails.annualIncome,
      employmentStatus: userDetails.employmentStatus,
      militaryStatus: userDetails.militaryStatus,
      employerName: userDetails.employerName,
    };

    await saveOnboardingData({ ...userData, currentUrl: ROUTES.myInfoSummary.path });
    await editUserProfileData({ profileData: userData });

    if (userId) {
      getUserProfileData({ userId });
    }

    if (openingAccountData.currentJointInviteId) {
      navigate(ROUTES.myInfoSummary.path);
    } else {
      if (!isDesktopSize) {
        makeJointCashAccountModal.show();
      } else {
        makeCashAccountWebModal.show();
      }
    }
  };

  const handleOnYes = () => navigate(ROUTES.myAccountJointAccounts.path, { state: { isAccountSetup: true, isFromMyDetailsPage: true } });
  const handleOnNo = () => navigate(ROUTES.myInfoSummary.path);

  const handleStatusChange = (event: TRecord) => {
    const newEmployerName = EMPLOYER_NAME_RELATION[event.value as keyof typeof EMPLOYER_NAME_RELATION] || userDetails.employerName;
    setUserDetails({ ...userDetails, employmentStatus: event.value, employerName: newEmployerName });
  };

  useEffect(() => {
    detailsForm.setFieldValue('employer-name', userDetails?.employerName ?? '');
  }, [userDetails]);

  useEffect(() => {
    setUserDetails({
      incomeSource: primarySourceOfIncome || '',
      annualIncome: annualHouseholdIncome || '',
      employmentStatus: employmentStatus || '',
      militaryStatus: militaryStatus || '',
      employerName: employerName || '',
    });

    checkErrors();
  }, []);

  return (
    <SLayout>
      {(editUserProfileDataResult.isLoading || getEmployersQuery.isLoading || openingAccountDataLoading) && <Loader />}
      <Header title={t(`accountOpening.AFewMoreDetails`)} stage="My Details" webStage="Details" marginBottom={28} />

      <div className="content-container">
        <div className="web-title-container">
          <Title>{t(`accountOpening.AFewMoreDetails`)}</Title>
        </div>

        <SMyDetailsWrapper>
          <CustomCard marginBottom={32} background="blue5" borderRadius={16} padding="16px 25px" className="card-header">
            <BodyText textType="bodyText" size="T" fontWeight="B" color="charcoal" textAlign="start" lineHeight={1.4}>
              {t('accountOpening.KYCRequirements')}
            </BodyText>
          </CustomCard>
          <EmploymentStatus value={userDetails.employmentStatus ? t(`incomeSource.${userDetails.employmentStatus}`) : ''} onChange={handleStatusChange} />

          <div className="primary-employer-name">
            <CustomRequiredLabel label={t('accountOpening.Primary Employer Name')} fontFamily="DM Sans" marginBottom={10} />

            <Form form={detailsForm} name="details-form" onFieldsChange={checkErrors}>
              <Form.Item name="employer-name" validateTrigger={['onBlur', 'onChange']} rules={[employerNameCharacterRule()]}>
                <AutocompleteInput
                  suggestions={getEmployersQuery?.data ?? []}
                  value={userDetails.employerName}
                  placeholder={t('accountOpening.Enter Your Primary Employer Name')}
                  onChange={(val) => setUserDetails({ ...userDetails, employerName: val })}
                />
              </Form.Item>
            </Form>
          </div>

          <MilitaryStatus
            value={userDetails.militaryStatus ? t(`incomeSource.${userDetails.militaryStatus}`) : ''}
            onChange={(data) => setUserDetails({ ...userDetails, militaryStatus: data.value })}
          />

          <PrimarySource value={userDetails.incomeSource ? t(`incomeSource.${userDetails.incomeSource}`) : ''} onChange={(data) => setUserDetails({ ...userDetails, incomeSource: data.value })} />

          <EstimatedSource value={userDetails.annualIncome ? t(`incomeSource.${userDetails.annualIncome}`) : ''} onChange={(data) => setUserDetails({ ...userDetails, annualIncome: data.name })} />
        </SMyDetailsWrapper>
      </div>

      <div className="web-footer">
        <Footer isDisabled={isDisable || hasErrors} onClick={handleOnContinue} />
      </div>

      <div className="mobile-footer">
        <Button disabled={isDisable || hasErrors} onClick={handleOnContinue} preset={!isDisable ? 'blue-filled' : 'red-filled'} marginBottom={24}>
          {t('myInfo.Continue')}
        </Button>

        <BodyText textType="bodyText" fontWeight="M" size="T" color="charcoal70" textAlign="end" className="next-step" marginBottom={16} justifyContent="end">
          {t('accountOpening.NextStep')} {t('accountOpening.Verification')}
        </BodyText>

        <BodyText textType="bodyText" fontWeight="R" size="T" color="charcoal50" textAlign="start" marginBottom={30}>
          {t('prepPage.PercapitaGroupInc', { ns: 'prepPage' })}
        </BodyText>
      </div>
      <MakeJointCashAccountModal
        open={makeJointCashAccountModal.isActive}
        onClose={makeJointCashAccountModal.hide}
        showDisclosureSheet={disclosureSheet.show}
        handleOnNo={handleOnNo}
        handleOnYes={handleOnYes}
      />
      <MakeJointCashAccountWebModal
        open={makeCashAccountWebModal.isActive}
        onClose={makeCashAccountWebModal.hide}
        handleOnNo={handleOnNo}
        handleOnYes={handleOnYes}
        showDisclosureSheet={disclosureSheet.show}
      />
      {consentsData?.map((disclosure) => (
        <ConsentSheet key={disclosure.id} consentData={disclosure} isOpen={disclosureSheet.isActive} onClose={disclosureSheet.hide} isReadonly />
      ))}
    </SLayout>
  );
};
