import { BodyText, Title } from 'components/general/Typography';
import Card from 'assets/images/card.svg';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SuttonDisclaimerNote } from 'components/general/DisclaimerNote/SuttonDisclaimerNote';
import { useToggle } from 'utils/hooks/useToggle';
import { Icon } from 'components/general/Icon/Icon';
import { useAccounts } from 'utils/hooks/useAccounts';
import { IAccountItem } from 'store/user/accounts/accounts.types';
import { useNavigate } from 'react-router-dom';
import { useRemoveCardMutation } from 'store/user/users.api';
import { Loader } from 'components/general/Loader/Loader';
import { ValidateCardSheet } from './ValidateCardSheet/ValidateCardSheet';
import { SLayout } from './ManageCardsPage.styles';
import { RemoveCardSheet } from './RemoveCardSheet/RemoveCardSheet';

export const ManageCardsPage: React.FC = () => {
  const navigate = useNavigate();
  const [removeCardAPI, removeCardAPIResult] = useRemoveCardMutation();
  const { internalAccounts, refetchInternal, isLoading } = useAccounts();
  const [selectedAccount, setSelectedAccount] = useState<IAccountItem | undefined>();
  const { t } = useTranslation('moveMoney');

  const validateCardSheet = useToggle();
  const removeCardSheet = useToggle();

  const handleAdd = (account: IAccountItem) => {
    setSelectedAccount(account);
    validateCardSheet.show();
  };

  const handleRemove = (account: IAccountItem) => {
    setSelectedAccount(account);
    removeCardSheet.show();
  };

  const onRemove = () => {
    removeCardAPI({ percapitaAccountId: selectedAccount?.accountId, last4: selectedAccount?.lastFourDigits });
    removeCardSheet.hide();
  };

  if (removeCardAPIResult.isLoading || isLoading) return <Loader />;

  return (
    <>
      <SLayout>
        <div>
          <header>
            <Icon cursorPointer name="arrowLeft" color="blue" size="big" onClick={() => navigate(-1)} />
            <Title>{t('internationalTransferPage.Cards')}</Title>
          </header>

          <BodyText textType="bodyText" size="N" fontWeight="R" color="charcoal70" marginBottom={24}>
            {t('internationalTransferPage.YouCanManage')}
          </BodyText>

          {internalAccounts?.map((account: IAccountItem) => (
            <div className="card-container" key={account.accountId}>
              <div>
                <img src={Card} alt="Credit card" />

                <div className="info-container">
                  <BodyText textType="bodyText" size="N" fontWeight="B" color="charcoal">
                    **** **** **** {account.lastFourDigits}
                  </BodyText>
                  <BodyText textType="bodyText" size="T" fontWeight="R" color="charcoal70">
                    {account.name}
                  </BodyText>
                </div>
              </div>

              {account.isAddedToReadyRemit ? (
                <div className="action-container" onClick={() => handleRemove(account)}>
                  <Icon color="red" name="trash" cursorPointer size="small" />
                  <BodyText textType="bodyText" color="red" size="N" fontWeight="M" cursorPointer font="Poppins">
                    {t('internationalTransferPage.RemoveCard')}
                  </BodyText>
                </div>
              ) : (
                <div className="action-container" onClick={() => handleAdd(account)}>
                  <Icon color="blue" name="circlePlus" cursorPointer size="small" />
                  <BodyText textType="bodyText" color="blue" size="N" fontWeight="M" cursorPointer font="Poppins">
                    {t('internationalTransferPage.AddCard')}
                  </BodyText>
                </div>
              )}
            </div>
          ))}
        </div>

        <SuttonDisclaimerNote />
      </SLayout>

      <ValidateCardSheet isOpen={validateCardSheet.isActive} onClose={validateCardSheet.hide} selectedAccount={selectedAccount} onSuccess={refetchInternal} />
      <RemoveCardSheet isOpen={removeCardSheet.isActive} onClose={removeCardSheet.hide} onRemove={onRemove} />
    </>
  );
};
