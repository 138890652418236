import React from 'react';
import { ROUTES } from 'vars/const/ROUTES';
import { useToggle } from 'utils/hooks/useToggle';
import { useNavigate } from 'react-router-dom';
import { AddJointAccountModal } from 'views/Account/AccountInformation/Modals/editAccount/AddJointAccountModal/AddJointAccountModal';
import { MakeJointCashAccountModal } from 'views/OpenCashAccount/MyInfo/MyDetailsPage/MakeJointCashAccountModal/MakeJointCashAccountModal';
import { ConsentType } from 'components/general/Consent/Consents.types';
import { ConsentSheet } from 'components/general/Consent/ConsentSheet';
import { useConsents } from 'utils/hooks/useConsents';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';

interface ConfirmJointAccountModalProps {
  accountForJointId?: string;
  open: boolean;
  handleClose: () => void;
  accountName?: string;
}

export const ConfirmJointAccountModal = ({ open, handleClose, accountName, accountForJointId = '' }: ConfirmJointAccountModalProps) => {
  const addJointAccountModal = useToggle();
  const { consentsData } = useConsents(ConsentType.SUTTON);
  const disclosureSheet = useToggle();
  const { isDesktopSize } = useDeviceDimension();
  const navigate = useNavigate();

  const closeAddJointModal = (isInviteError: boolean) => {
    addJointAccountModal.hide();
    if (!isInviteError) handleClose();
  };

  const handleOnYes = () => {
    if (isDesktopSize) {
      navigate(ROUTES.addJointAccount.path);
    } else {
      addJointAccountModal.show();
    }
  };

  return (
    <>
      <MakeJointCashAccountModal open={open} onClose={handleClose} showDisclosureSheet={disclosureSheet.show} handleOnNo={handleClose} handleOnYes={handleOnYes} accountName={accountName} />
      {consentsData?.map((disclosure) => (
        <ConsentSheet key={disclosure.id} consentData={disclosure} isOpen={disclosureSheet.isActive} onClose={disclosureSheet.hide} isReadonly />
      ))}
      <AddJointAccountModal accountForJointId={accountForJointId} open={addJointAccountModal.isActive} onClose={closeAddJointModal} />
    </>
  );
};
