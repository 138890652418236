import styled, { css } from 'styled-components';
import { getColor, mediaFrom } from 'utils/helpers/styleHelpers';

export const SLayout = styled.div<{ bgFooterImage?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  min-height: 100%;
  width: 100%;
  box-sizing: border-box;

  ${mediaFrom(
    'desktop',
    css`
      align-items: center;
      justify-content: space-around;
    `
  )}

  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 35px;

    ${mediaFrom(
      'desktop',
      css`
        width: 580px;
      `
    )}

    .title-container {
      width: 100%;
      margin-top: 15px;

      ${mediaFrom(
        'desktop',
        css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: start;
          margin-top: 48px;
          width: 100%;
        `
      )}
    }

    .form-card {
      display: flex;

      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      align-self: stretch;
      border-radius: 20px;

      margin-top: 32px;

      .ant-form {
        width: 100%;
      }

      ${mediaFrom(
        'tablet',
        css`
          background: ${getColor('white')};
          padding: 32px 24px;
        `
      )}

      ${mediaFrom(
        'desktop',
        css`
          width: 580px;
        `
      )}
    }
  }

  .mobile-footer {
    ${mediaFrom(
      'desktop',
      css`
        display: none;
      `
    )}

    width: 100%;
    margin-bottom: 60px;
    margin-top: 20px;

    button {
      :disabled {
        background: transparent !important;
        border: 2px solid ${getColor('charcoal40')};
        color: ${getColor('charcoal40')};
      }
    }
  }
`;
