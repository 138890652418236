import styled, { css } from 'styled-components';
import { getColor, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';

export const SLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  min-height: 95%;
  width: 100%;
  box-sizing: border-box;

  ${mediaFrom(
    'desktop',
    css`
      justify-content: space-around;
    `
  )}

  ${mediaFrom(
    'tablet',
    css`
      align-items: center;
    `
  )}

  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 35px;

    ${mediaFrom(
      'tablet',
      css`
        width: 536px;
      `
    )}

    ${mediaFrom(
      'desktop',
      css`
        width: 580px;
      `
    )}
  }

  .mobile-title-container {
    width: 100%;

    ${mediaFrom(
      577,
      css`
        display: none;
      `
    )}
  }

  .web-title-container {
    ${mediaUpTo(
      'tablet',
      css`
        display: none;
      `
    )}
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    margin-bottom: 16px;
    margin-top: 48px;
    width: 100%;
  }

  .disclosure-cards {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;

    .consent-wrapper {
      width: 100%;
    }
  }

  .disclosure-links {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    margin-top: 32px;

    ${mediaFrom(
      'desktop',
      css`
        justify-content: flex-start;
        gap: 48px;

        .body-text {
          .custom-text-inner {
            font-weight: 600;
          }
        }
      `
    )}
  }

  .mobile-footer {
    ${mediaFrom(
      'tablet',
      css`
        display: none;
      `
    )}

    width: 100%;
    margin-bottom: 60px;

    button {
      :disabled {
        background: transparent !important;
        border: 2px solid ${getColor('charcoal40')};
        color: ${getColor('charcoal40')};
      }
    }
  }

  ${mediaUpTo(
    'mobile',
    css`
      .mobile-title-container {
        .custom-text-inner {
          font-size: 12px;
        }
      }
    `
  )}
`;
