import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BodyText, Title } from 'components/general/Typography';
import { Icon } from 'components/general/Icon/Icon';
import { Button } from 'components/theme/Button/Button';
import { ROUTES } from 'vars/const/ROUTES';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { setShowComingSoonModal } from 'store/ui.slice';
import { useDispatch } from 'react-redux';
import { SCardLayout, SCustomCard } from './InternationalTransferCard.styles';

export const InternationalTransferCard = () => {
  const { t } = useTranslation('home', { keyPrefix: 'homeScreen.internationalTransferCard' });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isComingSoon = false;

  const topBadge = useMemo(() => {
    const iconName = isComingSoon ? '' : 'loudspeaker';
    const text = isComingSoon ? t('comingSoon') : t('nowAvailable');
    const badgeClassName = isComingSoon ? 'coming-soon' : 'now-available';

    return (
      <div className={clsx('top-badge', badgeClassName)}>
        {iconName && <Icon name={iconName} color="goldOrange" marginRight={10} />}

        <BodyText size="T" fontWeight="M">
          {text}
        </BodyText>
      </div>
    );
  }, [isComingSoon, t]);

  const onLearnMoreClick = () => {
    if (isComingSoon) {
      dispatch(setShowComingSoonModal(true));
    } else {
      navigate(ROUTES.internationalTransfer.path, { state: { backPage: pathname } });
    }
  };

  return (
    <SCardLayout>
      <Title>{t('internationalTransfer')}</Title>

      <SCustomCard>
        {topBadge}

        <Title className="card-title" size="sM" lineHeight="24px" marginBottom={8}>
          {t('safelySendMoneyOverseas')}
        </Title>

        <BodyText className="card-description" size="N" lineHeight="20px" color="charcoal70">
          {t('easilySendMoneyAroundTheWorld')}
        </BodyText>

        <Button className="learn-more-button" preset="blue-filled" size="middle" onClick={onLearnMoreClick}>
          {t('learnMore')}
        </Button>
      </SCustomCard>
    </SCardLayout>
  );
};
