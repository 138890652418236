// noinspection AllyPlainJsInspection

const HOME_PATH = '/home';
const OPEN_ACCOUNT_MAIN_PATH = '/open-account/main';
export type TMobileHeaderType = 'logo' | 'default' | 'logo-simple';

export interface IRouteConfig {
  path: string;
  title?: string;
  defaultBackTo?: string;
  navbar?: boolean;
  backNav?: boolean;
  noRightButtons?: boolean;
  isTitleVisible?: boolean;
  isBackButtonVisible?: boolean;
  isProfileButtonVisible?: boolean;
  isIFramePage?: boolean;
  layoutClassName?: string;
  headerType?: TMobileHeaderType;
  desktop?: {
    topBgCurves?: boolean;
    navbar?: boolean;
  };
}

export const ROUTES = {
  root: { path: '/', title: 'Root' },
  auth: { path: '/auth', title: 'Mobile Authentication' },
  app: { path: '/app/*', title: 'Redirect to Mobile App' },
  appOpenAccountVerifySmsCode: { path: '/app/open-account/my-info/verify-sms', title: 'Verify SMS code for CAO' },
  appNotificationsSettings: { path: '/app/notification-settings', title: 'App Notification Setting' },
  login: { path: '/login', title: 'Login', headerType: 'logo-simple' },
  verifyDeviceWarning: { path: '/verify-device-warning', title: 'Verify Device Warning' },
  verifyDevice: { path: '/verify-device', title: 'Verify Device' },
  started: { path: '/started', title: 'Start Page' },
  registration: { path: '/register', title: 'Registration' },
  registrationCode: { path: '/register/code', title: 'SMS code' },
  setupBiometric: { path: '/setup-biometric', title: 'Setup Biometric' },
  forgotPassword: { path: '/forgot-password', title: 'Forgot password' },
  forgotPasswordCode: { path: '/forgot-password/sms-code', title: 'SMS code' },
  forgotUsernameSent: { path: '/username-sent', title: 'Forgot username' },
  checkCode: { path: '/check-code', title: 'Check code' },
  changePassword: { path: '/change-password', title: 'Change Password' },
  onboarding: { path: '/onboarding', title: 'Onboarding: Welcome' },
  onboardingHome: { path: '/onboarding/home', title: 'Onboarding: Home' },
  onboardingFirstSteps: { path: '/onboarding/first-steps', title: 'Onboarding: First steps' },
  onboardingLanguageSelection: { path: '/onboarding/language-selection', title: 'Onboarding: Language Selection' },
  home: { path: HOME_PATH, title: 'Home' },
  logout: { path: '/logout', title: 'Logout' },
  invite: { path: '/invite', title: 'Invite' },
  refer: { path: '/refer', title: 'Refer', backNav: false },
  wellness: { path: '/wellness/:tabName?', title: '' },
  wellnessFAQ: { path: '/wellness/help/:questionName', title: '', isTitleVisible: false, backNav: true },
  balancesTransactions: {
    path: '/balances-transactions',
    navbar: false,
    backNav: true,
    defaultBackTo: HOME_PATH,
  },
  profile: {
    path: '/profile',
    title: 'Profile',
    navbar: false,
    isTitleVisible: false,
    defaultBackTo: 'menu',
    backNav: true,
    desktop: {
      navbar: true,
    },
  },
  profileChangePassword: { path: '/profile/change-password', title: 'Profile Change Password' },
  searchPayee: { path: '/search-payee', title: 'Search Payee' },
  spendingTransactions: { path: '/checking-account/transactions', title: 'Transactions' },
  savingBudgetTransactions: { path: '/saving-budget/transactions', title: 'Transactions' },
  success: { path: '/success', title: 'Success' },
  myIdSuccess: {
    path: '/open-cash-account/success',
    title: 'Cash Account Opening',
    navbar: false,
    noRightButtons: true,
  },
  openCashAccountCongrats: {
    path: '/open-cash-account/congrats',
    title: 'Cash Account Opening',
    navbar: false,
    noRightButtons: true,
  },
  myIdPending: {
    path: '/open-cash-account/pending',
    title: 'Cash Account Opening',
    navbar: false,
    noRightButtons: true,
  },
  myIdMatch: {
    path: '/open-cash-account/match',
    title: 'Cash Account Opening',
    navbar: false,
    noRightButtons: true,
  },
  uploadDocumentSuccess: {
    path: '/open-account/upload-document/success',
    navbar: false,
    noRightButtons: true,
  },
  contactInformation: {
    path: '/contact_information',
    navbar: false,
    backNav: true,
    isTitleVisible: false,
    desktop: {
      navbar: true,
    },
  },

  welcome: { path: '/welcome', title: 'Welcome' },
  attune: {
    path: '/attune',
    title: 'MyMoney Journey',
    navbar: false,
    backNav: true,
  },
  playPercUp: { path: '/playpercup', title: 'Play Percapita Plays', backNav: true, isIFramePage: true },
  percapitaPayEnroll: {
    path: '/percapita-pay/enroll',
    title: 'Move Money',
    navbar: false,
    backNav: true,
    isTitleVisible: false,
  },
  termsAndConditions: {
    path: '/percapita-pay/terms-and-conditions',
    title: 'Percapita Pay',
    isTitleVisible: false,
    backNav: true,
    defaultBackTo: HOME_PATH,
  },
  accountApproved: {
    path: '/percapita-pay/account-approved',
    title: 'Percapita Pay',
    navbar: false,
    backNav: true,
    isTitleVisible: false,
  },
  emailVerification: {
    path: '/percapita-pay/email-verification',
    title: 'Percapita Pay',
    navbar: false,
    backNav: true,
    isTitleVisible: false,
    noRightButtons: true,
  },
  verificationCode: {
    path: '/percapita-pay/verification-code',
    title: 'Verification Code',
    isTitleVisible: false,
    navbar: false,
    backNav: true,
  },
  percapitaPayHome: {
    path: '/percapita-pay/home',
    title: 'Percapita Pay',
    navbar: false,
    defaultBackTo: HOME_PATH,
    isTitleVisible: false,
    desktop: {
      navbar: true,
    },
  },
  starter: { path: '/open-account/starter', title: 'Cash Account Starter', isTitleVisible: false, backNav: true },
  openAccountPrep: {
    path: '/open-account/prep',
    title: 'Prep',
    defaultBackTo: HOME_PATH,
    navbar: false,
    headerType: 'logo',
  },
  myAccountAccounts: {
    path: '/open-account/my-account/accounts',
    title: 'Cash Account Opening',
    navbar: false,
    noRightButtons: true,
  },
  myAccountDisclosures: {
    path: '/open-account/my-account/disclosures',
    title: 'Cash Account Opening',
    navbar: false,
    noRightButtons: true,
  },
  myAccountJointAccounts: {
    path: '/open-account/my-account/joint-accounts',
    title: 'Cash Account Opening',
    navbar: false,
    backNav: true,
    noRightButtons: true,
  },
  myId: {
    path: '/open-cash-account/my-id',
    title: 'Cash Account Opening',
    navbar: false,
    noRightButtons: true,
  },
  truliooUpload: {
    path: '/open-cash-account/upload',
    title: 'Cash Account Opening',
  },
  myInfoEConsent: {
    path: '/open-account/my-info/e-consent',
    title: 'Cash Account Opening',
    defaultBackTo: OPEN_ACCOUNT_MAIN_PATH,
    navbar: false,
    backNav: true,
    noRightButtons: true,
  },
  myInfoDisclosures: {
    path: '/open-account/my-info/disclosures',
    title: 'Cash Account Opening',
    backNav: true,
    navbar: false,
    noRightButtons: true,
  },
  myInfoName: {
    path: '/open-account/my-info/name',
    title: 'Cash Account Opening',
    defaultBackTo: '/open-account/my-info/disclosures',
    navbar: false,
    backNav: true,
    noRightButtons: true,
  },
  myInfoAge: {
    path: '/open-account/my-info/age',
    title: 'Cash Account Opening',
    defaultBackTo: '/open-account/my-info/email',
    navbar: false,
    noRightButtons: true,
  },
  myInfoHomeAddress: {
    path: '/open-account/my-info/address',
    title: 'Cash Account Opening',
    defaultBackTo: '/open-account/my-info/name',
    navbar: false,
    backNav: true,
    noRightButtons: true,
  },
  myInfoTaxId: {
    path: '/open-account/my-info/tax-id',
    title: 'Cash Account Opening',
    defaultBackTo: '/open-account/my-info/age',
    navbar: false,
    noRightButtons: true,
  },
  myInfoEmailUsername: {
    path: '/open-account/my-info/email',
    title: 'Cash Account Opening',
    defaultBackTo: '/open-account/my-info/address',
    navbar: false,
    backNav: true,
    noRightButtons: true,
  },
  myInfoVerifySms: {
    path: '/open-account/my-info/verify-sms',
    title: 'Cash Account Opening',
    navbar: false,
    backNav: true,
    noRightButtons: true,
  },
  myInfoEmailVerified: {
    path: '/open-account/my-info/email-verified',
    title: 'Cash Account Opening',
    navbar: false,
    noRightButtons: true,
  },
  myInfoAdditionalDetails: {
    path: '/open-account/my-info/additional-details',
    title: 'Cash Account Opening',
    defaultBackTo: '/open-account/my-info/tax-id',
    navbar: false,
    noRightButtons: true,
  },
  myInfoSummary: {
    path: '/open-account/my-info/summary',
    title: 'Cash Account Opening',
    defaultBackTo: '/open-account/my-info/additional-details',
    navbar: false,
    backNav: true,
    noRightButtons: true,
  },
  myInfoVeriticationResult: {
    path: '/open-account/my-info/result',
    title: 'Cash Account Opening',
    defaultBackTo: '/open-account/my-info/summary',
    navbar: false,
    noRightButtons: true,
  },
  openNewCash: {
    path: '/open-account/open-new-cash',
    title: 'Cash Account Opening',
    noLeftNav: true,
    noRightButtons: true,
    isRoundedDesktopHeader: true,
    noBottomNav: true,
  },
  openNewCashApproved: {
    path: '/open-account/open-new-cash/approved',
    title: 'Cash Account Opening',
    noLeftNav: true,
    noRightButtons: true,
    isRoundedDesktopHeader: true,
    noBottomNav: true,
  },
  feeSchedulePage: {
    path: '/fee-schedule',
    defaultBackTo: '/menu',
    isTitleVisible: false,
  },
  transactionsHistory: { path: '/transactions/history', title: 'Transactions History' },
  mainMenu: {
    path: '/menu',
    title: 'Menu',
  },
  subscriptions: { path: '/subscriptions', title: 'Susbscriptions', isTitleVisible: false, navbar: false, backNav: true },
  percapitaPayBalance: { path: '/percapita-pay/home', title: 'Percapita Pay: Balance', backNav: true },
  internalTransfer: {
    path: '/accounts/internal-transfer',
    title: 'Home',
    navbar: false,
    backNav: true,
    noRightButtons: true,
    defaultBackTo: '/home',
  },
  notificationSettings: {
    path: '/notification-settings',
    title: 'Notification Settings',
    isTitleVisible: false,
    navbar: false,
    backNav: true,
    desktop: {
      navbar: true,
    },
  },
  requesToCloseCashAccount: {
    path: '/request-to-close-cash-account',
    title: 'Request to Close Cash Account',
    isTitleVisible: false,
    navbar: false,
    backNav: false,
    desktop: {
      navbar: false,
    },
  },
  transactionLimits: {
    path: '/transaction-limits',
    title: '',
    defaultBackTo: '/profile',
    navbar: false,
    desktop: {
      navbar: true,
    },
  },
  helpAndSupport: {
    path: '/help-and-support/:groupId?/:questionName?',
    title: 'Menu',
    navbar: false,
    backNav: true,
    isTitleVisible: false,
    desktop: {
      navbar: true,
    },
  },
  helpAndSupportSearch: {
    path: '/help-and-support/search/:questionName?',
    title: '',
    defaultBackTo: '/help-and-support',
    navbar: false,
    desktop: {
      navbar: true,
    },
  },
  percapitaPayHistory: {
    path: '/percapita-pay/history',
    title: '',
    navbar: false,
    backNav: true,
    isTitleVisible: false,
  },
  productsInterests: { path: '/products-interests', title: 'Get updated when we launch our products.' },
  successfulAccountOpen: { path: '/account-open/success', title: 'Account Open Success', backNav: true },
  depositOnboard: { path: '/deposit-onboard', title: '', navbar: false, noRightButtons: true, isTitleVisible: false, backNav: true },
  startDeposit: { path: '/start-deposit', title: '3 Great Features', navbar: false, noRightButtons: true, backNav: true },
  directDeposit: { path: '/direct-deposit', title: '3 Great Features', navbar: false, noRightButtons: true, backNav: true },
  directDepositNow: { path: '/direct-deposit-now', title: '', defaultBackTo: '/add-money-disclosure', navbar: false, noRightButtons: true },
  setUpDeposit: { path: '/set-up-deposit', title: '', navbar: false, noRightButtons: true, backNav: true },
  disclosures: { path: '/disclosures', title: 'Disclosures', defaultBackTo: '/menu', isTitleVisible: true, navbar: false, desktop: { navbar: true } },
  moveMoney: {
    path: '/move-money',
    title: 'Move Money',
    isProfileButtonVisible: false,
  },
  mobileCheckCapture: {
    path: '/mobile-check-capture',
    title: '',
    isProfileButtonVisible: false,
    defaultBackTo: '/move-money',
    noRightButtons: true,
    backNav: true,
  },
  doMore: {
    path: '/do-more',
    title: 'Do More',
    isTitleVisible: true,
  },
  addExternalAccounts: {
    path: '/add-external-account',
    // noRightButtons: true,
    navbar: false,
    backNav: true,
  },
  addMoneyDisclosure: {
    path: '/add-money-disclosure',
    title: '',
    noRightButtons: true,
    navbar: false,
    defaultBackTo: '/open-account/my-info/summary',
  },
  sendMoneyPage: {
    path: '/send-money-page',
    title: '',
    noRightButtons: true,
    navbar: false,
    defaultBackTo: '/move-money',
  },
  transfers: {
    path: '/move-money/transfers',
    title: '',
    navbar: false,
    backNav: true,
    defaultBackTo: '/move-money',
    desktop: {
      navbar: true,
    },
  },
  confirmTransfer: {
    path: '/move-money/confirm-transfer',
    title: '',
    noRightButtons: true,
    navbar: false,
    backNav: true,
    defaultBackTo: '/move-money/transfers',
    desktop: {
      navbar: true,
    },
  },
  transfersConfirm: {
    path: '/move-money/transfers/confirm',
    title: '',
    defaultBackTo: '/move-money/transfers',
  },
  internationalTransfer: {
    path: '/move-money/international-transfer',
    title: '',
    defaultBackTo: '/move-money',
    isRoundedDesktopHeader: true,
    layoutClassName: 'move-money_international-transfer',
    navbar: false,
  },
  countryRequestPage: {
    path: '/move-money/international-transfer/country-request-page',
    title: '',
    defaultBackTo: '/move-money/international-transfer',
    isRoundedDesktopHeader: true,
    layoutClassName: 'country-request-page',
    navbar: false,
    backNav: true,
  },
  manageCards: {
    path: '/move-money/international-transfer/manage-cards',
    title: '',
    defaultBackTo: '/move-money/international-transfer',
    isRoundedDesktopHeader: true,
    navbar: false,
    backNav: true,
  },
  addDebitCard: {
    path: '/add-debit-card',
    navbar: false,
    backNav: true,
  },
  addNewBankAccount: {
    path: '/add-new-bank-account',
    navbar: false,
    backNav: true,
    desktop: {
      navbar: true,
    },
  },
  truliooTransaction: {
    path: '/trulioo-transaction',
    title: 'Trulioo transaction',
  },
  addNeedsGoalsAccount: {
    path: '/add-account/:type',
    navbar: false,
    backNav: true,
  },
  addNeedsGoalsAccountSelect: {
    path: '/add-account/:type/select',
    navbar: false,
  },
  acceptInvite: {
    path: '/accept-invite/:inviteId',
    title: '',
    navbar: false,
    noRightButtons: true,
    headerType: 'logo',
  },
  acceptInviteProspect: {
    path: '/accept-invite-prospect/:inviteId',
    title: '',
  },
  playToWinPromo: {
    path: '/promo/play2024',
    title: '',
  },
  successAddNeedsGoalsAccount: {
    path: '/add-account/:type/success',
    title: 'Home',
    defaultBackTo: '/home',
    backNav: true,
  },
  securityPrivacy: {
    path: '/security_privacy',
    title: '',
    defaultBackTo: '/menu',
    navbar: false,
  },
  accountInformation: {
    path: '/account_information/:type?',
    title: 'Menu',
    navbar: false,
    backNav: true,
    isTitleVisible: false,
    defaultBackTo: '/menu',
    desktop: {
      navbar: true,
    },
  },
  selectedAccountInformation: {
    path: '/account_information/:sourceType/:accountId',
    title: 'Selected Account Information',
    navbar: false,
    backNav: true,
    isTitleVisible: false,
    desktop: {
      navbar: true,
    },
  },
  statementsAndDocuments: {
    path: '/statements',
    title: 'Menu',
    defaultBackTo: '/menu',
    isTitleVisible: false,
    navbar: false,
    desktop: {
      navbar: true,
    },
  },
  statementsIframe: {
    path: '/statements-iframe',
    title: 'Menu',
    defaultBackTo: '/menu',
    isTitleVisible: false,
    navbar: false,
  },
  pennyJar: {
    path: '/penny-jar',
    backNav: true,
    title: '',
    navbar: false,
    isTitleVisible: false,
    desktop: {
      topBgCurves: true,
    },
  },
  accountPennyJar: {
    path: '/account_information/percapita/:accountId?/penny-jar',
    title: '',
    backNav: true,
    isTitleVisible: false,
    navbar: false,
  },
  cashCushion: {
    path: '/cash-cushion',
    title: '',
    isTitleVisible: false,
    navbar: false,
    backNav: true,
    desktop: {
      topBgCurves: true,
    },
  },
  cashCushionActivate: {
    path: '/cash-cushion/activate',
    title: '',
    isTitleVisible: false,
    navbar: false,
    backNav: true,
  },
  uploadDocument: {
    path: '/open-account/upload-document',
    title: 'Cash Account Opening',
    navbar: false,
    noRightButtons: true,
  },
  atmLocations: {
    path: '/atm-locations',
    title: 'ATM Locations',
    defaultBackTo: '/move-money',
    navbar: false,
    isTitleVisible: false,
    desktop: {
      navbar: true,
    },
  },
  notificationsCenter: {
    path: '/notifications-center',
    title: '',
    defaultBackTo: HOME_PATH,
    navbar: false,
  },
  notificationsCenterItem: {
    path: '/notifications-center/:id',
    title: 'Notifications Center',
    defaultBackTo: '/notifications-center',
    navbar: false,
    backNav: true,
  },
  forgotDataUsernameSent: {
    path: '/username-sent',
    title: 'Username has been sent',
  },
  forgotDataInformationSent: {
    path: '/information-sent',
    title: 'Information has been sent',
  },
  accountInformationVerify: {
    path: '/account/verify',
    desktop: {
      navbar: false,
    },
    navbar: false,
  },
  changeAddress: {
    path: '/change-address',
    title: '',
    defaultBackTo: '/Profile',
    navbar: false,
  },
  uploadDocuments: {
    path: '/change-address/upload-documents',
    title: '',
    defaultBackTo: '/change-address',
    navbar: false,
  },
  profileSettings: {
    path: '/profile-settings',
    title: '',
    navbar: false,
    backNav: true,
  },
  accountCashCushion: {
    path: '/account_information/percapita/:accountId?/cash-cushion',
    title: '',
    defaultBackTo: '/account_information/selected',
    navbar: false,
  },
  addJointAccount: {
    path: '/add-joint-account',
    title: '',
    navbar: false,
    backNav: true,
  },
  sessionLocked: {
    path: '/session-locked',
    title: '',
    defaultBackTo: '/session-locked',
    noBottomNav: true,
  },
  FAQRoute: {
    path: ':category?/:answer?',
    defaultBackTo: '/move-money',
  },
  userVerificationIFrame: {
    path: '/user-verification',
    title: '',
    defaultBackTo: '/open-account/my-info/summary',
    isTitleVisible: false,
    navbar: false,
  },
};
