import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectDesktopNavigationBarData, selectLeftSubmenuActiveIndex, selectNavigationBarData } from 'store/ui.slice';
import { useGetMenuDataQuery } from 'store/api';
import { Loader } from 'components/general/Loader/Loader';
import { useAppDispatch } from 'utils/hooks/store';
import { setPreviousTab } from 'store/user/help.slice';
import clsx from 'clsx';
import { IMenuItemData } from 'vars/types/menu.types';
import { useLocationTopLevelParent } from 'utils/hooks/useLocationTopLevelParent';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { ROUTES } from 'vars/const/ROUTES';
import { getGroupsPaths } from 'utils/helpers/pathes';
import { useCardHub } from 'utils/hooks/useCardHub';
import { SCREEN_SIZE } from 'utils/helpers/styleHelpers';
import { CardHubModal } from 'components/general/Modals/CardHubModal/CardHubModal';
import { SNavigation, SIcon } from './NavigationBar.styles';
import { NavigationItemDesktop } from './NavigationItemDesktop';

export interface INavigationBarProps {
  isShadowVisible: boolean;
}

export const NavigationBar = ({ isShadowVisible }: INavigationBarProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { currentWindowSize } = useDeviceDimension();
  const isDesktopSize = currentWindowSize >= 577;
  const { locationTopLevelParent, location } = useLocationTopLevelParent();
  const getMenuDataQuery = useGetMenuDataQuery();
  const navigationBarMenu = useSelector(selectNavigationBarData);
  const desktopNavigationBarData = useSelector(selectDesktopNavigationBarData);
  const activeSubmenuIndex = useSelector(selectLeftSubmenuActiveIndex);
  const isLoading = getMenuDataQuery.isLoading || getMenuDataQuery.isFetching;
  const pathsWithHomeIcon = [ROUTES.starter.path];
  const cardHub = useCardHub();

  const handleItemClick = (item: IMenuItemData) => {
    if (!isDesktopSize) {
      if (item.path === 'wellness') {
        dispatch(setPreviousTab('goals-and-tools'));
      }
    } else if (!item.children || !item.children.length) {
      navigate(item.path);
    }
  };

  const isActive = (item: IMenuItemData) => {
    const itemPathList = getGroupsPaths(item);

    if (!isDesktopSize) {
      return (
        (locationTopLevelParent === item.path && item.path !== 'menu') ||
        (pathsWithHomeIcon.includes(location.pathname) && item.path === 'home') ||
        (item.path === 'menu' && locationTopLevelParent === 'menu')
      );
    }

    return locationTopLevelParent === item.path || (pathsWithHomeIcon.includes(location.pathname) && itemPathList.includes('home')) || itemPathList.includes(location.pathname.slice(1));
  };

  return currentWindowSize > SCREEN_SIZE.tablet ? (
    <SNavigation className="desktop-left-nav">
      {desktopNavigationBarData?.map((desktopItem: IMenuItemData, index: number) => (
        <NavigationItemDesktop
          itemIndex={index}
          activeSubmenuIndex={activeSubmenuIndex}
          key={`${desktopItem.path}-${desktopItem.index}`}
          item={desktopItem}
          isActive={isActive}
          isGroup={Boolean(desktopItem?.children?.length)}
          onOpenCardHub={cardHub.open}
        />
      ))}
      <CardHubModal open={cardHub.isActive} onClose={cardHub.close} />
    </SNavigation>
  ) : (
    <SNavigation className={clsx('main-nav-left', isShadowVisible && 'shadow')}>
      {isLoading && <Loader size="small" />}
      {navigationBarMenu?.map((item) => (
        <Link key={item.text} to={item.path === 'menu' && isDesktopSize ? '#' : item.path} onClick={() => handleItemClick(item)} className={clsx('nav-item', isActive(item) && 'active')}>
          {/* The decision to receive icon names from a backend API does not really make a lot of sense, so it should */}
          <SIcon name={item.icon} color={isActive(item) ? 'blue' : 'charcoal70'} cursorPointer />
          <span className="nav-item-title">{item.text}</span>
        </Link>
      ))}
    </SNavigation>
  );
};
