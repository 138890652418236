import React from 'react';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { Button } from 'components/theme/Button/Button';
import { useTranslation } from 'react-i18next';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { SArrowRight } from 'views/PercapitaPay/EmailVerification/EmailVerificationPage.styles';

interface ISubmitButtom {
  handleOnFinish: () => Promise<void>;
  isButtonDisabled: boolean;
}

export const SubmitButtom: React.FC<ISubmitButtom> = ({ handleOnFinish, isButtonDisabled }) => {
  const { t } = useTranslation('percapitaPay');
  const { isDesktopSize } = useDeviceDimension();
  return (
    <CustomRow marginTop={30} justifyContent={isDesktopSize ? 'center' : 'space-between'}>
      <Button onClick={handleOnFinish} disabled={isButtonDisabled} preset="blue-filled" width={isDesktopSize ? '70%' : '100%'}>
        {t('percapitaPayEmailVerification.Send verification code')} {!isDesktopSize && <SArrowRight color={isButtonDisabled ? 'charcoal40' : 'white'} />}
      </Button>
    </CustomRow>
  );
};
