import styled from 'styled-components';
import { getColor } from 'utils/helpers/styleHelpers';

export const SCardContainer = styled.div`
  display: flex;
  gap: 12px;
  cursor: pointer;

  height: 104px;
  align-items: center;
  border-radius: 16px;
  padding: 24px;
  justify-content: space-between;
  background: #ebedfc;
  margin: 32px 0;
  flex-wrap: wrap;

  background: ${getColor('transparent')};
  border: 2px solid ${getColor('creamS5')};

  &.selected {
    background: ${getColor('blue10')};
    border: none;
  }

  img {
    width: 44px;
    height: 28px;
  }

  .card-holder {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 12px;
  }

  #vgs-collect-form {
    width: 135px;
    height: 56px;

    iframe {
      width: 135px;
    }
  }
`;
