import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useToggle } from 'utils/hooks/useToggle';
import { ROUTES } from 'vars/const/ROUTES';
import { Form } from 'antd';
import { Loader } from 'components/general/Loader/Loader';
import { useTranslation } from 'react-i18next';
import { useUSPSValidateAddressMutation } from 'store/api';
import { MyInfoHomeAddressModal } from 'components/general/Modals/MyInfoHomeAddressModal/MyInfoHomeAddressModal';
import { Button } from 'components/theme/Button/Button';
import { BodyText, Title } from 'components/general/Typography';
import { useCashAccountOpening } from 'utils/hooks/useCashAccountOpening';
import { IHomeAddressForm, IMyInfoEditFromVerify } from 'views/OpenCashAccount/MyInfo/MyInfo.type';
import { handleUSPSAddressCheckResult } from 'views/OpenCashAccount/MyInfo/MyInfo.helpers';
import { Header } from 'views/OpenCashAccount/MyInfo/Header/Header';
import { Footer } from 'views/OpenCashAccount/MyInfo/Footer/Footer';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { WebHeader } from 'views/OpenCashAccount/MyInfo/Header/WebHeader/WebHeader';
import { useEditUserProfileDataMutation } from 'store/user/users.api';
import { AddressForm } from './AddressForm/AddressForm';
import { SLayout } from './AddressPage.styles';

export const AddressPage = () => {
  const navigate = useNavigate();
  const [isCheckboxAgreed, setIsCheckboxAgreed] = useState(false);
  const location = useLocation() as IMyInfoEditFromVerify;
  const [form] = Form.useForm();
  const { t } = useTranslation('cashAccountOpening');
  const isEditing = location?.state?.isEditing;
  const nextPage = isEditing ? ROUTES.myInfoSummary.path : ROUTES.myInfoEmailUsername.path;
  const { getOnboardingDataIsLoading, saveOnboardingData, openingAccountData } = useCashAccountOpening();
  const [editUserProfileData] = useEditUserProfileDataMutation();
  const [checkAddress, checkAddressResult] = useUSPSValidateAddressMutation();
  const [isContinueActive, setIsContinueActive] = useState(false);
  const addressCheckModal = useToggle();
  const handledAddressCheckResult = handleUSPSAddressCheckResult(checkAddressResult.data);
  const isLoading = checkAddressResult?.isLoading || getOnboardingDataIsLoading;
  const { isDesktopSize } = useDeviceDimension();

  const [isAddressConfirm, setIsAddressConfirm] = useState(false);

  const handleSubmit = async (values: IHomeAddressForm) => {
    form
      .validateFields()
      .then(() => {
        setIsContinueActive(true);
      })
      .catch(() => {
        setIsContinueActive(false);
      });

    await checkAddress?.({
      address: `${values?.address}`,
      address2: values?.address2,
      zip: values?.zipCode,
      city: values?.city,
      state: values?.stateProvince,
    });
  };

  const handleChangeAgree = () => {
    if (!isCheckboxAgreed) {
      form.setFieldValue('mailingAddress1', '');
      form.setFieldValue('mailingAddress2', '');
      form.setFieldValue('mailingCity', '');
      form.setFieldValue('mailingState', '');
      form.setFieldValue('mailingPostalCode', '');
    }
    setIsCheckboxAgreed((preVal) => !preVal);
  };

  const handleKeepMineClick = () => {
    addressCheckModal.hide();
  };

  const handleModalTakeSuggested = () => {
    const formValues = form.getFieldsValue();

    const updatedAddress = {
      ...formValues,
      ...handledAddressCheckResult?.suggested,
      isMailingAddressTheSame: !formValues.mailingCheckbox,
    };

    if (updatedAddress.isMailingAddressTheSame) {
      updatedAddress.mailingAddress1 = updatedAddress.address;
      updatedAddress.mailingAddress2 = updatedAddress.address2;
      updatedAddress.mailingCity = updatedAddress.city;
      updatedAddress.mailingState = updatedAddress.stateProvince;
      updatedAddress.mailingPostalCode = updatedAddress.zipCode;
    }

    setIsAddressConfirm(true);
    saveOnboardingData(updatedAddress);
    addressCheckModal.hide();
    navigate(nextPage);
  };

  useEffect(() => {
    const formValues = form.getFieldsValue();

    if (handledAddressCheckResult?.isError && !isAddressConfirm) {
      addressCheckModal.show();
    }
    if (handledAddressCheckResult?.isSuccess) {
      let addressData = { ...formValues };
      if (!formValues.mailingCheckbox) {
        addressData = {
          ...formValues,
          mailingAddress1: formValues.address,
          mailingAddress2: formValues.address2,
          mailingCity: formValues.city,
          mailingState: formValues.stateProvince,
          mailingPostalCode: formValues.zipCode,
        };
      }
      saveOnboardingData({
        ...addressData,
        isMailingAddressTheSame: !formValues.mailingCheckbox,
        currentUrl: nextPage,
      });

      editUserProfileData({
        profileData: {
          ...addressData,
          address1: formValues.address,
          address2: formValues.address2,
          city: formValues.city,
          stateProvince: formValues.stateProvince,
          postalCode: formValues.zipCode,
          isMailingAddressTheSame: !formValues.mailingCheckbox,
        },
      });

      setIsAddressConfirm(false);
      navigate(nextPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handledAddressCheckResult?.isSuccess, handledAddressCheckResult?.isError]);

  useEffect(() => {
    if (openingAccountData.isMailingAddressTheSame !== undefined) {
      setIsCheckboxAgreed(!openingAccountData.isMailingAddressTheSame);
    } else {
      setIsCheckboxAgreed(false);
    }
  }, []);

  return (
    <>
      {isEditing ? (
        <WebHeader showExit={false} title={t('accountOpening.GoBackToAccountInformation')} />
      ) : (
        <Header title={t('step.WhereDoYouLive')} stage="Address" webStage="Address" marginTop={5} marginBottom={28} />
      )}

      <SLayout>
        {isLoading && <Loader />}
        <div className="content-container">
          {isEditing ? (
            <div className="edit-title-container">
              <Title textAlign="start" justifyContent="start" fontWeight="SB" size={isDesktopSize ? 'S' : 'M'} marginBottom={4}>
                {t('myInfo.HomeAddress')}
              </Title>
              <BodyText textType="bodyText" justifyContent="start" textAlign="start" color="charcoal60" size="N" fontWeight="R">
                {t('myInfo.ChangeYourHomeAddressHere')}
              </BodyText>
            </div>
          ) : (
            <>
              <div className="mobile-title-container">
                <BodyText textType="bodyText" color="charcoal60" size="N" fontWeight="R" paddingTop={20} paddingRight={5} marginBottom={32} lineHeight={1.3}>
                  {t('myInfo.ProvideHomeAddress')}
                </BodyText>
              </div>
              <div className="web-title-container">
                <Title marginBottom={8}>{t('step.WhereDoYouLive')}</Title>
                <BodyText textType="bodyText" color="charcoal60" size="N" fontWeight="R" marginBottom={16}>
                  {t('myInfo.ProvideHomeAddress')}
                </BodyText>
              </div>
            </>
          )}

          <AddressForm handleSubmit={handleSubmit} onCompletion={setIsContinueActive} form={form} isCheckboxAgreed={isCheckboxAgreed} handleChangeAgree={handleChangeAgree} isEditMode={isEditing} />
        </div>

        <Footer isDisabled={!isContinueActive} onClick={form.submit} secondBtnText={isEditing ? t('myInfo.SaveChanges') : ''} />

        <div className="mobile-footer">
          <Button size="large" disabled={!isContinueActive} onClick={form.submit} marginBottom={24} preset="blue-filled">
            {t(isEditing ? 'myInfo.SaveChanges' : 'myInfo.Continue')}
          </Button>

          {!isEditing && (
            <BodyText textType="bodyText" fontWeight="M" size="T" color="charcoal70" textAlign="end" marginBottom={16} className="next-step" justifyContent="end">
              {t('accountOpening.NextStep')} {t('step.Email')}
            </BodyText>
          )}
        </div>

        <MyInfoHomeAddressModal
          isModalVisible={addressCheckModal.isActive}
          onClose={addressCheckModal.hide}
          errorType={handledAddressCheckResult?.errorType}
          errors={handledAddressCheckResult?.errorMessages}
          onConfirmClick={handleModalTakeSuggested}
          onEditClick={handleKeepMineClick}
          suggested={handledAddressCheckResult?.suggested}
          addressForm={form}
        />
      </SLayout>
    </>
  );
};
