import styled, { css } from 'styled-components/macro';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';

export const SCustomRow = styled(CustomRow)`
  overflow-y: hidden;

  ${mediaFrom(
    577,
    css`
      display: none;
    `
  )}

  ${mediaUpTo(
    'mobile',
    css`
      .custom-title-text {
        font-size: 20px;
      }

      .svg-icon {
        width: 20px;
        height: 20px;
      }
    `
  )}
`;
