import React, { useState } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { ROUTES } from 'vars/const/ROUTES';
import { useEditUserProfileDataMutation, useLazyGetCurrentUserQuery } from 'store/user/users.api';
import { BodyText, Title } from 'components/general/Typography';
import { useCashAccountOpening } from 'utils/hooks/useCashAccountOpening';
import { filterEmptyStrings } from 'utils/helpers/object';
import dottesInAngle from 'assets/images/dottesInAngle.svg';
import { INameForm, IMyInfoEditFromVerify } from 'views/OpenCashAccount/MyInfo/MyInfo.type';
import { Header } from 'views/OpenCashAccount/MyInfo/Header/Header';
import { Loader } from 'components/general/Loader/Loader';
import { Button } from 'components/theme/Button/Button';
import { Footer } from 'views/OpenCashAccount/MyInfo/Footer/Footer';
import { NameForm } from './NameForm/NameForm';
import { EditNamePage } from './EditNamePage/EditNamePage';
import { SLayout } from './NamePage.styles';

export const NamePage = () => {
  const navigate = useNavigate();
  const location = useLocation() as IMyInfoEditFromVerify;
  const isEditing = location?.state?.isEditing;
  const [editUserProfileData, editUserProfileDataResult] = useEditUserProfileDataMutation();
  const [getCurrentUser] = useLazyGetCurrentUserQuery();
  const { saveOnboardingData, openingAccountData, getOnboardingDataIsLoading } = useCashAccountOpening();
  const [form] = Form.useForm();
  const [isContinueActive, setIsContinueActive] = useState(false);

  const { t } = useTranslation('cashAccountOpening');

  const handleSubmit = async (values: INameForm) => {
    const nextUrl = isEditing ? ROUTES.myInfoSummary.path : ROUTES.myInfoHomeAddress.path;
    await editUserProfileData({ profileData: filterEmptyStrings(values) }).unwrap();
    saveOnboardingData?.({ ...values, currentUrl: nextUrl });
    getCurrentUser();
    navigate(nextUrl);
  };

  if (isEditing) {
    return <EditNamePage handleSubmit={handleSubmit} />;
  }

  return (
    <>
      <Header title={t('myInfo.WhatsYourName')} stage="Name" webStage="Name" marginTop={16} />
      <SLayout bgFooterImage={dottesInAngle}>
        <div className="content-container">
          <div className="web-title-container">
            <Title>{t('myInfo.WhatsYourName')}</Title>
          </div>

          {getOnboardingDataIsLoading || editUserProfileDataResult.isLoading ? (
            <Loader />
          ) : (
            <div className="form-card">
              <NameForm handleSubmit={handleSubmit} form={form} onCompletion={setIsContinueActive} openingAccountData={openingAccountData} />
            </div>
          )}
        </div>

        <Footer isDisabled={!isContinueActive} onClick={form.submit} />

        <div className="mobile-footer">
          <Button size="large" preset="blue-filled" disabled={!isContinueActive} onClick={form.submit} marginBottom={16}>
            {t('myInfo.Continue')}
          </Button>
          <BodyText textType="bodyText" fontWeight="M" size="T" color="charcoal70" textAlign="end" className="next-step" marginBottom={16} justifyContent="end">
            {t('accountOpening.NextStep')} {t('step.Address')}
          </BodyText>
          <BodyText textType="helperText" fontWeight="R" color="charcoal50" textAlign="start" size="N" marginTop={24} lineHeight={1.3} paddingRight={10} paddingLeft={10}>
            {t('myInfo.IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT')}
          </BodyText>
        </div>

        <div className="web-disclosure">
          <BodyText className="disclosure" textType="helperText" fontWeight="R" color="charcoal50" textAlign="start" size="N">
            {t('myInfo.IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT')}
          </BodyText>
        </div>
      </SLayout>
    </>
  );
};
