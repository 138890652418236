import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { generatePath, Outlet, useLocation } from 'react-router-dom';
import { NavigationBar } from 'components/navigation/NavigationBar/NavigationBar';
import { useAppDispatch } from 'utils/hooks/store';
import { setHeaderTitle, selectNotificationCapabilityModal, selectDisplayOverlayPad } from 'store/ui.slice';
import { MobileHeader } from 'components/navigation/MobilePageHeader/MobileHeader';
import { useRouteConfig } from 'utils/hooks/useRouteConfig';
import { SortBySheet } from 'components/general/BottomDrawers/SortBySheet/SortBySheet';
import { DestinationAccountSheet } from 'components/general/BottomDrawers/DestinationAccountSheet/DestinationAccountSheet';
import { DateRangeSheet } from 'components/general/BottomDrawers/DateRangeSheet/DateRangeSheet';
import { HistoryFilterSheet } from 'components/general/BottomDrawers/HistoryFilterSheet/HistoryFilterSheet';
import { AddStuffSaveAccountModal } from 'components/general/Modals/AddStuffSaveAccountModal/AddStuffSaveAccountModal';
import { AdditionalInformationModal } from 'components/general/Modals/AdditionalInformationHandler/AdditionalInformationHandler';
import { PercapitaPlaysInfoModal } from 'components/general/Modals/PercapitaPlaysInfoModal/PercapitaPlaysInfoModal';
import { NotificationCapabilityModal } from 'components/general/Modals/NotificationCapabilityModal/NotificationCapabilityModal';
import { SubscriptionsModal } from 'components/general/Modals/SubscriptionsModal/SubscriptionsModal';
import { PercapitaPayModal } from 'components/general/Modals/PercapitaPayModal/PercapitaPayModal';
import { EnrollModal } from 'components/general/Modals/EnrollModal/EnrollModal';
import { CashOpeningExitModal } from 'components/general/Modals/CashOpeningExitModal/CashOpeningExitModal';
import { ChatSheet } from 'components/general/BottomDrawers/ChatSheet/ChatSheet';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { ComingSoonModal } from 'views/MoveMoney/MoveMoneyPage/ComingSoonModal';
import { ROUTES } from 'vars/const/ROUTES';
import { useGetNotificationsQuery } from 'store/user/users.api';
import { useSelector } from 'react-redux';
import { NeedSupportModal } from 'components/general/Modals/NeedSupportModal/NeedSupportModal';
import { MobileCheckComingSoonModal } from 'views/MoveMoney/MoveMoneyPage/MobileCheckComingSoonModal/MobileCheckComingSoonModal';
import { SCREEN_SIZE } from 'utils/helpers/styleHelpers';
import { DesktopHeader } from './DesktopHeader';
import { LogoHeader } from './LogoHeader/LogoHeader';
import { SContentWrapper, SLayout, SLayoutContent } from './MainLayout.styles';

export interface IMainLayout {
  headerTitle?: string;
}

export const MainLayout = ({ headerTitle = '' }: IMainLayout) => {
  const { currentWindowSize } = useDeviceDimension();
  const isDesktopSize = currentWindowSize >= 577;
  const wrapperRef = useRef(null);
  const { navbar, headerType, desktop, layoutClassName: routeLayoutClassName } = useRouteConfig();
  const isNavbarVisible = navbar || (desktop?.navbar && isDesktopSize);
  const [isShadowVisible, setIsShadowVisible] = useState(true);
  const dispatch = useAppDispatch();
  const displayOverlayPad = useSelector(selectDisplayOverlayPad);
  const { pathname } = useLocation();
  const isNotificationCardVisible = useSelector(selectNotificationCapabilityModal);
  const MobileHeaderComponent = headerType === 'logo' ? LogoHeader : MobileHeader;

  const layoutClassName = clsx('main-layout', routeLayoutClassName, {
    desktop: currentWindowSize > SCREEN_SIZE.tablet,
  });

  const layoutContentClassName = clsx('layout-content', `${routeLayoutClassName}-content`, {
    'layout-content_bg-white': pathname === ROUTES.attune.path,
    'menu-page': pathname.toLowerCase() === ROUTES.mainMenu.path,
    'profile-page': pathname.toLowerCase() === ROUTES.profile.path,
    'do-more-page': pathname.toLowerCase() === ROUTES.doMore.path,
    'international-transfer-page': pathname.toLowerCase() === ROUTES.internationalTransfer.path,
    'add-account-needs-page': pathname.toLowerCase() === generatePath(ROUTES.addNeedsGoalsAccount.path, { type: 'needs' }),
    'add-account-goals-page': pathname.toLowerCase() === generatePath(ROUTES.addNeedsGoalsAccount.path, { type: 'goals' }),
    'top-bg-curves': desktop?.topBgCurves,
    'has-bottom-nav': navbar,
  });

  const onScroll = () => {
    if (wrapperRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = wrapperRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        setIsShadowVisible(false);
      } else {
        setIsShadowVisible(true);
      }
    }
  };

  useEffect(() => {
    dispatch(setHeaderTitle(''));
  }, []);

  useGetNotificationsQuery(undefined, { refetchOnMountOrArgChange: 300, pollingInterval: 300000 });

  /*
   * Do not merge Header and PageHeader into one component, they are distinct for a reason.
   * Header is _the_ header of the application, PageHeader is _a_ header of a specific page.
   * Ideally, the document structure should be as follows:
   * <body>
   *   // The header of the application
   *   <header><h1>Percapita</h1></header>
   *   <main>
   *     // Page header
   *     <header><h2>Home</h2></header>
   *   </main>
   * </body>
   */
  return (
    <SLayout className={layoutClassName}>
      {currentWindowSize > SCREEN_SIZE.tablet ? <DesktopHeader /> : <MobileHeaderComponent headerTitle={headerTitle} />}
      <SLayoutContent id="container" className={layoutContentClassName}>
        {isNavbarVisible && <NavigationBar isShadowVisible={isShadowVisible && !isNotificationCardVisible} />}

        <SContentWrapper className="main-layout__content-wrapper" onScroll={onScroll} ref={wrapperRef} id="scrollableDiv">
          <Outlet />
        </SContentWrapper>

        {/* --- Modals block --- */}
        <CashOpeningExitModal />
        <EnrollModal />
        <AddStuffSaveAccountModal />
        <AdditionalInformationModal />
        <SubscriptionsModal />
        <PercapitaPayModal />
        <PercapitaPlaysInfoModal />
        <ComingSoonModal />
        <MobileCheckComingSoonModal />
        <NeedSupportModal />
        {isNotificationCardVisible && <NotificationCapabilityModal isNavVisible={navbar} />}

        {/* --- Sheets block --- */}
        <SortBySheet />
        <DestinationAccountSheet />
        <DateRangeSheet />
        <HistoryFilterSheet />
        <ChatSheet />
        <div className={clsx('overlay-pad', displayOverlayPad && isDesktopSize && 'active')} />
      </SLayoutContent>
    </SLayout>
  );
};
