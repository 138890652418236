import { IATMLocationsRequest } from 'store/user/atmLocations/atmLocations.types';
import { TGroupName } from 'store/video/video.types';

export const API_ROUTES = {
  auth: {
    zendeskToken: '/auth/zendesk-token',
    estatementIframeUrl: '/auth/estatement/iframe-url',
    login: '/auth/login',
    logout: '/auth/logout',
    refreshToken: '/auth/refresh',
    forgotPassword: {
      init: '/auth/forgot-password',
      generateCode: '/auth/forgot-password/generate-code',
      checkCode: '/auth/forgot-password/check-code',
      finish: '/auth/forgot-password/finish',
    },
    forgotUsername: {
      init: '/auth/forgot-user',
    },
    validateEmail: {
      generateCode: '/auth/validate-email/generate-code',
      generateCodeOnly: '/auth/validate-email/generate-code_code_only',
      checkCode: '/auth/validate-email/check-code',
    },
    register: {
      generateCode: '/auth/register/generate-code',
      checkCode: '/auth/register/check-code',
      finish: '/auth/register/finish',
    },
    otpSeed: {
      requestNew: '/auth/otp-seed/request-new',
      generateCode: '/auth/otp-seed/generate-code',
      finish: '/auth/otp-seed/finish',
    },
    truliooToken: '/auth/trulioo-token',
    validateEmailLink: '/auth/validate-email-link',
  },
  trulioo: {
    embedIds: (truliooAccessToken: string, id: string) => `/trulioo/${truliooAccessToken}/trulioo-api/embedids/tokens/${id}`,
    saveTransaction: (truliooAccessToken: string, experienceTransactionId: string) => `/trulioo/${truliooAccessToken}/save_transaction/${experienceTransactionId}`,
    transactionsCheck: (experienceTransactionId: string) => `/trulioo/transactions/${experienceTransactionId}/check`,
    transactionsLatest: '/trulioo/transactions/latest',
    validateUserInfo: '/trulioo/verify',
    docv2: {
      getShortCode: '/trulioo/short-code',
      saveTransaction: (transactionId: string) => `/trulioo/docv2/save-transaction/${transactionId}`,
    },
  },
  user: {
    user: '/user',
    map: '/user/map',
    addCard: 'user/international-remittance/addCard',
    removeCard: 'user/international-remittance/removeCard',
    allAlerts: '/user/all-alerts',
    create_referral: '/user/create_referral',
    getCurrent: '/user/client',
    directDeposit: '/user/direct-deposit',
    getIsEmailVerified: '/user/is-email-verified',
    validateEmailLink: '/auth/validate-email-link',
    checkRightTimeNotification: '/user/checkrighttimenotification',
    changePassword: '/user/change-password',
    thirdPartyIds: '/user/third-party-ids',
    thirdPartyAttuneNew: '/user/third-party/attune/new',
    validateTaxId: '/user/validate-last-4',
    transactionLimits: '/user/system-properties/transactionLimits',
    alternativeAddresses: '/user/alternative-address',
    employers: '/user/employers',
    setRightTimeNotificationsAcitivity: (rightTimeNotificationHistoryId: string) => `/user/set-right-time-notifications-acitivity/${rightTimeNotificationHistoryId}`,
    createDownTimeUpdate: (productName: string) => `/user/create-downtime-update?productName=${productName}`,
    consents: {
      consents: '/user/consents',
      consentStatusById: (disclaimerId: string) => `/user/consents/${disclaimerId}/status`,
      consentByFlowName: (flowName: string) => `/user/consents/${flowName}`,
      consentById: (consentId: string) => `/user/consents/${consentId}`,
      enrollConsents: '/user/consents/EWAEnroll',
      percapitaPlayDisclosure: '/user/consents/PercapitaPlay',
      sendByEmail: (consentId: string) => `/user/consents/${consentId}/email`,
    },
    properties: {
      allProperties: '/user/properties',
      property: (propertyName: string) => `/user/properties/${propertyName}`,
      systemProperty: (propertyName: string) => `/user/system-properties/${propertyName}`,
      UIPreference: '/user/properties/UIPreference',
      onboardingPreferences: '/user/properties/OnboardingPreferences',
      percapitaPay: '/user/properties/percapitaPay',
      language: '/user/properties/language',
    },
    attune: {
      quizResult: (attuneQuizId: string) => `/user/attune-quizes/${attuneQuizId}/result`,
      quizResults: (attuneQuizId: string) => `/user/attune-quizes/${attuneQuizId}/results`,
    },
    changeEmail: {
      generate: '/user/change-email/generate-code',
      finish: '/user/change-email/finish',
    },
    changePhone: {
      generate: '/user/change-phone/generate-code',
      finish: '/user/change-phone/finish',
    },
    accounts: {
      accounts: '/user/accounts',
      thirdParty: '/user/accounts/third-party',
      inviteJointAccountHolder: '/user/accounts/invite-joint-account-holder',
      checkInviteId: (inviteId: string) => `/user/accounts/invite-joint-account-holder/${inviteId}/validate`,
      jointAccountHolderInvites: '/user/accounts/joint-account-holder-invites',
      hideJointAccountHolderInvites: (inviteId: string) => `/user/accounts/invite-joint-account-holder/${inviteId}/hide`,
      acceptJointAccountHolder: '/user/accounts/accept-joint-account-holder',
      ownerJointAccountInvites: '/user/accounts/owner-joint-account-invites',
      addJointAccountHolder: '/user/accounts/add-joint-account-holder',
      thirdPartyValidate: '/user/accounts/third-party/validate',
      transfer: '/user/accounts/transfer',
      accountById: (accountId: string) => `/user/accounts/${accountId}`,
      accountTransactionById: (accountId: string, transactionId: string) => `/user/accounts/${accountId}/transactions/${transactionId}`,
      accountTransactions: (accountId: string, accountType: string, dateFrom: string, dateTo: string) =>
        `/user/accounts/${accountId}/transactions?accountType=${accountType}&dateFrom=${dateFrom}&dateTo=${dateTo}`,
      features: (id: string) => `/user/accounts/${id}/features`,
      holds: (id: string) => `/user/accounts/${id}/holds`,
    },
    documentRequests: '/user/document-requests',
    uploadRequestedDocuments: '/user/upload-requested-documents',
    delete: '/user',
    kyc: '/user/kyc-status',
    atmLocations: (filterData: IATMLocationsRequest) => `/user/atm/latitude/${filterData.latitude}/longitude/${filterData.longitude}`,
    atmByAddress: '/user/atm/address',
    truvToken: '/user/truv',
    checkFree: '/user/check-free',
    notifications: '/user/notifications',
    notificationUnread: (id: string) => `/user/notifications/${id}/unread`,
    markNotificationsRead: '/user/notifications/mark-as-read',
    markNotificationsDeleted: '/user/notifications/mark-as-deleted',
    cancelChangeAddressRequests: '/user/address-change-requests',
    surveys: (id: string) => `/user/surveys/${id}`,
  },
  users: {
    alerts: (id: string) => `/users/${id}/alerts`,
  },
  zogo: {
    allPoints: '/zogo/all-points',
    points: '/zogo/points',
    skills: '/zogo/skills',
    modules: '/zogo/modules',
    token: '/zogo/token',
  },
  chat: {
    intents: '/chat/intents',
    conversations: '/chat/conversations',
    messages: (id: string) => `/chat/conversations/${id}/activities`,
    reconnect: (id: string) => `/chat/conversations/${id}/reconnect`,
    end: (id: string) => `/chat/conversations/${id}/end`,
    intentGroups: '/chat/intent-groups',
    intentGroupById: (groupId: string) => `/chat/intent-groups/${groupId}`,
    intentQuestions: (intentName: string) => `/chat/intents/${intentName}/questions`,
    intentTestingResults: `/chat/intent-testing-results`,
  },
  immediate: {
    consents: '/immediate/consents',
    updateConsentByPolicy: (policyId: string) => `/immediate/consents/${policyId}`,
    enroll: '/immediate/enroll',
    eulaPolicies: '/immediate/policies/Eula',
    timeEntries: '/immediate/time-entries',
    login: '/immediate/login',
    accounts: '/immediate/accounts',
    preparePayment: '/immediate/prepare-payment',
    requestPayment: '/immediate/request-payment',
    walletAccounts: '/immediate/accounts',
    paymentInfo: '/immediate/payment-info',
    payHistory: (rangeType: string) => `/immediate/history?rangeType=${rangeType}`,
  },
  disclaimers: {
    privacyPolicyDisclosure: '/disclaimers/privacy',
    termsOfUseDisclosure: '/disclaimers/terms-of-use',
    sutton: '/disclaimers/sutton',
  },
  test: {
    testChecksum: '/test/test-checksum',
    testChecksumPost: '/test/test-checksum-post',
  },
  ingo: {
    iframeUrl: '/ingo/iframe-url',
    checkAlternativeAddress: '/ingo/check-alternative-address',
    activateAlternativeAddress: '/ingo/activate-alternative-address',
    transferToExternalAccount: '/ingo/transfer-to-external-account',
    transferFromExternalAccount: '/ingo/transfer-from-external-account',
    riskSession: '/ingo/risk-session',
    requestBankName: '/ingo/request-bank-name',
  },
  plaid: {
    getLinkToken: '/plaidauth/link-token',
    submitExchangeToken: '/plaidauth/exchange-token',
  },
  updateUserData: (id: string) => `users/${id}`,
  userInvites: '/user-invites',
  USPSValidateAddress: '/usps/validate',
  history: '/history',
  menu: '/menu-hierarchy/tree',
  faq: '/faq',
  surveys: (id: string) => `/surveys/${id}`,
  video: (groupName: TGroupName) => `/user/videos/${groupName}`,
  userIdentityVerification: `/identity-verification`,
  april: {
    estimation: '/user/april/token/ESTIMATOR',
    optimizer: '/user/april/token/PAYCHECK_OPTIMIZATION',
    filer: '/user/april/token/FILING',
  },
};
