import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { usePlaidLink, PlaidLinkOptions } from 'react-plaid-link';
import { useLocation, useNavigate } from 'react-router-dom';
import { SuttonDisclaimerNote } from 'components/general/DisclaimerNote/SuttonDisclaimerNote';
import { ROUTES } from 'vars/const/ROUTES';
import { api } from 'store/api';
import { useAppDispatch } from 'utils/hooks/store';
import { selectPolicies } from 'store/user/authentication.slice';
import { setShowComingSoonModal } from 'store/ui.slice';
import { useDevLogger } from 'utils/hooks/useDevLogger';
import { BodyText, Title } from 'components/general/Typography';
import { Loader } from 'components/general/Loader/Loader';
import { useAccounts } from 'utils/hooks/useAccounts';
import { lsGetItem } from 'utils/helpers/storage';
import { mobileApiCall } from 'services/mobileService';
import { Page } from 'components/layouts/Page/Page';
import { SAddExternalAccountPage } from './AddExternalAccountsPage.styles';
import { AddAccountItem } from './AddAccountItem/AddAccountItem';

interface IAddExternalAccountPage {
  onAddNewAccountSuccess?: () => void;
  handleCloseModal?: () => void;
}

export const AddExternalAccountPage = ({ onAddNewAccountSuccess, handleCloseModal }: IAddExternalAccountPage) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { log, error } = useDevLogger();
  const addExternalAccountSuccessUrl = location.state?.addExternalAccountSuccessUrl;
  const dispatch = useAppDispatch();
  const { t } = useTranslation('moveMoney');
  const policies = useSelector(selectPolicies);
  const { externalAccounts, isLoading: accountsDataLoading } = useAccounts();
  const [getPlaidToken, getPlaidTokenResult] = api.useLazyGetPlaidLinkTokenQuery();
  const [submitPlaidTokenMutation, submitPlaidTokenMutationResult] = api.useSubmitPlaidExchangeTokenMutation();
  const isLoading = accountsDataLoading || getPlaidTokenResult.isLoading || submitPlaidTokenMutationResult.isLoading;

  const isMobileApp = lsGetItem('isMobileApp');

  const plaidConfig: PlaidLinkOptions = {
    onSuccess: (public_token) => {
      log('Public Token: ', public_token);
      submitPlaidTokenMutation(public_token);
    },
    onExit: (err) => {
      error('Plaid Error: ', err);
    },
    token: getPlaidTokenResult?.data?.linkToken ?? null,
  };

  const plaidLink = usePlaidLink(plaidConfig);

  const openComingSoonModal = (): void => {
    dispatch(setShowComingSoonModal(true));
  };

  const handleAddNewDebitCard = () => {
    handleCloseModal?.();
    if (policies?.ExternalDebitCardTransferEnabled) {
      navigate(ROUTES.addDebitCard.path, {
        state: {
          fundingDestination: 'debit',
        },
      });
    } else {
      openComingSoonModal();
    }
  };

  const handleAddNewBankAccount = () => {
    if (isMobileApp) {
      mobileApiCall('showPlaid', addExternalAccountSuccessUrl);
      return;
    }

    if (policies?.UsePlaidForAddingExternalAccount) {
      getPlaidToken();
    } else if (policies?.ExternalACHTransferEnabled) {
      navigate(ROUTES.addNewBankAccount.path);
    } else {
      openComingSoonModal();
    }
  };

  useEffect(() => {
    if (plaidLink.ready && getPlaidTokenResult.isSuccess) {
      handleCloseModal?.();
      plaidLink.open();
    }
  }, [getPlaidTokenResult.isSuccess, plaidLink.ready]);

  useEffect(() => {
    if (plaidLink.ready && getPlaidTokenResult.isSuccess) {
      handleCloseModal?.();
      plaidLink.open();
    }
  }, [getPlaidTokenResult.isSuccess, plaidLink.exit]);

  useEffect(() => {
    if (submitPlaidTokenMutationResult.isSuccess) {
      if (onAddNewAccountSuccess) {
        onAddNewAccountSuccess();
        return;
      }
      if (addExternalAccountSuccessUrl) {
        navigate(addExternalAccountSuccessUrl);
      }
    }
  }, [submitPlaidTokenMutationResult.isSuccess]);

  useEffect(() => {
    window.onpopstate = () => {
      if (location?.state?.isFromModal) {
        navigate(ROUTES.home.path);
      }
    };
  }, [navigate, location]);

  return (
    <SAddExternalAccountPage>
      {isLoading && <Loader />}
      <Page.Content>
        <div>
          <Title size="S" fontWeight="SB" color="charcoal" marginBottom={8} className="add-account-title">
            {t('addExternalAccountPage.AddExternalAccount')}
          </Title>
          <BodyText textType="bodyText" color="charcoal70" fontWeight="SM" size="M" font="DM Sans" marginBottom={32}>
            {t('addExternalAccountPage.ChooseTypeAccount')}
          </BodyText>
          <AddAccountItem
            title={t('addExternalAccountPage.DebitCard')}
            subtitle={t('addExternalAccountPage.AddDebitCardNumberToTransfer')}
            onClick={handleAddNewDebitCard}
            iconName="creditCard"
            isComingSoon={!policies?.ExternalDebitCardTransferEnabled}
          />
          <AddAccountItem
            title={t('addExternalAccountPage.BankAccount')}
            subtitle={t('addExternalAccountPage.BankAccountNumberViaACH')}
            onClick={handleAddNewBankAccount}
            iconName="bank"
            isComingSoon={!policies?.ExternalACHTransferEnabled}
          />
          <div className="sutton-disclaimer-note">
            <SuttonDisclaimerNote isForExternalTransfer={externalAccounts.length === 0} />
          </div>
        </div>
      </Page.Content>
    </SAddExternalAccountPage>
  );
};
