import styled, { css } from 'styled-components/macro';
import { Layout as LayoutLibrary } from 'antd';
import { getColor, getProp, mediaFrom, mediaUpTo, SCREEN_SIZE } from 'utils/helpers/styleHelpers';
import DotPatternBackground from 'assets/images/dotPatternBackground.svg';

export const SAuthLayout = styled.section<{ altLayout?: string }>`
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;

  &:before {
    content: '';
    width: 23.7rem;
    height: 25.5rem;
    position: absolute;
    top: 0;
    right: 0;
  }

  .auth-layout__header {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 36px 20px 12px;

    ${mediaUpTo(
      'mobile',
      css`
        padding-top: 30px;
      `
    )}

    .percapita-logo {
      width: 143px;
      height: auto;
    }

    &_mobile-simple {
      justify-content: flex-start;
      padding: 40px 28px 12px;
    }

    ${mediaFrom(
      'mobile',
      css`
        padding: 54px 20px 34px;

        .percapita-logo {
          width: 192px;
        }

        &_mobile-simple {
          padding: 76px 20px 2px;
        }
      `
    )};

    ${mediaFrom(
      'tablet',
      css`
        position: relative;
        justify-content: space-between;
        align-items: center;
        padding: 26px 24px;
        margin: 24px;
        background-color: white;
        border-radius: 20px;
        box-shadow: 0 16px 24px 0 #0000000a;

        .percapita-logo {
          width: 120px;
        }
      `
    )};

    ${mediaUpTo(
      'desktop',
      css`
        margin: 0;
        border-radius: unset;
      `
    )}
  }

  ${({ altLayout }) => {
    if (altLayout === 'v2' || altLayout === 'onboarding') {
      return css`
        background: ${getColor('cream70')} url(${DotPatternBackground}) right no-repeat;
      `;
    }

    if (altLayout === 'onboarding-first-steps') {
      return css`
        background-color: ${getColor('cream70')};

        ${mediaUpTo(
          SCREEN_SIZE.desktop - 1,
          css`
            background-image: url(${DotPatternBackground});
            background-position: top right;
            background-repeat: no-repeat;
          `
        )};

        ${mediaFrom(
          'desktop',
          css`
            background-color: ${getColor('white')};

            .auth-layout__header {
              display: none;
            }
            padding: 0;
          `
        )};
      `;
    }

    return css`
      background: ${getColor('blue')};
    `;
  }}
`;

export const SSuttonDisclaimerNoteWrapper = styled.div`
  display: flex;
  padding: 92px 108px;
`;

export const SHeaderLeftBtn = styled.button`
  position: absolute;
  width: 25px;
  height: 25px;
  background: transparent;
  padding: 0;
  align-items: center;
  left: 20px;
  cursor: pointer;
  border: none;
  outline: none;
`;

export const SLayoutInner = styled.div<{ altLayout?: string }>`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  z-index: 1;
  border-radius: 25px 25px 0 0;

  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;

  ${({ altLayout }) => {
    if (altLayout === 'v2') {
      return css`
        background: transparent;
        padding: 34px 20px 0;
      `;
    }

    if (altLayout === 'onboarding-first-steps') {
      return css`
        background: transparent;
        padding: 34px 20px 0;
        overflow-x: hidden;

        .footer-container {
          padding: 0 48px;
        }

        ${mediaFrom(
          'desktop',
          css`
            padding: 0;
            border-radius: 0;
          `
        )}
      `;
    }

    if (altLayout === 'onboarding') {
      return css`
        background: transparent;
        padding: 34px 0;
      `;
    }

    return css`
      background: ${getColor('cream70')};
      padding: 48px 20px 0;

      ${mediaUpTo(
        'tablet',
        css`
          padding-top: 18px;
        `
      )}
    `;
  }}

  ${mediaUpTo(
    'mobile',
    css`
      padding-inline: 3px;
      padding-top: 14px;
      border-radius: 17px 17px 0 0;
    `
  )}
`;

export const SLayoutContent = styled(LayoutLibrary.Content)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1 0 auto;
`;

export const SExtendedLayout = styled.div`
  background: ${getColor('creamSS2')};
  margin-top: -130px;
  height: 200px;
`;

export const SDesktopWrapper = styled.div`
  ${mediaFrom(
    'tablet',
    css`
      justify-content: center;
      display: flex;
      flex: 1 0 auto;
    `
  )}
`;

export const SDesktopBlock = styled.div<{ tabletWidth?: string }>`
  @media screen and (min-width: ${SCREEN_SIZE.tablet}px) {
    width: ${getProp('tabletWidth', '100%')};
  }
`;
