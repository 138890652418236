import React from 'react';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import { SBodyStyle, SMaskStyle } from 'components/theme/CustomModal/CustomModal.styles';
import { SModalCloseButton, SStatusImage } from 'views/PercapitaPay/MainPage/PercapitaPayMainPage.styles';
import { Button } from 'components/theme/Button/Button';
import { images } from 'assets';
import { Title } from 'components/general/Typography';
import { useTranslation } from 'react-i18next';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';

interface IPaymentErrorModalProps {
  paymentSucceed: boolean;
  handleCloseStatusModal: () => void;
}

export const PaymentSuccessModal: React.FC<IPaymentErrorModalProps> = ({ paymentSucceed, handleCloseStatusModal }) => {
  const { t } = useTranslation('percapitaPay');
  const { isDesktopSize } = useDeviceDimension();

  return (
    <CustomModal
      open={paymentSucceed}
      centered
      onCancel={handleCloseStatusModal}
      destroyOnClose
      footer={null}
      maskStyle={SMaskStyle}
      bodyStyle={SBodyStyle}
      closeIcon={<SModalCloseButton cursorPointer color="charcoal70" />}
      padding="24px"
      topPosition="10%"
      modalName="percapitaPay_paymentSuccessModal"
    >
      <div className="center-image">
        <SStatusImage src={images.congratulation} alt="payment succeed" />

        <Title font="Poppins" fontWeight="M" color="charcoal" size="M" marginBottom={25} textAlign={isDesktopSize ? 'center' : 'start'}>
          {t(`percapitaPayHome.Your Percapita Pay Transaction was Successful`)}!
        </Title>

        <Button
          preset="blue-filled"
          size={isDesktopSize ? 'middle' : 'middleStretch'}
          onClick={handleCloseStatusModal}
          marginLeft={isDesktopSize ? 'auto' : ''}
          marginRight={isDesktopSize ? 'auto' : ''}
        >
          {t(`percapitaPayHome.Continue`)}
        </Button>
      </div>
    </CustomModal>
  );
};
