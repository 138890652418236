import React, { useLayoutEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { BodyText, Title } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';
import { Icon } from 'components/general/Icon/Icon';
import { Trans, useTranslation } from 'react-i18next';
import { CountryFlag } from 'views/MoveMoney/InternationalTransferPage/CountryFlag/CountryFlag';
import { selectPolicies } from 'store/user/authentication.slice';
import Map from 'assets/images/map.svg';
import { DestinationList } from 'views/MoveMoney/InternationalTransferPage/AvailableDestinationsSheet/DestinationList';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'vars/const/ROUTES';
import { ISystemProperty } from 'store/user/properties/types';
import { useSelector } from 'react-redux';
import { SCard } from './DestinationCard.styles';

interface IDestinationCardProps {
  countries?: ISystemProperty[];
  showAvailableDestinationsSheet: () => void;
  hideAvailableDestinationsSheet: () => void;
  sendMoneyButtonHandler: () => void;
  transferTermsAndConditionsHandler: () => void;
  manageCardsButtonHandler: () => void;
  screenResponsiveSizes?: {
    [key: string]: boolean;
  };
}

export const DestinationCard: React.FC<IDestinationCardProps> = ({
  countries,
  showAvailableDestinationsSheet,
  hideAvailableDestinationsSheet,
  sendMoneyButtonHandler,
  manageCardsButtonHandler,
  screenResponsiveSizes,
  transferTermsAndConditionsHandler,
}) => {
  const { t } = useTranslation('moveMoney');
  const policies = useSelector(selectPolicies);
  const [isExpanded, setIsExpanded] = useState(false);
  const [bodyHeight, setBodyHeight] = useState(0);
  const bodyRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  const manageCardVisible = policies?.InternationalTransferCardManagementEnabled;

  const onCheckDestinations = () => {
    if (screenResponsiveSizes?.fromDesktopNormal) {
      setIsExpanded((prevState: boolean) => !prevState);
      return;
    }

    showAvailableDestinationsSheet?.();
  };

  useLayoutEffect(() => {
    if (isExpanded) {
      if (bodyRef.current) setBodyHeight(bodyRef.current?.offsetHeight);
    } else {
      setBodyHeight(0);
    }
  }, [isExpanded]);

  useLayoutEffect(() => {
    if (screenResponsiveSizes?.fromDesktopNormal) {
      hideAvailableDestinationsSheet?.();
      return;
    }

    setIsExpanded(false);
  }, [screenResponsiveSizes?.fromDesktopNormal]);

  return (
    <SCard className={clsx('destination-card', isExpanded && 'expanded')} bodyHeight={bodyHeight}>
      <div className="card-header">
        <img src={Map} alt="map" className="image-map" />

        <Title size="M" fontWeight="M" marginBottom={24}>
          {t('internationalTransferPage.SafelySendMoneyOverseas')}
        </Title>

        {countries?.length ? (
          <div className="flags-list">
            <CountryFlag flagCode={countries[0].flag} title={countries[0].country} type="circle" width="auto" />
            {countries?.[1] ? <CountryFlag flagCode={countries[1].flag} title={countries[1].country} type="circle" width="auto" /> : null}
            {countries?.[2] ? <CountryFlag flagCode={countries[2].flag} title={countries[2].country} type="circle" width="auto" /> : null}

            <BodyText size="T" fontWeight="B" display="inline-flex" className="flags-list-item">
              <span>+</span>
              <span>{Number(countries?.length) - 3}</span>
            </BodyText>
          </div>
        ) : null}

        <Button className="destination-card-button check-destinations-button" size="large" onClick={onCheckDestinations}>
          <span>{t('internationalTransferPage.CheckDestinations')}</span>
          <Icon name={isExpanded ? 'chevronUp' : 'chevronDown'} size="smallest" color="blue" />
        </Button>

        <Button className="destination-card-button send-money-button" preset="blue-filled" size="large" onClick={sendMoneyButtonHandler}>
          <span>{t('internationalTransferPage.SendMoney')}</span>
        </Button>

        {manageCardVisible && (
          <Button className="manage-cards-button mobile-manage-btn" size="large" onClick={manageCardsButtonHandler}>
            <span>{t('internationalTransferPage.ManageCards')}</span>
          </Button>
        )}
      </div>

      {manageCardVisible && (
        <Button className="manage-cards-button desktop-manage-btn" size="large" onClick={manageCardsButtonHandler}>
          <span>{t('internationalTransferPage.ManageCards')}</span>
        </Button>
      )}

      <div className="card-body-wrapper">
        <div className="card-body" ref={bodyRef}>
          <Title color="charcoal70" size="sL" marginBottom={4}>
            {t('internationalTransferPage.AvailableDestinations')}
          </Title>

          <BodyText size="N" color="charcoal70" lineHeight={1.4} marginBottom={16}>
            {t('internationalTransferPage.CheckTheAvailableTransferMethodsAndService')}
          </BodyText>

          <div className="additional-text">
            <BodyText size="T" lineHeight={1.3} marginBottom={20}>
              <Trans i18nKey="internationalTransferPage.CurrencyExchangeRatesAndAdditionalConversionFees" ns="moveMoney" />
            </BodyText>

            <BodyText size="T" lineHeight={1.3}>
              <Trans i18nKey="internationalTransferPage.LimitOf2TransactionsPerTransferMethod" ns="moveMoney" />
            </BodyText>
          </div>

          <DestinationList countries={countries} />

          {screenResponsiveSizes?.fromDesktopNormal ? (
            <div className="dont-see-you-country">
              <BodyText size="M" fontWeight="B" color="charcoal70" marginBottom={4}>
                {t('internationalTransferPage.DontSeeYourCountryHere')}
              </BodyText>

              <BodyText className="link" size="N" fontWeight="R" color="blue" onClick={() => navigate(ROUTES.countryRequestPage.path)}>
                {t('internationalTransferPage.ClickHereToLearnMore')}
              </BodyText>
            </div>
          ) : null}

          <BodyText size="T" color="charcoal70" lineHeight={1.3} marginTop={32}>
            <Trans
              i18nKey="internationalTransferPage.InternationalTransferServicesProvidedThroughBancorp"
              ns="moveMoney"
              components={{
                termsLink: <span className="terms-link" onClick={transferTermsAndConditionsHandler} />,
              }}
            />
          </BodyText>
        </div>
      </div>
    </SCard>
  );
};
