import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useRightTimeNotifications } from 'utils/hooks/useRightTimeNotifications';
import { Tabs, TabLabels } from 'components/general/Tabs/Tabs';
import { PercPoints } from 'views/Wellness/PercPoints/PercPoints';
import { ROUTES } from 'vars/const/ROUTES';
import { PageFooter } from 'components/layouts/Page/PageFooter/PageFooter';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { SWellnessPage } from './Wellness.styles';
import { LearnAndPlay } from './LearnAndPlay/LearnAndPlay';
import { WellnessFAQ } from './WellnessFAQ';
import { GoalsAndTools } from './GoalsAndTools/GoalsAndTools';

export const WellnessPage = () => {
  const { t } = useTranslation('wellness');
  const navigate = useNavigate();
  const { tabName } = useParams();
  const { isDesktopSize } = useDeviceDimension();

  const WELLNESS_TABS = useMemo(() => {
    return [
      {
        id: 'goals',
        title: t('goalsTools.Goals & Tools'),
        component: GoalsAndTools,
      },
      {
        id: 'learn',
        title: t('learnPlay.Learn & Play'),
        component: LearnAndPlay,
      },
      {
        id: 'help',
        title: t('helpSupport.HelpAndSupport'),
        component: WellnessFAQ,
      },
    ];
  }, [isDesktopSize, t]);

  useRightTimeNotifications(tabName);

  const handleChangeTab = (tab: string) => {
    navigate(generatePath(ROUTES.wellness.path, { tabName: tab }));
  };

  useEffect(() => {
    if (!tabName || !WELLNESS_TABS.map((tab) => tab.id).includes(tabName)) {
      navigate(generatePath(ROUTES.wellness.path, { tabName: WELLNESS_TABS[0].id }), { replace: true });
    }
  }, [tabName]);

  return (
    <SWellnessPage className="wellness-page">
      <div className="wellness-page__content">
        <TabLabels activeTabId={tabName} labels={WELLNESS_TABS} onSelectTab={handleChangeTab} />
      </div>
      <PercPoints />
      <div className="wellness-page__content">
        <Tabs activeTabId={tabName} tabs={WELLNESS_TABS} />
      </div>
      <PageFooter />
    </SWellnessPage>
  );
};
