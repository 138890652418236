import styled from 'styled-components';
import { css } from 'styled-components/macro';
import { getColor, mediaUpTo, ifProp } from 'utils/helpers/styleHelpers';
import { darken, transparentize } from 'polished';
import { FONT_SIZE } from 'components/general/Typography/Typography.types';

const outlinedCss = css`
  background: transparent;
  color: ${getColor('blue')};
  border: 2px solid ${getColor('blue')};

  &:hover {
    background: ${getColor('blue')};
    color: ${getColor('white')};
  }

  &:active {
    background: ${getColor('blueS30')};
    color: ${getColor('white')};
  }

  &:focus {
    background: ${getColor('blue')};
    border-color: ${getColor('blue20')};
    outline: 2px solid ${getColor('blue20')};
    color: ${getColor('white')};
  }
`;
const filledCss = css`
  background: ${getColor('blue')};
  border: 2px solid ${getColor('blue')};
  color: ${getColor('white')};

  &:hover {
    border-color: ${getColor('blueS30')};
    background: ${getColor('blueS30')};
  }

  &:active {
    border: 2px solid ${getColor('blueS50')};
    background: ${getColor('blueS50')};
  }

  &:focus {
    background: ${getColor('blue')};
    border-color: ${getColor('blue20')};
    outline: 2px solid ${getColor('blue20')};
  }
`;

const disabledBtnCss = css`
  background: ${getColor('charcoal40')};
  cursor: not-allowed;
  border: 2px solid ${getColor('charcoal30')};
  opacity: 0.7;
`;

export const SButton = styled.button<{ noBorder?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  transition: all 100ms linear;
  white-space: nowrap;

  &.preset {
    &-blue-outlined {
      ${outlinedCss};
    }

    &-blue-filled {
      ${filledCss};
    }

    &-cream {
      background: ${getColor('cream')};
      color: ${getColor('blue')};
      border: 2px solid ${getColor('cream')};

      &:hover {
        background: ${(props) => transparentize(0.1, getColor('cream')(props) as string)};
      }

      &:active {
        background: ${getColor('creamSS1')};
      }

      &:disabled {
        ${disabledBtnCss};
      }
    }

    &-secondary {
      background: ${getColor('transparent')};
      color: ${getColor('blue')};
      border: 2px solid ${getColor('blue')};

      &:active {
        background: ${getColor('white')};
      }

      &:disabled {
        ${disabledBtnCss};
        background: ${getColor('transparent')};
        color: ${getColor('charcoal40')};
      }
    }

    &-red-outlined {
      ${outlinedCss};
      border-color: ${getColor('red')};
      color: ${getColor('red')};

      &:hover {
        background: ${getColor('red')};
      }

      &:active {
        background: ${(props) => darken(0.1, getColor('red')(props))};
      }

      &:focus {
        background: ${getColor('red')};
        border-color: ${getColor('red20')};
        outline-color: ${getColor('red20')};
      }
    }

    &-red-filled {
      ${filledCss};
      background: ${getColor('red')};
      border-color: ${getColor('red')};

      &:hover {
        background: ${(props) => darken(0.1, getColor('red')(props))};
      }

      &:active {
        background: ${(props) => darken(0.2, getColor('red')(props))};
      }

      &:focus {
        background: ${getColor('red')};
        border-color: ${getColor('red20')};
        outline-color: ${getColor('red20')};
      }
    }

    &-primary-with-outline {
      background: ${getColor('blue')};
      color: ${getColor('white')};
      border: 2px solid ${getColor('blue')};

      &:disabled {
        background: transparent;
        ${disabledBtnCss};
      }
    }

    &-transparent {
      color: ${getColor('charcoal')};
      background: transparent;
      border: 2px solid ${getColor('transparent')};
    }

    &-white-filled {
      color: ${getColor('blue')};
      background: ${getColor('white')};
    }
  }

  &:disabled.preset {
    &-blue-outlined,
    &-red-outlined {
      background: ${getColor('transparent')};
      border-color: ${getColor('charcoal40')};
      color: ${getColor('charcoal40')};
      cursor: not-allowed;
    }

    &-blue-filled {
      background: ${getColor('charcoal40')};
      border-color: ${getColor('charcoal40')};
      color: ${getColor('white')};
      cursor: not-allowed;
    }
  }

  &.size {
    background: red;
    &-unset {
      min-width: unset;
    }

    &-small {
      min-width: 104px;
      padding: 8px 14px;
      font-size: 12px;
      line-height: 18px;
    }

    &-middle {
      min-width: 113px;
      padding: 8px 24px;
      font-size: 14px;
      line-height: 20px;
    }

    &-xl {
      width: 100%;
      padding: 14px 24px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }

    &-middleStretch {
      width: 100%;
      padding: 10px 24px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    &-middleAlt {
      min-width: 120px;
      padding: 10px 24px;
      font-size: 14px;
      line-height: 20px;
    }

    &-large {
      width: 100%;
      padding: 12px 32px;
      font-size: 18px;
      line-height: 28px;

      ${mediaUpTo(
        'tablet',
        css`
          padding: 8px 10px;
          font-size: ${FONT_SIZE.bodyText.M};
        `
      )}
    }
  }

  .btn__inner {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${ifProp(
    'noBorder',
    css`
      border: 0 !important;
    `
  )};
`;
