import React, { useEffect } from 'react';
import { images } from 'assets';
import { Loader } from 'components/general/Loader/Loader';
import { Title, BodyText } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { Trans, useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { useToggle } from 'utils/hooks/useToggle';
import { useAddAccountMutation } from 'store/user/accounts/accounts.api';
import { ROUTES } from 'vars/const/ROUTES';
import { EAccountType, IAccountItem } from 'store/user/accounts/accounts.types';
import { Header } from 'views/Main/Header/Header';
import { Breadcrumbs } from 'views/Main/Header/Breadcrumbs/Breadcrumbs';
import { TBreadcrumbsPath } from 'views/OpenCashAccount/StarterPage/CashAccOpeningDesktopPage/CashAccOpeningDesktopPage';
import { SimpleAccountSelectSheet } from 'components/general/Accounts/AccountSelect/SimpleAccountSelectSheet/SimpleAccountSelectSheet';
import { useAccounts } from 'utils/hooks/useAccounts';
import { useEnhancedNav } from 'utils/hooks/useEnhancedNav';
import { getCashAccountsWithSubIds } from 'utils/helpers/accounts/accountsHelper';
import { StuffSaveAccountErrorModal } from 'components/general/Modals/AddStuffSaveAccountModal/StuffSaveAccountErrorModal';
import { PageFooter } from 'components/layouts/Page/PageFooter/PageFooter';
import clsx from 'clsx';
import { Card } from './Card/Card';
import { SAddNeedsGoalsPage, SList, SListItem, SLayout, SBreadcrumbsWrapper } from './AddNeedsGoals.styles';

export const AddNeedsGoalsPage = () => {
  const { navigate } = useEnhancedNav();
  const selectAccountSheet = useToggle();
  const errorModal = useToggle();
  const { type: addAccountType } = useParams();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation('addAccount');
  const { cashAccounts: accounts, internalAccounts } = useAccounts();
  const preselectedAccount = accounts.find((acc) => acc.accountId === searchParams.get('accountId'));
  const { isDesktopSize } = useDeviceDimension();
  const [addAccountAPI, addAccountAPIResult] = useAddAccountMutation();
  const accountsWitsSubIds = getCashAccountsWithSubIds(internalAccounts, addAccountType);

  const pathList: TBreadcrumbsPath[] = [
    { id: 0, name: t('addAccount.MoreWithPercapita'), path: ROUTES.doMore.path },
    { id: 1, name: '' },
  ];

  const getDescriptionForSheet = () => {
    const key = accountsWitsSubIds.length ? 'SelectDescriptionWithDisabled' : 'SelectDescription';
    return t(`addAccount.${addAccountType}.${key}`);
  };

  const addAccountRequest = async (accountId: string) => {
    const percapitaAccountType = addAccountType === 'needs' ? EAccountType.STUFF : EAccountType.SAVE;
    const selectedCashAccount = accounts.find((acc) => acc.accountId === accountId);
    if (selectedCashAccount) {
      await addAccountAPI({
        percapitaAccountType,
        jointInviteType: selectedCashAccount.isPrimary ? undefined : 'JointOwner',
        cashAccountId: selectedCashAccount.accountId,
        userReferralCode: '',
      });
    }
  };

  const handleAddAccount = () => {
    if (preselectedAccount) {
      addAccountRequest(preselectedAccount.accountId);
    } else {
      if (isDesktopSize) {
        navigate(ROUTES.addNeedsGoalsAccountSelect.path, { params: { type: addAccountType } });
      } else {
        selectAccountSheet.show();
      }
    }
  };

  const handleSelectAccount = (account: IAccountItem) => {
    selectAccountSheet.hide();
    addAccountRequest(account.accountId);
  };

  useEffect(() => {
    if (addAccountAPIResult?.isSuccess) navigate(ROUTES.successAddNeedsGoalsAccount.path, { params: { type: addAccountType } });
    if (addAccountAPIResult?.isError) {
      errorModal.show();
    }
  }, [addAccountAPIResult?.isSuccess, addAccountAPIResult?.isError]);

  if (isDesktopSize) {
    return (
      <>
        <SBreadcrumbsWrapper>
          <Breadcrumbs paths={pathList} title={addAccountType === 'needs' ? t('addAccount.needs.Title') : t('addAccount.goals.Title')} hasBackNav />
        </SBreadcrumbsWrapper>

        <SLayout>
          <Header headerTitle={addAccountType === 'needs' ? t('addAccount.needs.Title') : t('addAccount.goals.Title')} image={addAccountType === 'needs' ? images.addNeeds : images.addGoals}>
            <Title fontWeight="SB" size="S" textAlign="start" justifyContent="start" className="header-subtitle">
              {addAccountType === 'needs' ? t('addAccount.needs.Subtitle') : t('addAccount.goals.Subtitle')}
            </Title>

            <BodyText textType="bodyText" size="L" fontWeight="R" color="charcoal" lineHeight="24px" textAlign="start" justifyContent="start" className="header-description">
              <Trans i18nKey={addAccountType === 'needs' ? 'addAccount.needs.Description' : 'addAccount.goals.Description'} ns="addAccount" />
            </BodyText>
          </Header>

          <div className="content-container">
            {addAccountAPIResult.isLoading && <Loader />}

            <Card
              title={t(addAccountType === 'needs' ? 'addAccount.needs.important.Title' : 'addAccount.goals.important.Title')}
              name="moneyTransferCircle"
              list={[
                <Trans i18nKey={addAccountType === 'needs' ? 'addAccount.needs.important.SetAside' : 'addAccount.goals.important.LongTerm'} />,
                <Trans i18nKey="addAccount.NotLinked" values={{ addAccountType }} ns="addAccount" />,
                <Trans i18nKey="addAccount.TransferFunds" />,
                <Trans i18nKey="addAccount.FDIC" />,
              ]}
            />
          </div>

          <div className="button-panel">
            <Button preset="blue-filled" size="xl" onClick={handleAddAccount}>
              {addAccountType === 'needs' ? t('addAccount.needs.Button') : t('addAccount.goals.Button')}
            </Button>
          </div>

          <div className="disclosure-container">
            <BodyText textType="bodyText" color="charcoal70" size="T" fontWeight="R" textAlign="start" justifyContent="start">
              {t('addAccount.Disclosure')}
            </BodyText>
          </div>
        </SLayout>
      </>
    );
  }

  return (
    <SAddNeedsGoalsPage className={clsx(addAccountType)} flexDirection="column" minHeight="100%">
      {addAccountAPIResult.isLoading && <Loader />}

      <div className="add-needs-goals-page-header">
        <div className="add-needs-goals-page-image center-image">
          <img src={addAccountType === 'needs' ? images.addNeeds : images.addGoals} alt={addAccountType === 'needs' ? 'addNeeds' : 'addGoals'} />
        </div>

        <div className="add-needs-goals-page-title">
          <Title color="charcoal" font="Poppins" fontWeight="SB" size="L" textAlign="start" className="title">
            {addAccountType === 'needs' ? t('addAccount.needs.Title') : t('addAccount.goals.Title')}
          </Title>

          <Title color="charcoal" font="Poppins" fontWeight="R" size="T" textAlign="start" marginTop="spacing-tiny" className="account-subtitle">
            {addAccountType === 'needs' ? t('addAccount.needs.Subtitle') : t('addAccount.goals.Subtitle')}
          </Title>
        </div>

        <div className="add-needs-goals-page-body">
          <BodyText className="description" textType="bodyText" size="M" fontWeight="R" color="charcoal" marginTop="spacing-x-large" lineHeight="24px">
            <Trans i18nKey={addAccountType === 'needs' ? 'addAccount.needs.Description' : 'addAccount.goals.Description'} ns="addAccount" />
          </BodyText>

          <BodyText className="options-title" textType="bodyText" size="M" fontWeight="B" color="charcoal" marginTop="spacing-med" lineHeight="24px" extraStyles={{ width: '100%' }}>
            <Trans i18nKey={addAccountType === 'needs' ? 'addAccount.needs.important.Title' : 'addAccount.goals.important.Title'} ns="addAccount" />
          </BodyText>

          <SList className="options">
            <SListItem>
              <BodyText textType="bodyText" size="M" fontWeight="R" color="charcoal70" lineHeight="24px">
                <Trans i18nKey={addAccountType === 'needs' ? 'addAccount.needs.important.SetAside' : 'addAccount.goals.important.LongTerm'} ns="addAccount" />
              </BodyText>
            </SListItem>

            <SListItem>
              <BodyText textType="bodyText" size="M" fontWeight="R" color="charcoal70" lineHeight="24px">
                <Trans i18nKey="addAccount.NotLinked" ns="addAccount" values={{ addAccountType }} />
              </BodyText>
            </SListItem>

            <SListItem>
              <BodyText textType="bodyText" size="M" fontWeight="R" color="charcoal70" lineHeight="24px">
                <Trans i18nKey="addAccount.TransferFunds" ns="addAccount" />
              </BodyText>
            </SListItem>

            <SListItem>
              <BodyText textType="bodyText" size="M" fontWeight="R" color="charcoal70" lineHeight="24px">
                <Trans i18nKey="addAccount.FDIC" ns="addAccount" />
              </BodyText>
            </SListItem>
          </SList>
        </div>
      </div>

      <div>
        <div className="add-needs-goals-page-footer">
          <Button preset="blue-filled" size="large" onClick={handleAddAccount}>
            {addAccountType === 'needs' ? t('addAccount.needs.Button') : t('addAccount.goals.Button')}
          </Button>
        </div>
        <PageFooter type="full" />
      </div>

      <SimpleAccountSelectSheet
        accounts={accounts}
        isOpen={selectAccountSheet.isActive}
        onClose={selectAccountSheet.hide}
        onSelect={handleSelectAccount}
        disableIds={accountsWitsSubIds}
        description={getDescriptionForSheet()}
        title={t('addAccount.SelectCashAccountTitle')}
      />
      <StuffSaveAccountErrorModal open={errorModal.isActive} onClose={errorModal.hide} />
    </SAddNeedsGoalsPage>
  );
};
