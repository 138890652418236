import styled, { css } from 'styled-components/macro';
import { getColor, mediaFrom, flex, mediaUpTo } from 'utils/helpers/styleHelpers';

export const SBalancesAndTransactionsPage = styled.div<{ isDesktopSize?: boolean }>`
  display: flex;
  min-height: 100%;
  padding: ${({ isDesktopSize }) => (isDesktopSize ? '0' : '0 0 68px')};

  & .flex {
    &-view {
      ${flex('row', 'space-between', 'flex-start')};
    }

    &-column-view {
      flex-direction: column;
    }

    &-justify-center {
      justify-content: center;
    }

    &-justify-start {
      justify-content: flex-start;
    }

    &-align-start {
      align-items: flex-start;
    }

    &-align-stretch {
      align-items: stretch;
    }
  }

  & .account-transaction {
    padding-bottom: 32px;
    border-bottom: 1.5px solid ${getColor('creamS5')};
  }

  & .available-balance {
    width: 100%;
    margin-bottom: 24px;
    gap: 16px;
  }

  .sort-by {
    margin-bottom: 20px;
  }
`;

export const SMainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
  width: 100%;
  margin-bottom: 1rem;

  .content {
    flex: 1 1 auto;
    justify-content: space-between;
  }

  .filter-search {
    display: flex;
    justify-content: flex-end;
    margin-bottom: -30px;
  }

  .content-inner {
    display: flex;
    flex-direction: column;
  }

  .sutton-disclaimer-note {
    margin: 0;
  }

  .additional-details {
    padding: 16px 24px 16px 16px;
    background-color: ${getColor('cream')};
    border-radius: 16px;

    .svg-icon {
      transform: rotate(-15deg);
      margin-right: 12px;
    }
  }

  .transactions {
    position: relative;
  }

  section.group {
    margin-top: 15px;

    h2 {
      font-size: 12px;
      font-weight: 400;
      color: ${getColor('charcoal')};
      margin-bottom: 16px;
    }

    .group-name {
      margin-bottom: 16px;
      align-items: center;
    }

    ul {
      list-style: none;
      padding: 24px;
      background-color: ${getColor('white')};
      border-radius: 24px;
    }

    li {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ${mediaUpTo(
    'mobile',
    css`
      .mobile-title {
        .custom-title-text {
          font-size: 18px;
        }
      }
      section.group {
        .group-name .custom-text-inner {
          font-size: 12px;
        }

        ul {
          padding: 20px 18px;

          .description {
            font-size: 12px;
          }
        }

        .transaction-sign-internal {
          width: 32px;
          height: 32px;

          .svg-icon {
            width: 20px;
            height: 20px;
          }
        }

        .amount .custom-text-inner {
          font-size: 12px;
        }
      }
    `
  )};
  ${mediaFrom(
    'tablet',
    css`
      padding-top: 54px;

      .content {
        padding: 30px 0;
      }
    `
  )};
  ${mediaFrom(
    'desktop',
    css`
      .content {
        max-width: 648px;
        margin: 0 auto;
      }
    `
  )};
`;

export const STransactionSign = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${getColor('charcoal5')};

  .arrow {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: -7px;
    right: -7px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid ${getColor('white')};

    &:before {
      content: '';
      position: relative;
      width: 6px;
      height: 1px;
      background: ${getColor('white')};
    }

    &:after {
      content: '';
      position: relative;
      width: 5px;
      height: 5px;
      border: 1px solid ${getColor('white')};
      border-top: none;
      border-right: none;
      transform: rotate(45deg);
    }

    &.income {
      background: ${getColor('green')};
      &:after {
        margin-left: -7px;
        transform: rotate(45deg);
      }
    }

    &.expense {
      flex-direction: row-reverse;
      background: ${getColor('red')};
      &:after {
        margin-right: -7px;
        transform: rotate(-136deg);
      }
    }
  }
`;

export const STransaction = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: center;

  .description {
    flex-grow: 1;
    margin-left: 15px;
    font-weight: 600;
    color: ${getColor('black')};
    font-size: 14px;

    .transaction-bottom-caption {
      font-weight: 400;
      font-size: 12px;
      color: ${getColor('charcoal90')};
    }
  }

  .amount {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
  }
`;
