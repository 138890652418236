import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { CustomRequiredLabel } from 'components/theme/CustomRequiredLabel/CustomRequiredLabel';
import { Title } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';
import { JointAccountSuccessModal } from 'views/Account/AccountInformation/Modals/editAccount/JointAccountSuccessModal/JointAccountSuccessModal';
import { fullNameCharacterRule, getEmailRulesOptional, getPhoneValidator, getRequiredRule } from 'utils/helpers/validationRules';
import { BaseInput } from 'components/general/BaseInput/BaseInput';
import { Breadcrumbs } from 'views/Main/Header/Breadcrumbs/Breadcrumbs';
import { MaskedInput } from 'components/general/MaskedInput/MaskedInput';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { SuttonDisclaimerNote } from 'components/general/DisclaimerNote/SuttonDisclaimerNote';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { getUnmaskedMobileNumber } from 'utils/helpers/phoneNumber';
import { useInviteJointAccountHolderMutation } from 'store/user/accounts/accounts.api';
import { ROUTES } from 'vars/const/ROUTES';
import { IJointAccountsForm } from 'views/OpenCashAccount/MyAccount/JointAccounts/JointAccountsForm/JointAccountsForm.types';
import { useToggle } from 'utils/hooks/useToggle';
import { useLazyGetCurrentUserQuery } from 'store/user/users.api';
import { Loader } from 'components/general/Loader/Loader';
import { TBreadcrumbsPath } from 'views/OpenCashAccount/StarterPage/CashAccOpeningDesktopPage/CashAccOpeningDesktopPage';
import { handleError } from 'utils/helpers/errorHelper';
import { SBreadcrumbsWrapper, SWrapper } from './AddJointAccountPage.styles';

const PHONE_MASK = '(000) 000 0000';

const PHONE_MASK_OPTIONS = {
  lazy: true,
};

export const AddJointAccountPage = () => {
  const { t } = useTranslation(['accountInformation', 'accountOpening', 'errors', 'profile']);
  const location = useLocation();
  const isUnder18 = !!location.state?.backPage?.includes(ROUTES.myInfoTaxId.path);
  const [form] = Form.useForm();
  const [inviteJointAccountHolderAPI, inviteJointAccountHolderAPIResult] = useInviteJointAccountHolderMutation();
  const [getCurrentUserAPI, getCurrentUserAPIResult] = useLazyGetCurrentUserQuery();
  const [isActive, setIsActive] = useState(false);
  const jointAccountSuccess = useToggle();

  const pathList: TBreadcrumbsPath[] = [
    { id: 0, name: t('accountInformation.Accounts'), path: ROUTES.accountInformation.path },
    { id: 1, name: t('accountInformation.YourCashAccount') },
    { id: 2, name: '' },
  ];

  const handleSuccess = () => {
    jointAccountSuccess.show();
  };

  const isValid = () => {
    const errors = form.getFieldsError().filter((item) => item.errors.length > 0);
    return errors.length === 0;
  };

  const handleFormChange = (): void => {
    const phoneNumber = form.getFieldValue('phone');
    const phoneNumberDigits = phoneNumber.replace(/\D/g, '');
    setIsActive(phoneNumberDigits.length !== 0 && phoneNumberDigits.length !== undefined && phoneNumberDigits.length === 10);
  };

  const hasEmptyValues = () => {
    const values = Object.values(form.getFieldsValue());
    return values.includes(undefined);
  };

  const onFinish = (formValues: IJointAccountsForm) => {
    inviteJointAccountHolderAPI({
      isPrimaryOwner: !isUnder18,
      ...formValues,
      phone: getUnmaskedMobileNumber(`1${formValues.phone}`),
    });
    form.resetFields();
  };

  const onFieldsChange = () => {
    setIsActive(isValid() && !hasEmptyValues());
  };

  useEffect(() => {
    if (inviteJointAccountHolderAPIResult.isSuccess) getCurrentUserAPI();
    if (getCurrentUserAPIResult.isSuccess) handleSuccess();
  }, [inviteJointAccountHolderAPIResult.isSuccess, getCurrentUserAPIResult.isSuccess]);

  useEffect(() => {
    if (inviteJointAccountHolderAPIResult.isError) {
      handleError(inviteJointAccountHolderAPIResult.error);
    }
  }, [inviteJointAccountHolderAPIResult.isError]);

  return (
    <>
      <SBreadcrumbsWrapper>
        <Breadcrumbs paths={pathList} title={t('accountInformation.AddJointAccountholder')} hasBackNav />
      </SBreadcrumbsWrapper>

      <CustomRow justifyContent="center" flexDirection="column">
        <SWrapper>
          <Title color="charcoal" size="T" fontWeight="SB" marginTop={56} marginBottom={20}>
            {t(`accountOpening.JointAccountholderHaveToCompleteCashAccountProcess`)}
          </Title>

          {inviteJointAccountHolderAPIResult.isLoading || getCurrentUserAPIResult.isFetching ? (
            <Loader />
          ) : (
            <CustomCard>
              <Form onFinish={onFinish} onFieldsChange={onFieldsChange} autoComplete="off" layout="vertical" requiredMark={false} form={form}>
                <Form.Item
                  style={{ marginBottom: 32 }}
                  label={<CustomRequiredLabel label={t("accountOpening.Joint Accountholder's First Name")} isDanger />}
                  name="firstName"
                  validateTrigger={['onBlur', 'onChange']}
                  validateFirst
                  rules={[getRequiredRule(t('errors.PleaseInputTheirFirstName', { ns: 'errors' })), fullNameCharacterRule()]}
                >
                  <BaseInput placeholder={t(`accountOpening.EnterTheirFirstName`)} />
                </Form.Item>

                <Form.Item
                  label={<CustomRequiredLabel label={t("accountOpening.Joint Accountholder's Last Name")} isDanger />}
                  name="lastName"
                  style={{ marginBottom: 32 }}
                  validateTrigger={['onBlur', 'onChange']}
                  validateFirst
                  rules={[getRequiredRule(t('errors.PleaseInputTheirLastName', { ns: 'errors' })), fullNameCharacterRule()]}
                >
                  <BaseInput placeholder={t(`accountOpening.EnterTheirLastName`)} />
                </Form.Item>

                <Form.Item
                  label={<CustomRequiredLabel label={t("accountOpening.Joint Accountholder's Email")} isDanger />}
                  name="email"
                  style={{ marginBottom: 32 }}
                  validateTrigger={['onBlur', 'onChange']}
                  validateFirst
                  rules={[getRequiredRule(t('errors.PleaseInputTheirEmail', { ns: 'errors' })), getEmailRulesOptional()]}
                >
                  <BaseInput placeholder={t(`accountOpening.EnterTheirEmail`)} inputMode="email" autoCapitalize="off" />
                </Form.Item>

                <Form.Item
                  label={<CustomRequiredLabel label={t("accountOpening.Joint Accountholder's Mobile Number")} isDanger />}
                  name="phone"
                  style={{ marginBottom: 32 }}
                  validateTrigger={['onBlur', 'onChange']}
                  rules={[getRequiredRule(t('profile.Please enter your Phone Number', { ns: 'profile' })), getPhoneValidator(t('inviteEarn.PleaseInputCompleteMobileNumber', { ns: 'inviteEarn' }))]}
                >
                  <MaskedInput mask={PHONE_MASK} maskOptions={PHONE_MASK_OPTIONS} placeholder={t(`accountOpening.EnterTheirMobileNumber`)} onChange={handleFormChange} inputMode="tel" />
                </Form.Item>
                <CustomRow justifyContent="center">
                  <Button marginBottom={16} size="middleAlt" type="submit" disabled={!isActive} preset="blue-filled">
                    {t('accountInformation.AddJointAccountholder')}
                  </Button>
                </CustomRow>
              </Form>
            </CustomCard>
          )}
        </SWrapper>
        <CustomRow justifyContent="center" marginLeft={32} marginRight={32}>
          <SuttonDisclaimerNote marginBottom={56} />
        </CustomRow>
      </CustomRow>
      <JointAccountSuccessModal open={jointAccountSuccess.isActive} handleClose={jointAccountSuccess.hide} />
    </>
  );
};
