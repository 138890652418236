import React, { useEffect, useState } from 'react';
import { BodyText, Title } from 'components/general/Typography';
import { Trans, useTranslation } from 'react-i18next';
import Map from 'assets/images/map.svg';
import { Button } from 'components/theme/Button/Button';
import { useToggle } from 'utils/hooks/useToggle';
import { useProperties } from 'utils/hooks/useProperties';
import { ConsentId, ConsentType } from 'components/general/Consent/Consents.types';
import { ConsentSheet } from 'components/general/Consent/ConsentSheet';
import { useConsents } from 'utils/hooks/useConsents';
import { IConsentData } from 'store/user/consents/consents.types';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'vars/const/ROUTES';
import { mobileApiCall } from 'services/mobileService';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { ComingSoonModal } from 'views/MoveMoney/MoveMoneyPage/ComingSoonModal';
import { useAccounts } from 'utils/hooks/useAccounts';
import { ValidateCardSheet } from 'views/MoveMoney/ManageCardsPage/ValidateCardSheet/ValidateCardSheet';
import { useSelector } from 'react-redux';
import { selectPolicies } from 'store/user/authentication.slice';
import { IAccountItem } from 'store/user/accounts/accounts.types';
import { SLayout } from './InternationalTransferPage.styles';
import { HowDoesItWorkSection } from './HowDoesItWorkSection/HowDoesItWorkSection';
import { ICantSelectMyCountrySheet } from './ICantSelectMyCountrySheet/ICantSelectMyCountrySheet';
import { SelectYourCountrySheet } from './SelectYourCountrySheet/SelectYourCountrySheet';
import { CountriesRequestSubmitModal } from './CountriesRequestSubmitModal/CountriesRequestSubmitModal';
import { AvailableDestinationsSheet } from './AvailableDestinationsSheet/AvailableDestinationsSheet';
import { DestinationCard } from './DestinationCard/DestinationCard';
import { ICountryRequestParams } from './types';

export const InternationalTransferPage = () => {
  const { t } = useTranslation('moveMoney');
  const { fromDesktopNormal } = useDeviceDimension();
  const policies = useSelector(selectPolicies);
  const navigate = useNavigate();
  const { internalAccounts } = useAccounts();
  const selectYourCountrySheet = useToggle(false);
  const readyRemitConsentSheet = useToggle(false);
  const iCantSelectMyCountrySheet = useToggle(false);
  const availableDestinationsSheet = useToggle(false);
  const countriesRequestSubmitModal = useToggle(false);
  const validateCardSheet = useToggle();
  const [countryRequestParams, setCountryRequestParams] = useState<ICountryRequestParams>({});

  const manageCardsEnabled = policies?.InternationalTransferCardManagementEnabled;
  const hasAddedCard = internalAccounts.some((account: IAccountItem) => account.isAddedToReadyRemit === true);

  const {
    getSystemProperty,
    getSystemPropertyResult: { data: countries },
  } = useProperties();

  const readyRemitConsent = useConsents(ConsentType.READY_REMIT);
  const readyRemitConsentData = readyRemitConsent?.consentsData?.find((consent) => consent?.id === ConsentId.READY_REMIT_TERMS_OF_USE);
  const transferTermsAndConditionsHandler = () => readyRemitConsentSheet.show();

  const sendMoneyButtonHandler = () => {
    if (manageCardsEnabled) {
      if (!hasAddedCard) {
        validateCardSheet.show();
      }
      else {
        mobileApiCall('readyRemitRequest');
      }
    }
    else {
      mobileApiCall('readyRemitRequest');
    }
  };

  const manageCardsButtonHandler = () => {
    navigate(ROUTES.manageCards.path);
  };

  const availableDestinationsSheetActions = {
    isOpen: availableDestinationsSheet.isActive,
    onClose: availableDestinationsSheet.hide,
    onLearnMoreClick: () => {
      availableDestinationsSheet.hide();
      iCantSelectMyCountrySheet.show();
    },
    transferTermsAndConditionsHandler,
  };

  const iCantSelectMyCountrySheetActions = {
    isOpen: iCantSelectMyCountrySheet.isActive,
    onClose: iCantSelectMyCountrySheet.hide,
    onBack: () => {
      availableDestinationsSheet.show();
      iCantSelectMyCountrySheet.hide();
    },
    onSelectDestinationClick: () => {
      iCantSelectMyCountrySheet.hide();
      selectYourCountrySheet.show();
    },
    setCountryRequestParams,
  };

  const selectYourCountrySheetActions = {
    isOpen: selectYourCountrySheet.isActive,
    onClose: () => {
      selectYourCountrySheet.hide();
    },
    onBack: () => {
      selectYourCountrySheet.hide();
      iCantSelectMyCountrySheet.show();
    },
    showCountriesRequestSubmitModal: () => {
      selectYourCountrySheet.hide();
      countriesRequestSubmitModal.show();
    },
  };

  const countriesRequestSubmitModalActions = {
    isOpen: countriesRequestSubmitModal.isActive,
    onClose: countriesRequestSubmitModal.hide,
    handleContinue: countriesRequestSubmitModal.hide,
  };

  useEffect(() => {
    getSystemProperty('internationalTransfersDestinations');
  }, []);

  return (
    <>
      <SLayout>
        <div className="page-header">
          <div className="info">
            <Title marginBottom={18}>{t('internationalTransferPage.InternationalTransfer')}</Title>

            <BodyText className="description-row-1" size="N" color="charcoal70" lineHeight={1.4} marginBottom={24}>
              {t('internationalTransferPage.SafelySendMoneyToSelectCountries')}
            </BodyText>

            <BodyText className="description-row-2" size="N" color="charcoal70" lineHeight={1.4}>
              {t('internationalTransferPage.InternationalTransferReadyRemit')}
            </BodyText>
          </div>

          <img src={Map} alt="map" className="image-map-desktop" />
        </div>

        <DestinationCard
          countries={countries}
          showAvailableDestinationsSheet={availableDestinationsSheet.show}
          hideAvailableDestinationsSheet={availableDestinationsSheet.hide}
          sendMoneyButtonHandler={sendMoneyButtonHandler}
          manageCardsButtonHandler={manageCardsButtonHandler}
          screenResponsiveSizes={{ fromDesktopNormal }}
          transferTermsAndConditionsHandler={transferTermsAndConditionsHandler}
        />

        <HowDoesItWorkSection screenResponsiveSizes={{ fromDesktopNormal }} />

        <div className="verification-times">
          <BodyText size="N" color="charcoal70" lineHeight={1.4}>
            <Trans
              i18nKey="internationalTransferPage.VerificationTimesVaryAndMayDelay"
              ns="moveMoney"
              components={{ termsLink: <span className="terms-link" onClick={transferTermsAndConditionsHandler} /> }}
            />
          </BodyText>
        </div>

        <div className="btn-container">
          <Button className="send-money-button-desktop" preset="blue-filled" onClick={sendMoneyButtonHandler}>
            {t('internationalTransferPage.SendMoney')}
          </Button>
        </div>

        <div className="footer">
          <BodyText size="T" color="charcoal70" lineHeight={1.3} marginBottom={20}>
            {t('internationalTransferPage.VerificationTimesVaryAnd')}
          </BodyText>

          <BodyText size="T" color="charcoal70" lineHeight={1.3}>
            {t('internationalTransferPage.PercapitaGroupIncIsADigitalCompany')}
          </BodyText>
        </div>
      </SLayout>

      <SelectYourCountrySheet {...selectYourCountrySheetActions} countryRequestParams={countryRequestParams} />
      <ICantSelectMyCountrySheet {...iCantSelectMyCountrySheetActions} />
      <AvailableDestinationsSheet {...availableDestinationsSheetActions} countries={countries} />
      <CountriesRequestSubmitModal {...countriesRequestSubmitModalActions} />
      <ConsentSheet key={readyRemitConsentData?.id} consentData={readyRemitConsentData as IConsentData} isOpen={readyRemitConsentSheet.isActive} onClose={readyRemitConsentSheet.hide} isReadonly />
      <ComingSoonModal />
      <ValidateCardSheet isOpen={validateCardSheet.isActive} onClose={validateCardSheet.hide} internalAccounts={internalAccounts} fromSendMoney />
    </>
  );
};
