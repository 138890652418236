import React, { useMemo, useRef, useState } from 'react';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'vars/const/ROUTES';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/theme/Button/Button';
import { BodyText } from 'components/general/Typography';
import { TextButton } from 'components/general/TextButton/TextButton';
import { PageFooter } from 'components/layouts/Page/PageFooter/PageFooter';
import { Icon } from 'components/general/Icon/Icon';
import { OnboardingSlide } from 'views/Onboarding/OnboardingSlide/OboardingSlide';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { SCREEN_SIZE } from 'utils/helpers/styleHelpers';
import cashAccountImg from './images/cashAccountImg.png';
import percapitaPayImg from './images/percapitaPayImg.png';
import boostFinancialWellnessImg from './images/boostFinancialWellnessImg.png';
import { SActions, SItemsSlider, SLearnMoreTextWrapper, SOnboardingPageLayout } from './OnboardingPage.styles';

export const OnboardingPage = () => {
  const { t } = useTranslation('preRegOnboarding');
  const [currentSlide, setCurrentSlide] = useState(0);
  const { isDesktopSize, isMobileRegularSize, isTabletSize, fromTablet, fromDesktopSmall } = useDeviceDimension();
  const navigate = useNavigate();
  const sliderRef = useRef<Slider | null>(null);
  const slidesCenterPadding = useMemo(() => {
    let paddingVal = '5%';

    if (isMobileRegularSize) paddingVal = '6%';
    if (isTabletSize) paddingVal = '30%';
    if (fromDesktopSmall) paddingVal = 'auto';

    return paddingVal;
  }, [isDesktopSize, isTabletSize, isMobileRegularSize, fromDesktopSmall]);

  const sliderSetting = {
    className: fromDesktopSmall ? 'no-slider' : 'slider',
    infinite: false,
    slidesToShow: 1,
    centerPadding: slidesCenterPadding,
    centerMode: true,
    arrows: true,
    dots: true,
    afterChange: (index: number) => {
      setCurrentSlide(index);
    },
    appendDots: (dots: React.ReactNode) => (
      <div className="slider-actions">
        {isTabletSize && <Icon onClick={sliderRef.current?.slickPrev} name="chevronLeft" size="smaller" marginRight={12} />}
        <div className="dots">{dots}</div>
        {isTabletSize && <Icon onClick={sliderRef.current?.slickNext} name="chevronRight" size="smaller" marginLeft={12} />}
      </div>
    ),
    responsive: [
      {
        breakpoint: SCREEN_SIZE.desktop,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 10000, // a unrealistically big number to cover up greatest screen resolution
        settings: 'unslick' as const,
      },
    ],
  };

  return (
    <SOnboardingPageLayout className="onboarding-page-layout">
      <div className="top">
        <SItemsSlider
          ref={(slider) => {
            sliderRef.current = slider;
          }}
          {...sliderSetting}
        >
          <OnboardingSlide img={cashAccountImg} title={t('preRegOnboarding.CashAccountAndDebitCard')} description={t('preRegOnboarding.CashAccountDescription')} className="cash-account" />
          <OnboardingSlide
            img={percapitaPayImg}
            title={t('preRegOnboarding.PercapitaPay')}
            subtitle={t('preRegOnboarding.PoweredByImmediate')}
            description={t('preRegOnboarding.PercapitaPayDescription')}
            className="percapita-pay"
          />
          <OnboardingSlide
            img={boostFinancialWellnessImg}
            title={t('preRegOnboarding.BoostFinancialWellness')}
            description={t('preRegOnboarding.BoostFinancialWellnessDescription')}
            className="financial-wellness"
          />
        </SItemsSlider>
        {currentSlide === 0 && !fromTablet && (
          <SLearnMoreTextWrapper
            initial={{ translateX: '0px' }}
            animate={{ translateX: '7px' }}
            transition={{
              repeatType: 'reverse',
              repeat: Infinity,
              delay: 5,
              duration: 0.5,
              repeatDelay: 1,
            }}
          >
            <BodyText textType="bodyText" color="charcoal70" fontWeight="R" size="N" font="Poppins">
              {t('preRegOnboarding.Swipe')}
            </BodyText>
          </SLearnMoreTextWrapper>
        )}
        <SActions style={{ padding: `0 calc(${slidesCenterPadding} + ${fromTablet ? 12 : 6}px)` }}>
          <Button size="large" preset="blue-filled" onClick={() => navigate(ROUTES.registration.path)}>
            {t('preRegOnboarding.Continue')}
          </Button>
          {currentSlide !== 2 && !fromTablet && (
            <TextButton fontWeight="M" size="N" font="DM Sans" linkTo={ROUTES.registration.path}>
              {t('preRegOnboarding.Skip')}
            </TextButton>
          )}
        </SActions>
      </div>
      <PageFooter type="sutton" />
    </SOnboardingPageLayout>
  );
};
