import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import styled from 'styled-components';
import { getColor } from 'utils/helpers/styleHelpers';

export const SCustomModal = styled(CustomModal)`
  .ant-modal-content {
    height: 90vh;
    overflow: hidden;
    overflow-y: auto;
  }
  .modal-header {
    background-color: ${getColor('charcoal5')};
    padding: 32px 48px 32px 20px;
  }

  .modal-content {
    padding: 24px 8px;
  }

  .container {
    display: flex;
    flex-wrap: nowrap;

    font-weight: 500;
    text-align: left;
    line-height: 16px;
    font-size: 12px;

    &.table-header {
      background-color: ${getColor('cream70')};
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      border-radius: 8px;
    }

    .item {
      flex: 1 0;
      word-wrap: break-word;
      padding: 16px 0 16px 16px;
    }
  }

  .divider {
    width: 95%;
    height: 2px;
    background-color: ${getColor('charcoal10')};
    margin: 0 auto;
  }
`;

export const SCustomCard = styled(CustomCard)`
  padding: 16px;
  margin: 0 16px 20px;
  background-color: ${getColor('blue5')};
  border-radius: 16px;
`;
