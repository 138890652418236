import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'utils/hooks/store';
import { useUserStatus } from 'utils/hooks/useUserStatus';
import { BodyText } from 'components/general/Typography';
import { TProfileEditSheetType, setShowAdditionalInformationModal } from 'store/ui.slice';
import { selectCurrentAuthState } from 'store/user/authentication.slice';
import { Icon } from 'components/general/Icon/Icon';
import { useCancelChangeAddressRequestsMutation, useGetUserProfileDataMutation } from 'store/user/users.api';
import { Loader } from 'components/general/Loader/Loader';
import { useToggle } from 'utils/hooks/useToggle';
import { CancelAddressChangeModal } from 'components/general/Modals/CancelAddressChangeModal/CancelAddressChangeModal';
import clsx from 'clsx';
import { Tooltip } from 'views/Profile/ProfilePage/Tooltip/Tooltip';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { EditUsername } from 'views/Profile/ProfilePage/ProfileEditInline/EditUsername';
import { EditName } from 'views/Profile/ProfilePage/ProfileEditInline/EditName';
import { EditPhoneNumber } from 'views/Profile/ProfilePage/ProfileEditInline/EditPhoneNumber';
import { EditEmail } from 'views/Profile/ProfilePage/ProfileEditInline/EditEmail/EditEmail';
import { TextButton } from 'components/general/TextButton/TextButton';
import { SIcon, SData, SUnderReviewLabel, SLabelText, SCustomRow } from './DataItem.styles';

interface IDataItem {
  label: string;
  value: string;
  isLast?: boolean;
  isInline?: boolean;
  sheetType?: TProfileEditSheetType;
  showInfoIcon?: boolean;
  showEditIcon?: boolean;
  setSheetType?: ({ sheetType, mode }: { sheetType: string; mode: string }) => void;
  showSheet?: () => void;
}

const SBodyTextExtraStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row-reverse',
};

export const DataItem = ({ label, value, isLast = false, isInline = false, sheetType, showInfoIcon = false, showEditIcon = true, setSheetType, showSheet }: IDataItem) => {
  const { t } = useTranslation('profile');
  const cancelAddressChangeModal = useToggle();
  const { user } = useSelector(selectCurrentAuthState);
  const hasChangeAddressPendingRequest = user?.pendingRequestNames?.includes('IsAddressChangeRequest');
  const { isProspect } = useUserStatus();
  const { isDesktopSize, isMobileSmallSize } = useDeviceDimension();
  const [cancelRequest, cancelRequestResult] = useCancelChangeAddressRequestsMutation();
  const [getUserProfileData, getUserProfileDataResult] = useGetUserProfileDataMutation();
  const [isOpenInfoTooltip, setOpenInfoTooltip] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const hasAdditionalText = sheetType === 'address' && !isProspect;
  const trimmedValue = value.trim();

  const dispatch = useAppDispatch();
  const handleIconClick = (mode: string | undefined = 'edit') => {
    if (isInline) {
      setIsEdit(true);
    } else {
      setSheetType?.({ sheetType: sheetType || '', mode });
      showSheet?.();
    }
  };

  const handleCancelRequest = async () => {
    await cancelRequest();
    await getUserProfileData(user?.userId);
    cancelAddressChangeModal.hide();
  };

  const hideEdit = () => setIsEdit(false);

  const chosenIcon = hasChangeAddressPendingRequest ? (
    <div className="cancel-request" onClick={() => cancelAddressChangeModal.show()}>
      {t(`profile.CancelRequest`)}
    </div>
  ) : (
    <SIcon color="blue" cursorPointer onClick={() => handleIconClick()} />
  );

  const handleAdditionalInfoIconClick = () => {
    if (isDesktopSize) {
      setOpenInfoTooltip(true);
    } else {
      setOpenInfoTooltip(false);

      dispatch(
        setShowAdditionalInformationModal({
          displayAdditionalInformationModal: true,
          additionalInformationModalType: 'preferredName',
        })
      );
    }
  };

  const circleInfoIcon = showInfoIcon ? <Icon name="circleInfo" size="smaller" color="blue" cursorPointer onClick={handleAdditionalInfoIconClick} marginLeft={14} /> : undefined;

  const InfoTooltip = useMemo(
    () => (
      <Tooltip open={isOpenInfoTooltip} onClose={() => setOpenInfoTooltip(false)}>
        <BodyText size="T" fontWeight="B" marginBottom={4} className="tooltip-header-text">
          {t('profile.WhatIsPreferredName')}
        </BodyText>

        <BodyText size="T" color="charcoal70" className="tooltip-body-text">
          {t('profile.YourPreferredNameIsTheNameWeWillUse')}
        </BodyText>
      </Tooltip>
    ),
    [isOpenInfoTooltip, t]
  );

  return (
    <>
      {cancelRequestResult.isLoading || (getUserProfileDataResult.isLoading && <Loader />)}
      <SCustomRow marginBottom={isLast ? 0 : 18} width="100%" className={clsx('data-item', hasAdditionalText && 'has-additional-text')}>
        {isEdit && sheetType === 'username' && <EditUsername onClose={hideEdit} />}
        {isEdit && sheetType === 'preferredName' && <EditName onClose={hideEdit} />}
        {isEdit && sheetType === 'phoneNumber' && <EditPhoneNumber onClose={hideEdit} />}
        {isEdit && sheetType === 'email' && <EditEmail onClose={hideEdit} />}

        {!isEdit && (
          <>
            <SData>
              <div className="data-item-header">
                {showInfoIcon && InfoTooltip}
                <SLabelText textType="bodyText" font="DM Sans" fontWeight="R" color="charcoal70" size="M" icon={circleInfoIcon} extraStyles={SBodyTextExtraStyles}>
                  {t(`profile.${label}`)}
                </SLabelText>
                {sheetType === 'address' && hasChangeAddressPendingRequest && <SUnderReviewLabel size="T">{t(`profile.UnderReview`)}</SUnderReviewLabel>}
              </div>
              <BodyText textType="bodyText" font="DM Sans" fontWeight="M" color="charcoal" size="M" className="data-item-value">
                <div className={clsx({ pending: sheetType === 'address' && hasChangeAddressPendingRequest })}>{trimmedValue || '-'}</div>
                {sheetType === 'address' && showEditIcon && trimmedValue && chosenIcon}
              </BodyText>
            </SData>

            {sheetType !== 'address' && showEditIcon && trimmedValue && <SIcon color="blue" cursorPointer onClick={() => handleIconClick()} />}

            {!trimmedValue && (
              <TextButton className="button-add" onClick={() => handleIconClick('add')} size="N" iconName="circlePlus">
                {t('profile.Add')}
              </TextButton>
            )}
          </>
        )}
      </SCustomRow>

      {hasAdditionalText && !isMobileSmallSize && (
        <BodyText textType="bodyText" size="T" fontWeight="R" color="charcoal70" marginBottom={24} marginRight={72} lineHeight="16px" className="additional-text">
          {t(`profile.AdditionalDocumentsAreRequired`)}
        </BodyText>
      )}

      <CancelAddressChangeModal open={cancelAddressChangeModal.isActive} onClose={cancelAddressChangeModal.hide} handleCancelRequest={handleCancelRequest} />
    </>
  );
};
