import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { images } from 'assets';
import { selectSystemProperties } from 'store/user/authentication.slice';
import { setShowNeedSupportModal } from 'store/ui.slice';
import { formatPhone } from 'utils/helpers/phone';
import { Title, BodyText } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { CustomDetails } from 'components/theme/CustomDetails/CustomDetails';
import { moneyTransfer, security, noFee, earnings } from 'assets/icons';
import { useLocation } from 'react-router-dom';
import { SLink } from 'views/OpenCashAccount/MyInfo/MyDetailsPage/MyDetailsPage.styles';
import { FeeScheduleModal } from './FeeScheduleModal/FeeScheduleModal';
import { SStarterPage, SWrapper } from './StarterPage.styles';

interface ICashAccountStarterPage {
  handleContinueClick: (alreadyHasCash: boolean) => void;
  handleFeeScheduleClick?: () => void;
  hide?: () => void;
  isActive?: boolean;
  isStarterPage: boolean;
}

export const CashAccountOpeningPage = ({ handleContinueClick, handleFeeScheduleClick, isActive, hide, isStarterPage }: ICashAccountStarterPage) => {
  const { t } = useTranslation('starter');
  const { state } = useLocation();
  const isSecondaryCash = state?.alreadyHasCash;

  const dispatch = useDispatch();

  const handleOpenNeedSupportModal = () => {
    dispatch(setShowNeedSupportModal(true));
  };

  const { supportPhoneNumber } = useSelector(selectSystemProperties);
  const supportTelVal = useMemo(() => `tel:${formatPhone(supportPhoneNumber)}`, [supportPhoneNumber]);

  return (
    <>
      <SStarterPage isStarterPage={!isStarterPage}>
        <img style={{ width: isSecondaryCash ? '180px' : 'auto' }} src={isSecondaryCash ? images.addNewCashAccount : images.cashAccountStarted} alt="depositAccountsModal" />
        <Title fontWeight="M" size="M" font="Poppins" textAlign="start" color="charcoal" marginBottom={15} className="open-account-title">
          {isSecondaryCash ? t('starter.headingNewCash') : t('starter.heading')}
        </Title>
        <BodyText textType="bodyText" font="DM Sans" fontWeight="R" size="N" color="charcoal70" lineHeight={1.5} marginBottom={16} className="open-account-subtitle">
          {isSecondaryCash ? t('starter.welcomeNewCash') : t('starter.welcome')}
        </BodyText>
        {isSecondaryCash && (
          <Button preset="blue-filled" size="large" onClick={() => handleContinueClick(isSecondaryCash)} marginTop={32} marginBottom={32}>
            {t('starter.continue')}
          </Button>
        )}
        <section className="benefits">
          <div className={`list ${!isStarterPage ? 'listColoredBorder' : ''}`}>
            <SWrapper>
              <CustomDetails summary={t('starter.benefits.convenienceAndFlexibility.summary')} summaryIconURL={moneyTransfer}>
                <ul className="benefit">
                  <li>
                    <span>{t('starter.benefits.convenienceAndFlexibility.card')}</span>
                  </li>
                  <li>
                    <span>{t('starter.benefits.convenienceAndFlexibility.atms')}</span>
                  </li>
                  <li>
                    <span>{t('starter.benefits.convenienceAndFlexibility.pennyJar')}</span>
                  </li>
                </ul>
              </CustomDetails>
            </SWrapper>
            <SWrapper>
              <CustomDetails summary={t('starter.benefits.securityAndControl.summary')} summaryIconURL={security}>
                <ul className="benefit">
                  <li>
                    <span>{t('starter.benefits.securityAndControl.fdic')}</span>
                  </li>
                  <li>
                    <span>{t('starter.benefits.securityAndControl.bills')}</span>
                  </li>
                  <li>
                    <span>{t('starter.benefits.securityAndControl.alerts')}</span>
                  </li>
                  <li>
                    <span>{t('starter.benefits.securityAndControl.support')}</span>
                  </li>
                </ul>
              </CustomDetails>
            </SWrapper>
            <SWrapper>
              <CustomDetails summary={t('starter.benefits.noSurpriseFees.summary')} summaryIconURL={noFee}>
                <ul className="benefit">
                  <li>
                    <span>{t('starter.benefits.noSurpriseFees.balance')}</span>
                  </li>
                  <li>
                    <span>{t('starter.benefits.noSurpriseFees.expenditures')}</span>
                  </li>
                  <li>
                    <span>{t('starter.benefits.noSurpriseFees.atm')}</span>
                  </li>
                  <li>
                    {isStarterPage ? (
                      <>
                        <span>{t('starter.benefits.noSurpriseFees.extra')}</span>
                        <a onClick={handleFeeScheduleClick}>{t('starter.benefits.noSurpriseFees.feeSchedule')}</a>
                      </>
                    ) : (
                      <span>{t('starter.benefits.noSurpriseFees.alternative')}</span>
                    )}
                  </li>
                </ul>
              </CustomDetails>
            </SWrapper>
            <SWrapper>
              <CustomDetails summary={t('starter.benefits.planningAndBudgetingTools.summary')} summaryIconURL={earnings}>
                <ul className="benefit">
                  <li>
                    <span>{t('starter.benefits.planningAndBudgetingTools.money')}</span>
                  </li>
                  <li>
                    <span>{t('starter.benefits.planningAndBudgetingTools.accounts.summary')}</span>
                    <ul>
                      <li>
                        <span>{t('starter.benefits.planningAndBudgetingTools.accounts.needs')}</span>
                      </li>
                      <li>
                        <span>{t('starter.benefits.planningAndBudgetingTools.accounts.goals')}</span>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span>{t('starter.benefits.planningAndBudgetingTools.more')}</span>
                  </li>
                </ul>
              </CustomDetails>
            </SWrapper>
          </div>
        </section>
        {!isSecondaryCash && (
          <CustomRow width="100%" justifyContent="center">
            <Button preset="blue-filled" width="150px" onClick={() => handleContinueClick(isSecondaryCash)}>
              {t('starter.continue')}
            </Button>
          </CustomRow>
        )}
        <section className="help" onClick={handleOpenNeedSupportModal}>
          <BodyText textType="bodyText" color="charcoal" fontWeight="B" size="L" justifyContent="center" marginBottom={17}>
            {t('accountOpening.Need help?', { ns: 'cashAccountOpening' })}
          </BodyText>
          <address>
            <a href={supportTelVal}>{supportPhoneNumber}</a>
          </address>
        </section>
        <footer>
          <BodyText textType="bodyText" color="charcoal70" size="T" fontWeight="R" textAlign="start">
            <Trans i18nKey="starter.legal" components={{ Link: <SLink href="http://www.fdic.gov" /> }} target="_blank" />
          </BodyText>
        </footer>
      </SStarterPage>
      {isActive && hide ? <FeeScheduleModal isActive={isActive} hide={hide} /> : null}
    </>
  );
};
