import styled, { css } from 'styled-components';
import bgCreamGradient from 'assets/images/bgCreamGradient.svg';
import { mediaUpTo, mediaFrom, mediaUpToHeight, getColor } from 'utils/helpers/styleHelpers';

export const SLayout = styled.div<{ bgFooterImage?: string }>`
  width: 100%;
  padding: 0 18px;

  .link {
    color: ${getColor('blue')};
    text-decoration: underline;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
  }

  .disclosure-container {
    padding-bottom: 40px;
  }

  ${mediaFrom(
    'tablet',
    css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: url(${bgCreamGradient}) no-repeat center 10% / 100%;

      .prep-title * {
        text-align: center;
        font-size: 40px;
        font-weight: 500;
        margin-bottom: 18px;
      }

      .prep-page-subtitle * {
        font-size: 18px;
      }

      .list-title {
        margin-top: 0;
      }

      .card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        max-width: 732px;
        width: 100%;
        background: ${getColor('white')};
        padding: 24px;
        margin: 28px 0 40px 0;
        border-radius: 20px;
      }

      .information {
        max-width: 732px;
        width: 100%;
        margin-bottom: 20px;
      }

      .disclosure-container {
        width: 100%;
        margin-top: 32px;

        .disclosure {
          width: 75%;
          padding: 84px 0 84px 128px;
        }
      }

      .title-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 470px;

        .title {
          padding-right: 0px;
        }

        .heading {
          justify-content: center;
          text-align: center;
        }
      }
    `
  )}

  ${mediaUpTo(
    'tablet',
    css`
      .custom-title-text {
        font-size: 28px;
      }
      .custom-text-inner,
      .list-item-text {
        font-size: 14px;
      }
      .list-title * {
        font-size: 16px;
      }
    `
  )}

  ${mediaUpTo(
    'tablet',
    css`
      .custom-title-text {
        font-size: 20px;
      }
      .custom-text-inner,
      .list-item-text {
        font-size: 12px;
      }
      .list-item {
        &-text {
          & li {
            margin-left: 33px;
          }
          & + .body-text {
            margin-top: 21px;
            & .custom-text-inner {
              line-height: 1.5;
            }
            ${mediaUpToHeight(
              815,
              css`
                margin-top: 35px;
              `
            )}
          }
        }
        .icon-circleTick {
          width: 20px;
          height: 20px;
        }
      }
    `
  )}
`;

export const SListItemText = styled.li`
  display: flex;
  font-family: DM Sans;
  padding-right: 30px;
  margin-left: 43px;
  margin-top: 8px;
  line-height: 1.3;
  color: ${getColor('charcoal70')};

  &::before {
    content: '\u2022';
    color: blue;
    display: inline-block;
    margin-right: 13px;
  }

  ${mediaUpTo(
    380,
    css`
      ${mediaUpToHeight(
        680,
        css`
          & {
            font-size: 12px;
          }
        `
      )}
    `
  )}
`;
