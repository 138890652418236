import styled, { css } from 'styled-components/macro';
import { getColor, mediaUpTo } from 'utils/helpers/styleHelpers';

export const STitleContainer = styled.div`
  display: flex;
  margin: 24px 0 16px;
  justify-content: space-between;

  ${mediaUpTo(
    'mobile',
    css`
      .transaction-sign-internal {
        width: 32px;
        height: 32px;

        .svg-icon {
          width: 16px;
          height: 16px;
        }
      }
    `
  )};
`;

export const STrnBlock = styled.div`
  position: relative;
  border: 2px solid ${getColor('charcoal5')};
  border-radius: 20px;
  margin: 16px 0;
  padding: 24px;
`;

export const SMapAddress = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .address-second-line {
    margin-top: 3px;
  }
`;

export const SMapWrapper = styled.div`
  position: relative;
  height: 144px;
  margin: -24px -24px 20px -24px;

  .map-img {
    width: 100%;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    object-fit: cover;
    height: 144px;
  }

  .map-pin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const STransactionContainer = styled.div`
  .link {
    color: ${getColor('blue')};
  }

  .category-group {
    display: flex;
    margin-left: 4px;
  }

  .category-label {
    flex-shrink: 0;
  }

  .category-name {
    max-width: 188px;
  }

  .category-icon {
    flex-shrink: 0;
    margin-left: 8px;
    align-self: center;
  }
`;
