import { format, intervalToDuration, parse, isValid } from 'date-fns';
import { es } from 'date-fns/locale';
import { capitalizeWords } from './string';

export const DATE_FORMAT = 'MM/dd/yyyy';

export const parseDate = (dateStr: string) => parse(dateStr, DATE_FORMAT, new Date());
export const formatDate = (val: Date) => format(val, DATE_FORMAT);
export const getValidDate = (dateString: string): string => {
  const regex = /^(\d{4}-\d{2}-\d{2})/;
  const matches = dateString.match(regex);
  return matches ? matches[1] : '';
};

export const formatLocaleDate = (val: Date, dateFormat: string, locale: string) => {
  const localeParams = locale.split('-')[0] === 'es' ? { locale: es } : {};
  return capitalizeWords(format(val, dateFormat, localeParams));
};

// eslint-disable-next-line consistent-return
export const isUnder18 = (dob: Date) => {
  if (dob && isValid(dob)) {
    const { years } = intervalToDuration({ start: dob, end: new Date() });
    return !!years && years < 18;
  }
};

export const dateOfBirthFormatter = (dateOfBirth: string) => dateOfBirth.split('/').map((date) => Number(date));
