import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { Loader } from 'components/general/Loader/Loader';
import { Icon } from 'components/general/Icon/Icon';
import { Checkbox } from 'components/general/Checkbox/Checkbox';
import { BodyText, Title } from 'components/general/Typography';
import { SAlertSourceItem, SCard } from 'views/NotificationsAndAlerts/NotificationsAndAlertsPage.styles';
import { useGetUserProfileDataMutation } from 'store/user/users.api';
import { selectCurrentUser, selectPolicies } from 'store/user/authentication.slice';
import { securityNotificationSettings } from 'store/user/alerts/alerts.slice';
import { IBaseSelectOption } from 'components/general/BaseSelect/BaseSelect.types';
import { Chip } from 'components/general/Chip/Chip';
import { useSubscriptions } from 'utils/hooks/useSubscriptions';
import { useAccounts } from 'utils/hooks/useAccounts';
import { IAlertItem } from 'views/NotificationsAndAlerts/NotificationsAndAlertsPage.types';
import { areEqualUsers, USER_PROFILE_IDS } from 'vars/const/USER_PROFILE_IDS';
import { Button } from 'components/theme/Button/Button';
import { options } from './constants';
import { useAlertInput } from './helpers';
import { BalanceSetting } from './BalanceSetting';
import { WaitlistSetting } from './WaitlistSetting';
import { SLayout } from './NotificationsTab.styles';

interface IChangedBalanceAlertItem {
  alert: IAlertItem;
  checked: boolean;
}

export const NotificationsTab = () => {
  const { t } = useTranslation('notificationsAlerts');
  const location = useLocation();
  const state = location.state;
  const isFromAccountInfo = location.state?.isFromAccountInfo || location.pathname.includes('account_information');

  const { balanceSettings, waitlistSettings, getUserAllAlerts, getUserAllAlertsResult, editAlerts, updateUserAllAlerts, updateUserAllAlertsResult, getUserPropertiesResult } = useSubscriptions();
  const { hasAccount } = useAlertInput();
  const [getUserProfileData, getUserProfileDataResult] = useGetUserProfileDataMutation();
  const securitySettings = useSelector(securityNotificationSettings);
  const policies = useSelector(selectPolicies);
  const user = useSelector(selectCurrentUser);
  const [alertsEditable, setAlertsEditable] = useState(Boolean(user?.isNotifyByEmail) || Boolean(user?.isNotifyBySms) || Boolean(user?.isNotifyByPush));
  const isClient = areEqualUsers(user?.systemProfileId ?? '', USER_PROFILE_IDS.CLIENT_ccc);
  const { internalAccountsGroups } = useAccounts();
  const [activeAccountId, setActiveAccountId] = useState(internalAccountsGroups?.[0]?.cash?.accountId ?? null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [changedBalanceAlerts, setChangedBalanceAlerts] = useState<IChangedBalanceAlertItem[]>([]);

  const renderBalanceNotifications = useMemo(() => {
    return activeAccountId ? balanceSettings?.filter((item) => item.accountId === activeAccountId) : balanceSettings;
  }, [activeAccountId, balanceSettings]);

  const memoizedFilteredUpdatedAlerts = useMemo(() => {
    if (balanceSettings) {
      const filteredUpdatedAlerts: IAlertItem[] = balanceSettings.filter((alert: IAlertItem) => !alert.isReadOnly && !(!alert.isNotifyBySms && !alert.isNotifyByEmail));
      return filteredUpdatedAlerts;
    }
    return balanceSettings;
  }, [balanceSettings]);

  const memoizedSecuritySettings = useMemo(
    () =>
      securitySettings?.map((alert: IAlertItem) => (
        <div className="settings-security-outer" style={{ marginBottom: '20px' }} key={alert.alertId}>
          <div className="settings-security-inner" style={{ marginBottom: '14px' }}>
            <Checkbox checked disabled />
            <BodyText textType="bodyText" display="flex" textAlign="center" size="N" fontWeight="R" color="charcoal40" font="DM Sans" paddingTop={4}>
              {alert.title}
            </BodyText>
          </div>
        </div>
      )),
    [securitySettings]
  );

  const toggleCheckedEmail = (isNotifyByEmail: boolean) => {
    const updatedSettings = {
      isNotifyByEmail,
    };

    if (memoizedFilteredUpdatedAlerts) {
      const updatedAlerts: IAlertItem[] = memoizedFilteredUpdatedAlerts.map((alert: IAlertItem) => ({
        ...alert,
        ...updatedSettings,
      }));

      updateUserAllAlerts(updatedAlerts);
    }

    if (!alertsEditable && updatedSettings.isNotifyByEmail) {
      setAlertsEditable(true);
    }

    if (alertsEditable && !updatedSettings.isNotifyByEmail && !user?.isNotifyBySms) {
      setAlertsEditable(false);
    }
    editAlerts(updatedSettings);
  };

  const toggleCheckedSms = (isNotifyBySms: boolean) => {
    const updatedSettings = {
      isNotifyBySms,
    };

    if (memoizedFilteredUpdatedAlerts) {
      const updatedAlerts: IAlertItem[] = memoizedFilteredUpdatedAlerts.map((alert: IAlertItem) => ({
        ...alert,
        ...updatedSettings,
      }));

      updateUserAllAlerts(updatedAlerts);
    }
    editAlerts(updatedSettings);

    if (!alertsEditable && updatedSettings.isNotifyBySms) {
      setAlertsEditable(true);
    }

    if (alertsEditable && !updatedSettings.isNotifyBySms && !user?.isNotifyByEmail) {
      setAlertsEditable(false);
    }
  };

  const toggleCheckedPush = (isNotifyByPush: boolean) => {
    const updatedSettings = {
      isNotifyByPush,
    };

    if (memoizedFilteredUpdatedAlerts) {
      const updatedAlerts: IAlertItem[] = memoizedFilteredUpdatedAlerts.map((alert: IAlertItem) => ({
        ...alert,
        ...updatedSettings,
      }));

      updateUserAllAlerts(updatedAlerts);
    }
    editAlerts(updatedSettings);

    if (!alertsEditable && updatedSettings.isNotifyByPush) {
      setAlertsEditable(true);
    }

    if (alertsEditable && !user?.isNotifyBySms && !user?.isNotifyByEmail && !updatedSettings?.isNotifyByPush) {
      setAlertsEditable(false);
    }
  };

  const onNotificationToggle = (checked: boolean, alert: IAlertItem) => {
    updateUserAllAlerts([
      {
        ...alert,
        isNotifyBySms: checked ? user?.isNotifyBySms : false,
        isNotifyByEmail: checked ? user?.isNotifyByEmail : false,
        isNotifyByPush: checked ? user?.isNotifyByPush : false,
      },
    ]);
  };

  useEffect(() => {
    if (user) {
      const { userId } = user;
      getUserProfileData({ userId });
      getUserAllAlerts({});
    }
  }, []);

  const selectOptions = useMemo(
    () =>
      options.map((option) => ({
        value: option,
        label: t(option),
      })),
    [t]
  );

  const handleAccountClick = (accountId: string) => {
    if (accountId !== activeAccountId) setChangedBalanceAlerts([]);

    setActiveAccountId(accountId);

    if (containerRef.current) {
      const parentElement = containerRef.current;
      const parentElementWidth = parentElement?.clientWidth;
      const children = Array.from(containerRef.current.children) as HTMLElement[];
      const currentTarget = children.find((child) => !child.classList.contains('active')) as HTMLElement | undefined;

      if (currentTarget) {
        const accountItemWidth = currentTarget.clientWidth;
        const elementLeft = currentTarget.offsetLeft;
        const scrollLeft = Math.max(0, elementLeft - (Number(parentElementWidth) - accountItemWidth) / 2);

        parentElement?.scrollTo({
          left: scrollLeft,
          behavior: 'smooth',
        });
      }
    }

    getUserAllAlerts({});
  };

  /* Balances and transactions changes handling functions */
  const handleBalancesChange = (checked: boolean, alert: IAlertItem) => {
    const index = changedBalanceAlerts.findIndex((item: IChangedBalanceAlertItem) => item.alert.alertId === alert.alertId);

    if (index !== -1) {
      const updatedItems = [...changedBalanceAlerts];
      updatedItems[index] = { ...updatedItems[index], checked };
      setChangedBalanceAlerts(updatedItems);
    } else {
      setChangedBalanceAlerts([...changedBalanceAlerts, { checked, alert }]);
    }
  };

  const handleValueApply = (alert: IAlertItem, newValue: unknown | string, isSelector: boolean) => {
    const index = changedBalanceAlerts.findIndex((item: IChangedBalanceAlertItem) => item.alert.alertId === alert.alertId);

    const updatedAlert: any = {
      ...alert,
      value: isSelector ? (newValue as IBaseSelectOption).value : newValue,
      parameterValue: isSelector ? (newValue as IBaseSelectOption).value : newValue,
    };

    if (index !== -1) {
      const updatedItems = [...changedBalanceAlerts];
      updatedItems[index] = { ...updatedItems[index], alert: updatedAlert };
      setChangedBalanceAlerts(updatedItems);
    } else {
      setChangedBalanceAlerts([...changedBalanceAlerts, { checked: true, alert: updatedAlert }]);
    }
  };

  const handleSaveBalances = async () => {
    await Promise.all(
      changedBalanceAlerts.map((item: IChangedBalanceAlertItem) =>
        updateUserAllAlerts([
          {
            ...item.alert,
            isNotifyBySms: item.checked ? user?.isNotifyBySms : false,
            isNotifyByEmail: item.checked ? user?.isNotifyByEmail : false,
            isNotifyByPush: item.checked ? user?.isNotifyByPush : false,
          },
        ])
      )
    );

    setChangedBalanceAlerts([]);
  };

  useEffect(() => {
    if (state?.accountId) {
      setActiveAccountId(state.accountId);
    }
    if (isFromAccountInfo) {
      const selectedId = location.pathname.split('/')[3];
      setActiveAccountId(selectedId);
    }
  }, [state?.accountId, location.pathname]);

  return (
    <SLayout>
      {isFromAccountInfo ? (
        <>
          <Title textAlign="start" size="sS" marginBottom="spacing-med" fontWeight="SB">
            {t('SetYourNotificationPreferences')}
          </Title>
          {getUserPropertiesResult.isFetching ? (
            <Loader />
          ) : (
            <CustomCard padding="0 0 32px 0">
              <CustomCard className="notifications-wrapper">
                <BodyText size="M" fontWeight="SB" color="charcoal40" marginBottom={32}>
                  {t('NotifyMeWhen')}
                </BodyText>
                {renderBalanceNotifications?.map((alert) => (
                  <BalanceSetting alert={alert} alertsEditable={alertsEditable} hasAccount={hasAccount} selectOptions={selectOptions} onCheck={handleBalancesChange} onApply={handleValueApply} />
                ))}
              </CustomCard>
              {!getUserAllAlertsResult.isFetching && (
                <div className="settings-save-btn">
                  <Button onClick={handleSaveBalances} preset="blue-filled" disabled={changedBalanceAlerts.length === 0}>
                    {t('SaveChanges')}
                  </Button>
                </div>
              )}
            </CustomCard>
          )}
        </>
      ) : (
        <>
          <Title textAlign="start" size="sS" marginBottom="spacing-med" fontWeight="SB">
            {t('AlertDelivery')}
          </Title>
          <CustomCard padding="32px 24px 16px">
            <BodyText textType="bodyText" display="flex" size="N" fontWeight="R" color="charcoal70" marginBottom="spacing-x-large">
              {t('SelectHowToReceiveBalanceAndTransactionNotifications')}
            </BodyText>
            <div className="settings-alert-source-items">
              {getUserProfileDataResult.isLoading && <Loader size="small" />}
              <SAlertSourceItem onClick={() => toggleCheckedEmail(!user?.isNotifyByEmail)}>
                <Icon name="envelope" color={user?.isNotifyByEmail ? 'blue' : 'charcoal20'} size="xl" marginRight={8} marginBottom={13} cursorPointer />
                <BodyText textType="bodyText" display="flex" textAlign="center" size="M" fontWeight="R" color="charcoal" marginRight={8} marginBottom={8} cursorPointer>
                  {t('Email')}
                </BodyText>
                <Checkbox checked={user?.isNotifyByEmail} />
              </SAlertSourceItem>
              {policies?.PushNotificationsEnabled && (
                <SAlertSourceItem onClick={() => toggleCheckedPush(!user?.isNotifyByPush)}>
                  <Icon name="mobile" color={user?.isNotifyByPush ? 'blue' : 'charcoal20'} size="xl" marginRight={8} marginBottom={13} cursorPointer />
                  <BodyText textType="bodyText" display="flex" textAlign="center" size="M" fontWeight="R" color="charcoal" marginRight={8} marginBottom={8} cursorPointer>
                    {t('InApp')}
                  </BodyText>
                  <Checkbox checked={user?.isNotifyByPush} />
                </SAlertSourceItem>
              )}
              <SAlertSourceItem onClick={() => toggleCheckedSms(!user?.isNotifyBySms)}>
                <Icon name="comment" color={user?.isNotifyBySms ? 'blue' : 'charcoal20'} size="xl" marginRight={8} marginBottom={13} cursorPointer />
                <BodyText textType="bodyText" display="flex" textAlign="center" size="M" fontWeight="R" color="charcoal" marginRight={8} marginBottom={8} cursorPointer>
                  {t('Text')}
                </BodyText>
                <Checkbox checked={user?.isNotifyBySms} />
              </SAlertSourceItem>
            </div>
          </CustomCard>

          <BodyText textType="bodyText" size="T" fontWeight="R" color="charcoal70" marginTop="spacing-med" marginBottom="spacing-x-large">
            {t('MessageAndData')}
          </BodyText>

          {isClient ? (
            <>
              <Title textAlign="start" size="sS" marginBottom="spacing-med" fontWeight="SB">
                {t('Balances&Transactions')}
              </Title>

              <CustomCard className="accounts-switcher">
                <div ref={containerRef}>
                  {internalAccountsGroups.map((group) => (
                    <Chip
                      isActive={group.cash?.accountId === activeAccountId}
                      type="light"
                      onClick={() => handleAccountClick(group.cash?.accountId ?? '')}
                      iconName={group.cash?.isJoint ? 'refer' : 'cash'}
                      key={group.cash?.accountId}
                    >
                      <div className="acc-name">{group.cash?.shortName ? `${t(group.cash?.shortName)} (${group.cash.lastFourDigits})` : group.cash?.maskedAccountId}</div>
                    </Chip>
                  ))}
                </div>
              </CustomCard>

              <CustomCard extraStyles={{ minHeight: '200px', borderTopLeftRadius: 0, borderTopRightRadius: 0, marginTop: '2px' }}>
                {(getUserAllAlertsResult.isFetching || updateUserAllAlertsResult.isLoading) && <Loader size="small" />}
                {!hasAccount && (
                  <BodyText textType="errorText" color="red" fontWeight="R" size="T" marginBottom="spacing-small" icon={<Icon name="triangleWarning" color="red40" size="xs" />}>
                    {t('DoNotHaveAccountSetUpNotifications')}
                  </BodyText>
                )}

                {renderBalanceNotifications?.map((alert) => (
                  <BalanceSetting alert={alert} alertsEditable={alertsEditable} hasAccount={hasAccount} selectOptions={selectOptions} onCheck={handleBalancesChange} onApply={handleValueApply} />
                ))}

                {!getUserAllAlertsResult.isFetching && (
                  <div className="balances-save-btn-container">
                    <Button onClick={handleSaveBalances} preset="blue-filled" disabled={changedBalanceAlerts.length === 0}>
                      {t('Save')}
                    </Button>
                  </div>
                )}
              </CustomCard>

              <Title textAlign="start" size="sS" marginBottom={20} fontWeight="SB" marginTop="spacing-x-large">
                {t('SecurityAlerts')}
              </Title>
              <SCard marginTop={10} marginBottom={64} extraStyles={{ minHeight: '200px', paddingBottom: '8px' }}>
                <div style={{ marginBottom: '20px' }}>
                  <BodyText
                    textType="bodyText"
                    icon={<Icon name="triangleWarning" color="red70" size="smaller" />}
                    display="flex"
                    justify-content="flex-start"
                    size="N"
                    fontWeight="R"
                    color="charcoal70"
                  >
                    {t('YouCanNotDisableTheseNotifications')}
                  </BodyText>
                </div>

                {securitySettings ? memoizedSecuritySettings : <Loader size="small" inline />}
              </SCard>
            </>
          ) : (
            <>
              <Title textAlign="start" size="sL" marginBottom="spacing-x-large" fontWeight="M">
                {t('WaitlistNotifications')}
              </Title>

              <CustomCard marginTop={32} extraStyles={{ minHeight: '200px' }} marginBottom={30}>
                {waitlistSettings?.map((alert) => (
                  <WaitlistSetting alert={alert} alertsEditable={alertsEditable} hasAccount={hasAccount} onCheck={onNotificationToggle} />
                ))}
              </CustomCard>
            </>
          )}
        </>
      )}
    </SLayout>
  );
};
