import React, { useEffect, useState } from 'react';
import { Icon } from 'components/general/Icon/Icon';
import { Checkbox } from 'components/general/Checkbox/Checkbox';
import { BodyText } from 'components/general/Typography';
import { useToggle } from 'utils/hooks/useToggle';
import { IAlertItem } from 'views/NotificationsAndAlerts/NotificationsAndAlertsPage.types';
import { CurrentAvailableBalanceSheet } from 'views/NotificationsAndAlerts/CurrentAvailableBalanceSheet/CurrentAvailableBalanceSheet';
import { useTranslation } from 'react-i18next';
import { IBaseSelectOption } from 'views/NotificationsAndAlerts/CurrentAvailableBalanceSheet/CurrentAvailableBalanceSheet.types';
import { AlertsModal } from 'views/NotificationsAndAlerts/AlertsModal/AlertsModal';

interface IBalanceSetting {
  alert: IAlertItem;
  alertsEditable: boolean;
  hasAccount: boolean;
  selectOptions: { value: string; label: string }[];
  onCheck: (checked: boolean, alert: IAlertItem) => void;
  onApply: (alert: IAlertItem, newValue: unknown, isSelector: boolean) => void;
}

export const BalanceSetting = ({ alert, selectOptions, alertsEditable, hasAccount, onCheck, onApply }: IBalanceSetting) => {
  const { t } = useTranslation('notificationsAlerts');
  const isActiveAlert = (alert.isNotifyBySms || alert.isNotifyByEmail) && alertsEditable;

  const availableBalanceSheet = useToggle();
  const amountModal = useToggle();

  const [checked, setChecked] = useState(isActiveAlert);
  const [selectedValue, setSelectedValue] = useState<string>(t(alert.value));
  const [inputValue, setInputValue] = useState<string>(alert.value);

  const handleApply = (newValue: unknown) => {
    setSelectedValue(t((newValue as IBaseSelectOption).value));
    onApply(alert, newValue, true);
    availableBalanceSheet.hide();
  };

  const onCheckChange = (checkedValue: boolean) => {
    setChecked(checkedValue);
    onCheck(checkedValue, alert);
  };

  const handleAmountApply = (newValue: string) => {
    setInputValue(newValue);
    onApply(alert, newValue, false);
    amountModal.hide();
  };

  useEffect(() => {
    setSelectedValue(t(alert.value));
  }, []);

  return (
    <>
      <div className="settings-outer" key={alert.alertId}>
        <div className="settings-inner">
          <div className="settings-checkbox" style={{ alignItems: 'flex-start' }}>
            <Checkbox checked={checked} onChange={(e) => onCheckChange(e.target.checked)} disabled={!hasAccount || !alertsEditable} />
            <BodyText textType="bodyText" display="flex" size="N" fontWeight="M" color="charcoal" font="DM Sans" paddingTop={4}>
              {t(alert.title)}
            </BodyText>
          </div>
          <div className="settings-available">
            <Icon name="edit" color={checked ? 'blue' : 'charcoal40'} size="smaller" onClick={() => checked && amountModal.show()} data-alertId={alert.alertId} />
            {alert.alertName === 'AvailableBalanceDelivered' && (
              <>
                <div className="settings-available-balance" onClick={availableBalanceSheet.show}>
                  <BodyText textType="bodyText" fontWeight="B" size="T" color={checked ? 'charcoal' : 'charcoal40'} marginRight={16} cursorPointer>
                    {selectedValue || t('Weekly')}
                  </BodyText>
                  <Icon name="chevronDown" color={checked ? 'blue' : 'charcoal40'} size="smaller" cursorPointer />
                </div>
                {checked && (
                  <CurrentAvailableBalanceSheet
                    open={availableBalanceSheet.isActive}
                    options={selectOptions}
                    defaultValue={selectedValue || t('Weekly')}
                    onChange={handleApply}
                    onClose={availableBalanceSheet.hide}
                  />
                )}
              </>
            )}
          </div>
        </div>
        <div className="settings-inner" style={{ paddingLeft: '32px' }}>
          <BodyText textType="helperText" display="flex" size="N" fontWeight="R" color="charcoal60" extraStyles={{ maxWidth: '70%' }}>
            {t(alert.subTitle)}
          </BodyText>
          {alert.alertName !== 'InternalTransfer' && alert.alertName !== 'AvailableBalanceDelivered' && (
            <BodyText textType="helperText" size="N" fontWeight="B" color={checked ? 'charcoal' : 'charcoal40'}>
              {Number(inputValue) > 0 && `$${inputValue}`}
            </BodyText>
          )}
        </div>
      </div>

      <AlertsModal open={amountModal.isActive} closeModal={amountModal.hide} alertData={alert} handleApply={handleAmountApply} />
    </>
  );
};
